import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'; 
import FlagIcon from 'vue-flag-icon';
import axios from 'axios';
import store from "./store";
import i18n from '@/plugins/i18n';
import VueCryptojs from "vue-cryptojs";

Vue.prototype.$http = axios;
Vue.prototype.$http.defaults.withCredentials = true;

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  // console.log(error.response.data)
  if (error.response.status === 401) {
    // store.dispatch('logout')
    window.alert = function () {}
    router.push('/login')
  }
  return Promise.reject(error)
})

window.$ = window.jQuery = require("jquery");
Vue.config.productionTip = false
Vue.use(FlagIcon);
Vue.use(VueCryptojs);
new Vue({
  i18n,
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
