<template>
  <div>
    <v-card>
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >Master Product Plan</div>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4"></v-col>
            <v-col cols="12" md="4"></v-col>
            <v-col cols="12" md="4" style="text-align: right" class="d-flex justify-end">
              <v-btn
                width="180px"
                color="#1976d2 "
                class="white--text mt-5 flex-sm-grow-0 flex-grow-1"
                router
                @click="AddMasterProductPlan()"
              >Add</v-btn>
            </v-col>
          </v-row>
          <br />
          <div>
            <v-data-table
              :headers="headers"
              :items="ListData"
              hide-default-footer
              style="color: #444444"
              :items-per-page="itemsPerPage"
              :page.sync="page"
              single-line
              hide-details
            >
              <template v-slot:item="{ item, index }">
                <tr>
                  <td style="text-align: center">{{ no_run + (index + 1) }}</td>
                  <td style="text-align: center">{{ item.productPlanName }}</td>
                  <td style="text-align: center">{{ item.limitUser }}</td>
                  <td style="text-align: center">{{ item.limitSignature }}</td>
                  <td
                    style="
                      text-align: center;
                      color: #24709e;
                    "
                  >
                    <v-btn
                      :color="getColor(item.activeFlag)"
                      rounded
                      class="text-capitalize"
                      depressed
                      style="width: 150px"
                    >
                      <span
                        :style="{ color: getColorText(item.activeFlag) }"
                      >{{ item.activeFlag == true ? "Active" : "InActive" }}</span>
                    </v-btn>
                  </td>
                  <td style="text-align: center;">
                    <v-menu transition="slide-y-transition" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="#126496"
                          style="text-transform: none !important"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </template>
                      <v-list v-if="item.activeFlag == false">
                        <v-list-item
                          v-for="(temp, i) in itemsActionInActive"
                          :key="i"
                          @click="SelectAction(temp.title, item)"
                        >
                          <v-list-item-title>{{ temp.title }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                      <v-list v-else>
                        <v-list-item
                          v-for="(temp, i) in itemsAction"
                          :key="i"
                          @click="SelectAction(temp.title, item)"
                        >
                          <v-list-item-title>{{ temp.title }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <br />
          <v-row> 
            <v-col cols="12" xs="12" sm="12" md="4">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    style="border: 1px solid #EBEBF2"
                      v-bind="attrs"
                      v-on="on"
                      class="text-capitalize mt-1"
                      outlined
                    >
                      {{ itemsPerPage }}
                      <v-icon color="primary">mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in paginationCount"
                      :key="index"
                      @click="
                        itemsPerPage = item.title;
                        ChangePerPage(itemsPerPage);
                      "
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col> 
            <v-col cols="12" md="4">
              <v-pagination
                v-model="page"
                :total-visible="7"
                :length="pageCount"
                @input="ChangePage(page)"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card> 

    <v-dialog v-model="AddDialog" persistent width="800">
      <v-card>
        <v-card-title>
          <p style="word-break: break-word">Add Master Product Plan</p>
          <v-spacer></v-spacer>
          <v-btn style="margin-top: -10px" @click="CloseAddDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <hr class="ml-5 mr-4" />
        <v-row class="ml-5 mr-2">
          <v-col cols="12" md="12">
            <p class="mytitle">Name *</p>
            <span v-if="v_Name" class="validation-class">Please fill Name.</span>
            <v-text-field style="width: 450px" solo v-model="ProductPlanName"></v-text-field>      
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="12">
            <p class="mytitle">Description *</p>
            <span v-if="v_Description" class="validation-class">Please fill Description.</span>
            <v-textarea solo v-model="Description"></v-textarea>         
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col>
            <p class="mytitle">LimitUser *</p>
            <span v-if="v_LimitUser" class="validation-class">Please fill LimitUser.</span>
            <v-text-field v-if="(LimitSignature == null || LimitSignature == 0 )&& (checkPlan != 3 && checkPlan != 4) " style="width: 300px" solo v-model="LimitUser" @keypress="validateNumber"></v-text-field>          
            <v-text-field v-else disabled style="width: 300px;" solo-inverted v-model="LimitUser" @keypress="validateNumber"></v-text-field>           
            <p class="mytitle">LimitSignature *</p>
            <span v-if="v_LimitSignature" class="validation-class">Please fill LimitSignature.</span> 
            <v-text-field v-if="(LimitUser == null || LimitUser == 0 )&& (checkPlan != 3 && checkPlan != 4)" style="width: 300px" solo v-model="LimitSignature" @keypress="validateNumber"></v-text-field>
            <v-text-field v-else disabled style="width: 300px" solo-inverted v-model="LimitSignature" @keypress="validateNumber"></v-text-field>
          </v-col>
          <v-col>
            <v-row>
              <v-col md="12" cols="6"> 
                <p class="mytitle">Type plan</p> 
                <v-radio-group v-model="checkPlan">
                  <v-radio  v-for="(item,index) in itemTypePlan" :key="index" :label="item.typeName"  :value="item.id" ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" >
          <v-col cols="12" md="3">             
            <v-checkbox v-model="ContactSale" label="Contact Sale"></v-checkbox>
          </v-col>
          <v-col cols="12" md="2">
            <v-checkbox v-model="Server" label="Server"></v-checkbox>
          </v-col>
        </v-row>
        <v-card-actions style="margin-right: 3%; margin-left: 3%" class="d-flex flex-row-reverse flex-wrap justify-start pb-6">
          <v-btn
            color="#1976d2"
            class="mr-2 white--text text-capitalize flex-sm-grow-0 flex-grow-1"
            width="120"
            max-height="35"
            @click="AddDataMasterProductPlan()"
          >Add</v-btn>
          <v-btn
            class="mr-2 text-capitalize flex-sm-grow-0 flex-grow-1"
            width="120"
            max-height="35"
            @click="CloseAddDialog"
            text
          >Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="EditDialog" persistent width="800">
      <v-card>
        <v-card-title>
          <p style="word-break: break-word">Edit Master Product Plan</p>
          <v-spacer></v-spacer>
          <v-btn style="margin-top: -10px" @click="CloseEditDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <hr class="ml-5 mr-4" />
        <v-row class="ml-5 mr-2 mt-3">
          <v-col cols="12" md="12">
            <p class="mytitle">Name *</p>
            <v-text-field style="width: 450px" solo v-model="ProductPlanName"></v-text-field>
            <!-- <input style="width: 500px" class="input-border" type="text" v-model="ProductPlanName" /> -->
            <span v-if="v_Name" class="validation-class">Please fill Name.</span>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="12">
            <p class="mytitle">Description *</p>
            <v-textarea solo v-model="Description"></v-textarea>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col>
              <p class="mytitle">LimitUser *</p>
              <v-text-field v-if="(LimitSignature == null || LimitSignature == 0) && (checkPlan != 3 && checkPlan != 4)" style="width: 300px" solo v-model="LimitUser" @keypress="validateNumber"></v-text-field>          
              <v-text-field v-else disabled style="width: 300px" solo-inverted v-model="LimitUser" @keypress="validateNumber"></v-text-field>    
              <p class="mytitle">LimitSignature *</p>
              <v-text-field v-if="(LimitUser == null || LimitUser == 0) && (checkPlan != 3 && checkPlan != 4)" style="width: 300px" solo v-model="LimitSignature" @keypress="validateNumber"></v-text-field>
              <v-text-field v-else disabled style="width: 300px" solo-inverted v-model="LimitSignature" @keypress="validateNumber"></v-text-field>
          </v-col>
          <v-col>
            <v-row>
              <v-col md="12" cols="6"> 
                <p class="mytitle">Type plan</p> 
                <v-radio-group v-model="checkPlan">
                  <v-radio  v-for="(item,index) in itemTypePlan" :key="index" :label="item.typeName"  :value="item.id" ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" >
          <v-col cols="12" md="3">             
            <v-checkbox v-model="ContactSale" label="Contact Sale"></v-checkbox>
          </v-col>
          <v-col cols="12" md="2">
            <v-checkbox v-model="Server" label="Server"></v-checkbox>
          </v-col>
        </v-row>
        <br />
        <v-card-actions style="margin-right: 3%; margin-left: 3%" class="d-flex flex-row-reverse flex-wrap justify-start pb-6">
          <v-btn
            color="#1976d2"
            class="mr-2 white--text text-capitalize flex-sm-grow-0 flex-grow-1"
            width="120"
            max-height="35"
            @click="EditDataMasterProductPlan()"
          >Update</v-btn>
          <v-btn
            class="mr-2 text-capitalize flex-sm-grow-0 flex-grow-1"
            width="120"
            max-height="35"
            @click="CloseEditDialog"
            text
          >Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
    <Loading
      :flagLoading="flagLoadingSend"
    />
    
    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />
  </div>
</template>
<script>
import axios from "axios";
import store from "../store";
import enurl from "@/api/environment";
// import func from '../../vue-temp/vue-editor-bridge';
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components: {
    Alert,
    Loading,
  },
  props: {},
  data() {
    return {
      flagLoadingSend:false,
      flagAlertSend: false,
      mesAlertSend: "",
      typeAlertSend: "success",
      url: enurl.apiUrl,
      permissionid: store.state.permissionid,
      no_run: null,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      items: [10, 15, 20],
      paginationCount: [
        { title: 10 },
        { title: 20 },
        { title: 30 },
        { title: 40 },
      ],
      ListData: [],
      loadingdialog: false,
      AddDialog: false,
      EditDialog: false,
      ProductPlanID: null,
      ProductPlanName: "",
      LimitUser: null,
      LimitSignature: null,
      v_Name: false,
      v_LimitUser: false,
      v_LimitSignature: false,
      v_Description: false,
      itemsAction: [{ title: "Edit" }, { title: "InActive" }],
      itemsActionInActive: [{ title: "Edit" }, { title: "Active" }],
      itemTypePlan:[{ id:1 , typeName: "Free"},{ id:2 ,typeName: "Normal"},{ id:3 ,typeName: "Unlimit User"},{ id:4 ,typeName: "Unlimit Signature"}],
      checkPlan:null,
      btnColorAlert: "",
      Description: "",
      ContactSale: false,
      Server: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "#",
          align: "center",
          // value: "no",
          sortable: false
        },
        {
          text: "Name",
          // value: "productPlanName",
          align: "center",
          sortable: false
        },
        {
          text: "LimitUser",
          align: "center",
          sortable: false
          // value: "limitUser",
        },
        {
          text: "LimitSignature",
          // value: "limitSignature",
          align: "center",
          sortable: false
        },
        {
          text: "Status",
          // value: "activeFlag",
          align: "center",
          sortable: false
        },
        {
          text: "Action",
          // value: "action",
          align: "center",
          sortable: false
        }
      ];
    }
  },

  watch: {
    ProductPlanName: function (val) {
      if (val != "" || val != null) {
        this.v_Name = false;
      } else {
        this.v_Name = true;
      }
    },
    Description: function (val) {
      if (val != "" || val != null) {
        this.v_Description = false;
      } else {
        this.v_Description = true;
      }
    },
    checkPlan: function (val){
      if(val ==3 || val ==4 ){
        this.LimitUser = null;
        this.LimitSignature = null;
      } 
    },
  },

  mounted() {
    this.GetDataMasterProductPlan();
  },

  methods: {
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    validateNumber: event => {
      let keyCode = event.keyCode;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },

    AddMasterProductPlan() {
      let self = this;
      self.AddDialog = true;
    },

    CloseAddDialog() {
      let self = this;
      self.ClearData();
      self.AddDialog = false;
    },

    ClearData() {
      let self = this;
      self.ProductPlanName = null;
      self.Description = null;
      self.LimitUser = null;
      self.checkPlan = null; 
      self.LimitSignature = null;
      self.ContactSale = false;
      self.Server = false;
      self.AddDialog = false;
      self.EditDialog = false;
    },

    CloseEditDialog() {
      let self = this;
      self.ClearData();
      self.EditDialog = false;
    },

    getColor(e) {
      if (e == true) {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },
    getColorText(e) {
      if (e == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    SelectAction(temp, id) {
      let self = this;
      if (temp == "Edit") {
        self.ProductPlanID = id.productPlanID;
        self.loadingdialog = true;
        let TempData = {
          ID: self.ProductPlanID
        };
        axios
          .post(
            `${self.url}ProductPlan/GetDataMasterProductPlanForEdit`,
            TempData
          )
          .then(function(response) {
            if (response.data.status == 0) {
              var data = response.data.data;
              if (data.length > 0) {
                self.ProductPlanID = data[0].id;
                self.ProductPlanName = data[0].name;
                self.LimitUser = data[0].limitUser;
                self.checkEditPlan = self.LimitUser != null ? 3 : 1;
                self.LimitSignature = data[0].limitSignature;
                self.Description = data[0].description;
                self.checkPlan = data[0].typePlan;
                self.ContactSale = data[0].contactSale;
                self.Server = data[0].server;
                self.EditDialog = true;
                self.loadingdialog = false;
              }
            }
          })
          .catch(error => {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error.response;
            self.flagAlertSend = true;
            self.loadingdialog = false;
          });
      } else {
        self.loadingdialog = true;
        let Temp = {
          ID: id.productPlanID,
          Status: id.activeFlag
        };
        axios
          .post(`${self.url}ProductPlan/ActivateProductPlan`, Temp)
          .then(function(response) {
            if (response.data.status == 0) {
              self.loadingdialog = false;
              window.location.reload();
            }
          })
          .catch(error => {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error.response;
            self.flagAlertSend = true;
            self.loadingdialog = false;
          });
      }
    },

    GetDataMasterProductPlanForEdit() {
      let self = this;
      self.loadingdialog = true;
      let TempData = {
        ID: self.ProductPlanID
      };
      axios
        .post(
          `${self.url}ProductPlan/GetDataMasterProductPlanForEdit`,
          TempData
        )
        .then(function(response) {
          if (response.data.status == 0) {
            self.loadingdialog = false;
          }
        })
        .catch(error => {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error.response;
          self.flagAlertSend = true;
          self.loadingdialog = false;
        });
    },

    AddDataMasterProductPlan() {
      let self = this;
      if ((self.ProductPlanName == "" || self.Description == "" ) && (self.checkPlan != 3 || self.checkPlan != 4)) {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณากรอกข้อมูลให้ครบถ้วน !";
        self.flagAlertSend = true;
        if (self.ProductPlanName == "") {
          self.v_Name = true;
        } else {
          self.v_Name = false;
        }
        if (self.Description == "") {
          self.v_Description = true;
        } else {
          self.v_Description = false;
        }
      } 
      if((self.LimitUser == null && self.LimitSignature == null)&& (self.checkPlan != 3 && self.checkPlan != 4)){
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณากรอกข้อมูลให้ครบถ้วน !";
        self.flagAlertSend = true;
      }
      else {
        self.loadingdialog = true;
        let Temp = {
          ProductPlanName: self.ProductPlanName,
          Description: self.Description,
          LimitUser: self.LimitUser == null ? null : parseInt(self.LimitUser),
          LimitSignature: self.LimitSignature  == null ? null : parseInt(self.LimitSignature),
          ContactSale: self.ContactSale,
          TypePlan: self.checkPlan,
          Server: self.Server
        };
        axios
          .post(`${self.url}ProductPlan/AddDataMasterProductPlan`, Temp)
          .then(function(response) {
            if (response.data.status == 0) { 
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = "Success";
              self.flagAlertSend = true;       
              self.AddDialog = false;
              self.loadingdialog = false;
              self.ClearData();
              self.GetDataMasterProductPlan();             
            }
          })
          .catch(error => {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error.response;
            self.flagAlertSend = true;
            self.loadingdialog = false;
          });
      }
    },

    EditDataMasterProductPlan() {
      let self = this;
      if ((self.ProductPlanName == "" || self.Description == "") && (self.checkPlan != 3 && self.checkPlan != 4)) {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณากรอกข้อมูลให้ครบถ้วน !";
        self.flagAlertSend = true;
        if (self.ProductPlanName == "") {
          self.v_Name = true;
        } else {
          self.v_Name = false;
        }
        if (self.Description == "") {
          self.v_Description = true;
        } else {
          self.v_Description = false;
        }
      } 
      if((self.LimitUser == null || self.LimitUser == "") && (self.LimitSignature == null || self.LimitSignature == "") && (self.checkPlan != 3 && self.checkPlan != 4)){
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณากรอกข้อมูลให้ครบถ้วน !";
        self.flagAlertSend = true;
      }
      else {
        self.loadingdialog = true;
        let Temp = {
          ID: self.ProductPlanID,
          ProductPlanName: self.ProductPlanName,
          Description: self.Description,
          LimitUser: self.LimitUser == null ? null : parseInt(self.LimitUser),
          LimitSignature: self.LimitSignature  == null ? null : parseInt(self.LimitSignature),
          ContactSale: self.ContactSale,
          TypePlan: self.checkPlan,
          Server: self.Server
        };
        axios
          .post(`${self.url}ProductPlan/EditDataMasterProductPlan`, Temp)
          .then(function(response) {
            if (response.data.status == 0) {
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = "Success";
              self.flagAlertSend = true;
              self.loadingdialog = false;
              self.AddDialog = false;
              self.ClearData();
              self.GetDataMasterProductPlan();
            }
          })
          .catch(error => {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error.response;
            self.flagAlertSend = true;
            self.loadingdialog = false;
          });
      }
    },

    GetDataMasterProductPlan() {
      let self = this;
      self.loadingdialog = true;
      let TempData = {
        Page: self.page,
        PerPage: self.itemsPerPage
      };
      axios
        .post(`${self.url}ProductPlan/GetDataProductPlan`, TempData)
        .then(function(response) {
          if (response.data.status == 0) {
            self.ListData = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.loadingdialog = false;
          } else {
            self.loadingdialog = false;
            self.ListData = [];
            self.pageCount = 0;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = "ไม่พบข้อมูล";
            self.flagAlertSend = true;
          }
        })
        .catch(error => {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error.response;
          self.flagAlertSend = true;
          self.loadingdialog = false;
        });
    },

    ChangePerPage: function(value) {
      let self = this;
      self.loadingdialog = true;
      let TempData = {
        Page: 1,
        PerPage: value
      };
      axios
        .post(`${self.url}ProductPlan/GetDataProductPlan`, TempData)
        .then(function(response) {
          if (response.data.status == 0) {
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.ListData = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.page = 1;
            self.loadingdialog = false;
          } else {
            self.loadingdialog = false;
            self.ListData = [];
            self.pageCount = 0;
            self.page = 1;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = "ไม่พบข้อมูล";
            self.flagAlertSend = true;
          }
        })
        .catch(error => {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error.response;
          self.flagAlertSend = true;
          self.loadingdialog = false;
        });
    },

    ChangePage: function(value) {
      let self = this;
      self.loadingdialog = true;
      let TempData = {
        Page: value,
        PerPage: self.itemsPerPage
      };
      axios
        .post(`${self.url}ProductPlan/GetDataProductPlan`, TempData)
        .then(function(response) {
          if (response.data.status == 0) {
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.ListData = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.loadingdialog = false;
          } else {
            self.loadingdialog = false;
            self.ListData = [];
            self.pageCount = 0;
            self.page = 1;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = "ไม่พบข้อมูล";
            self.flagAlertSend = true;
          }
        })
        .catch(error => {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error.response;
          self.flagAlertSend = true;
          self.loadingdialog = false;
        });
    }
  }
};
</script>
<style scoped>
@media only screen and (min-width: 1030px) and (max-width: 1409px) {
  .per_page {
    margin-left: -5rem !important;
  }
  * >>> .v-select > .v-input__control > .v-input__slot {
    width: 80px !important;
  }
}
* >>> tbody tr:nth-of-type(even) {
  background-color: #1264960d;
}
.v-text-field--outlined fieldset {
  color: #ebebf2 !important;
}
* >>> .v-data-table-header {
  background-color: #126496 !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
* >>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
* >>> .theme--light.v-pagination .v-pagination__item--active {
  background: #126496 !important;
  color: white !important;
}
.mytitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
</style>
<style lang="scss">
.__pagination {
  .v-pagination__navigation {
    box-shadow: none !important;
    border: 1px solid #0000001a;
    width: auto !important;
    padding-left: 10px;
    padding-right: 10px;
    &:focus {
      outline: none;
    }
  }
}
</style>
