import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import enurl from "@/api/environment";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    token: sessionStorage.getItem("token") || false,
    usertype: sessionStorage.getItem("usertype") || "",
    username: sessionStorage.getItem("username") || "",
    permissionid: sessionStorage.getItem("permissionid")|| "",
    firstName:sessionStorage.getItem("firstName")||"",
    lastName:sessionStorage.getItem("lastName")||"",
    email:sessionStorage.getItem("email")||"",
    pathImage:sessionStorage.getItem("pathImage")||"",
    mobile:sessionStorage.getItem("mobile")||"",
    employeeID:sessionStorage.getItem("employeeID")||"",
    user: {}
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, user) {
      state.status = "success";
      state.token = true;
      state.permissionid=user.permissionId;
      state.usertype = user.userType.trim();
      state.username = user.username.trim();
      state.firstName=user.firstName;
      state.lastName=user.lastName;
      state.email=user.email;
      state.mobile=user.mobile;
      state.pathImage=user.pathImage;
      state.employeeID=user.employeeID;
      // eslint-disable-next-line no-console
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = true;
      state.usertype = "";
      state.permissionid="";
      state.firstName="";
      state.lastName="";
      state.email="";
      state.mobile="";
      state.pathImage="";
      state.employeeID="";
    }
  },
  actions: {
    login({
      commit,
    }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
            url: `${enurl.apiUrl}Login/Login`,
            data: user,
            method: "POST"
          })
          .then(resp => {
            if(resp.data.status != 0)
            {
              commit("auth_error");
              reject(resp.data.message);
            }
            else
            {
              axios({
                url: `${enurl.apiUrl}User/GetInfoUser`,
                data: user,
                method: "POST"
              })
              .then(resp => {
                if(resp.data.status == 0)
                {
                  const permissionId=resp.data.data.permissionId;
                  const userType = resp.data.data.userType.trim();
                  const user = resp.data.data;
                  const userName = resp.data.data.username.trim();
                  const firstName = resp.data.data.firstName;
                  const lastName = resp.data.data.lastName;
                  const email = resp.data.data.email;
                  const mobile = resp.data.data.mobile;
                  const pathImage = resp.data.data.pathImage;
                  const employeeID=resp.data.data.employeeID;
                  sessionStorage.setItem("token", true);
                  sessionStorage.setItem("usertype", userType);
                  sessionStorage.setItem("username", userName);
                  sessionStorage.setItem("permissionid", permissionId);
                  sessionStorage.setItem("firstName", firstName);
                  sessionStorage.setItem("lastName", lastName);
                  sessionStorage.setItem("email", email);
                  sessionStorage.setItem("mobile", mobile);
                  sessionStorage.setItem("pathImage", pathImage);
                  sessionStorage.setItem("employeeID",employeeID);
                  commit("auth_success", user);
                  resolve(resp);
                }
              });  
            }
          })
          .catch(err => {
            commit("auth_error");
            reject(err);
          });
      });
    },
    logout({
      commit
    }) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        commit("logout");
        axios({
          url: `${enurl.apiUrl}Login/Logout`,
          method: "POST"
        })
          .then(resp => {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("usertype");
            sessionStorage.removeItem("username");
            sessionStorage.removeItem("permissionid");
            sessionStorage.removeItem("firstName");
            sessionStorage.removeItem("lastName");
            sessionStorage.removeItem("email");
            sessionStorage.removeItem("mobile");
            sessionStorage.removeItem("pathImage");
            sessionStorage.removeItem("employeeID");
            delete axios.defaults.headers.common["Authorization"];
            resolve(resp);
          })
          .catch(err => {
            commit("auth_error");
            reject(err);
          }); 
      });
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    usertype: state => state.usertype,
    permissionid: state => state.permissionid,
    employeeID:state=>state.employeeID,
    authStatus: state => state.status
  }
});