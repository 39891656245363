<template>
    <v-footer
    absolute
    inset
    color="#126496"
    class="white--text text-caption hidden-md-and-down"
    >
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="10" md="10">
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="4" md="4" lg="2" style="margin-top: 16px;">
              <v-img
                src="@/assets/Group 7922.png"
                width="180"
                class="mx-auto"
              ></v-img>
            </v-col>
            <v-col>
              <div style="font-size: 14px"
                >VERACITY </div
              >
              <span style="font-size: 14px">77/148 SINSATHORN TOWER ถ. กรุงธนบุรี แขวง คลองต้นไทร
                เขตคลองสาน กรุงเทพมหานคร 10600</span>
              <div class="d-flex">
                <span style="text-decoration: underline;font-size: 14px">Terms Of Use</span>
                <v-divider vertical inset class="mx-3" dark></v-divider>
                <span style="text-decoration: underline;font-size: 14px">Privacy Policy</span>
                <v-divider vertical inset class="mx-3" dark></v-divider>
                <span style="text-decoration: underline;font-size: 14px">Cookies Policy</span>
                <v-divider vertical inset class="mx-3" dark></v-divider>
                <span style="text-decoration: underline;font-size: 14px">Privacy Notice</span>
                <v-divider vertical inset class="mx-3" dark></v-divider>
              </div>
            </v-col>
            <v-col>
              <div style="font-size: 14px">For more Information</div>
              <div style="font-size: 14px">TEL : 02 440 0408</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xs="12" sm="2" md="2" style="margin-top: 16px;">
          <v-btn
            icon
            color="white"
            class="float-right"
            style="margin-top: 0.5rem"
          >
            <v-icon>mdi-arrow-up-circle-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
</template>
<script>
import * as easings from 'vuetify/lib/services/goto/easing-patterns'
  export default {
    data () {
      return {
        type: 'number',
        number: 0,
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
        easings: Object.keys(easings),
      }
    },
    computed: {
      target () {
        const value = this[this.type]
        if (!isNaN(value)) return Number(value)
        else return value
      },
      options () {
        return {
          duration: this.duration,
          offset: this.offset,
          easing: this.easing,
        }
      },
    },
  }
</script>