<template>
  <div :style="{'margin-bottom':!$vuetify.breakpoint.xl && !$vuetify.breakpoint.lg?'-20px':''}">
    <v-container fluid>
      <v-flex :style="{margin:!$vuetify.breakpoint.xl? '0':'',padding:!$vuetify.breakpoint.xl? '0':''}">
          <v-row>
            <v-col cols="12" md="3">
                <p style="color: #444444;font-size: 20px">Add Company</p>
                <p style="color: #126496; font-size: 14px">General Information</p>
            </v-col>
            <v-col cols="12" md="9"></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="8" lg="8">
                <p class="header-title">Company Name</p>
                <input class="input-border" :style="{width: '100%'}" type="text" placeholder="Company Name" v-model="CompanyName" />
                <br />
                <span v-if="v_CompanyName" class="validation-class">Please fill Company Name.</span>
            </v-col>
            <v-col cols="12" sm="5" md="4" lg="4">
                <p class="header-title">Company Code</p>
                <input class="input-border" style="width: 100%" type="text" placeholder="Company Code" v-model="CompanyCode" />
                <br />
                <span v-if="v_CompanyCode" class="validation-class">Please fill Company Code.</span>
            </v-col>
            <v-col cols="12" md="2" lg="1"></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="5" lg="5">
                <p class="header-title">Telephone Number</p>
                <input class="input-border" style="width: 100%" type="text" placeholder="Telephone Number" v-model="TelephoneNumber" @keypress="isNumber($event)"/>
                <br />
                <span v-if="v_Phone" class="validation-class">Please fill Telephone Number.</span>
            </v-col>
            <v-col cols="12" sm="6" md="7" lg="7">
                <p class="header-title">E-mail</p>
                <input class="input-border" style="width: 100%" type="text" placeholder="E-mail" v-model="Email" />
                <br />
                <span v-if="v_Email" class="validation-class">Please fill E-mail.</span>
            </v-col>
            <v-col cols="12" md="2">           
            </v-col>
            <v-col cols="12" md="2"></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm ="5" md="5" lg="5">
                <p class="header-title">Address</p>
                <input class="input-border" style="width: 100%" type="text" placeholder="Address" v-model="Address" />
                <br />
                <span v-if="v_Address" class="validation-class">Please fill Address.</span>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
                <p class="header-title">Country</p>
                <input class="input-border" style="width: 100%" type="text" placeholder="Country" v-model="Country" />
                <br />
                <span v-if="v_Country" class="validation-class">Please fill Country.</span>
            </v-col>          
            <v-col cols="12" sm="3" md="3" lg="3">
                <p class="header-title">City</p>
                <input class="input-border" style="width: 100%" type="text" placeholder="City" v-model="City" />
                <br />
                <span v-if="v_City" class="validation-class">Please fill City.</span>
            </v-col>
            <v-col cols="12" md="1">
                
            </v-col>
          </v-row>
          <br>
          <v-row>
            <v-col cols="12" sm="5" md="5">
                <p style="color: #126496; font-size: 14px">Login Credentials</p>
                <p class="header-title">User ID</p>
                <input class="input-border" style="width: 100%" type="text" placeholder="User ID" v-model="UserID" />
                <br />
                <span v-if="v_UserID" class="validation-class">Please fill User ID.</span>
            </v-col>
            <v-col cols="12" md="7"></v-col>                     
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5" lg="5">
                <p class="header-title">Password</p>
                <input id="password" class="input-border" style="width: 100%" type="password" placeholder="Password" v-model="Password" />               
                <v-icon v-show="flagPassword" style="margin-left: -30px; cursor: pointer;" @click="TogglePassword()">mdi-eye</v-icon>
                <v-icon v-show="!flagPassword" style="margin-left: -30px; cursor: pointer;" @click="TogglePassword()">mdi-eye-off</v-icon>
                <br />
                <span v-if="v_Password" class="validation-class">Please fill Password.</span>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3">
                <v-btn color="#126496" @click="Password = GeneratePassword()" style="width:14rem; color: white; font-size: 12px; margin-top:34px;">Generate Password</v-btn>
            </v-col>
            <v-col cols="12" md="3"></v-col>    
            <v-col cols="12" md="3"></v-col>                  
          </v-row>

          <!-- <v-row>
            <v-col cols="12" md="3">
                <p style="color: #126496; font-size: 14px">Packet Management</p>
                <p class="header-title">Limit User</p>
                <input class="input-border" style="width: 268px" type="text" placeholder="Limit User" />
            </v-col>
            <v-col cols="12" md="3" style="margin-top: 36px;">
                <p class="header-title">Sign Work Flow Limit</p>
                <input class="input-border" style="width: 268px" type="text" placeholder="Sign Work Flow Limit" />
            </v-col>
            <v-col cols="12" md="3"></v-col>    
            <v-col cols="12" md="3"></v-col>                      
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
                <p class="header-title">Expires Date</p>
                <input class="input-border" style="width: 240px" type="text" placeholder="DD/MM/YYYY"  />
            </v-col>
            <v-col cols="12" md="1" style="margin-top: 20px;">
                <v-checkbox
                    hide-details
                ></v-checkbox>
            </v-col>
            <v-col cols="12" md="3" style="margin-top: 36px;">
                <p style="color: #126496; font-size: 14px">Expires on DD/MM/YYYY</p>
            </v-col>
            <v-col cols="12" md="6"></v-col>                    
          </v-row> -->

          <v-row>
            <v-col cols="3" sm="3" md="3" lg="3">
                <v-btn color="#B8B8B8" style="padding-left:20px; padding-right:20px; color: white; font-size: 12px;" @click="GotoCompanyManagement()">Cancel</v-btn>
            </v-col>
            <v-col cols="3" sm="6" md="6" lg="5"></v-col>
            <v-col cols="4" sm="3" md="3" lg="3">
                <v-btn color="#1976D2" style="padding-left:20px; padding-right:20px; width:12xp; color: white" @click="AddCompany()" >Add Company</v-btn>
            </v-col>
            <!-- <v-col cols="12" md="2"></v-col>   -->
          </v-row>
        </v-flex>
    </v-container>

    <Loading
      :flagLoading="flagLoadingSend"
    />
    
    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />
    <br>
    <br>
    <br>
    <br>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import Footer from "./../components/Footer.vue";
// import store from "../store";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components:{
    Footer,
    Alert,
    Loading,
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    size: {
      type: String,
      default: "10",
    },
    characters: {
      type: String,
      default: "a-z,A-Z,0-9,#",
    },
    placeholder: {
      type: String,
      default: "Password",
    },
    auto: [String, Boolean],
    value: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    flagLoadingSend: false,
    userList: [],
    url: enurl.apiUrl,
    CompanyName: "",
    CompanyCode: "",
    TelephoneNumber: "",
    Email: "",
    Address: "",
    Country: "",
    City: "",
    UserID: "",
    Password: "",
    flagPassword: false,
    v_CompanyName: false,
    v_CompanyCode: false,
    v_Phone: false,
    v_Email: false,
    v_Country: false,
    v_City: false,
    v_Address: false,
    v_UserID: false,
    v_Password: false,
    checkphone: false,
    checkemail: false,
    flagAlertSend: false,
    mesAlertSend: "",
    typeAlertSend: "success",
  }),
  
  methods: {
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    GenerateUserID: function () {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }

      for (let i = 0; i < 6; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      return password;
    },

    GeneratePassword: function () {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }

      for (let i = 0; i < this.size; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      return password;
    },

    TogglePassword() {
      var x = document.getElementById("password");
      if (x.type === "password") {
        x.type = "text";
        this.flagPassword = false;
      } else {
        x.type = "password";
        this.flagPassword = true;
      }
    },

    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email) || "invalid";
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    GotoCompanyManagement(){
      let self = this;
      self.$router.push("/companymanagement");
    },

    AddCompany() {
      var self = this;
      if (
        self.CompanyName == "" ||
        self.CompanyCode == "" ||
        self.TelephoneNumber == "" ||
        self.Email == "" ||
        self.Address == "" ||
        self.Country == "" ||
        self.City == "" ||
        self.UserID == "" ||
        self.Password == "" ||
        (self.TelephoneNumber.length < 9 ||
        self.TelephoneNumber.length > 11 ) ||
        self.validateEmail(self.Email) == "invalid"
      ) {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณากรอกข้อมูลให้ครบถ้วน !";
        self.flagAlertSend = true;
        if (self.CompanyName == "") {
          self.v_CompanyName = true;
        } else {
          self.v_CompanyName = false;
        }
        if (self.CompanyCode == "") {
          self.v_CompanyCode = true;
        } else {
          self.v_CompanyCode = false;
        }
        if (self.TelephoneNumber == "") {
          self.v_Phone = true;
        } else {
          if (self.TelephoneNumber != null) {
            if (
              self.TelephoneNumber.length < 9 ||
              self.TelephoneNumber.length > 11
            ) {
              self.checkphone = true;
            } else {
              self.checkphone = false;
            }
            self.v_Phone = false;
          }
        }
        if (self.Email == "") {
          self.v_Email = true;
        } else {
          if (self.validateEmail(self.Email) == "invalid") {
            self.checkemail = true;
            self.v_Email = true;
          } else {
            self.checkemail = false;
            self.v_Email = false;
          }
        }
        if (self.Country == "") {
          self.v_Country = true;
        } else {
          self.v_Country = false;
        }
        if (self.City == "") {
          self.v_City = true;
        } else {
          self.v_City = false;
        }
        if (self.Address == "") {
          self.v_Address = true;
        } else {
          self.v_Address = false;
        }
        if (self.UserID == "") {
          self.v_UserID = true;
        } else {
          self.v_UserID = false;
        }
        if (self.Password == "") {
          self.v_Password = true;
        } else {
          self.v_Password = false;
        }
      } else {
        var temp = {
          companyName: self.CompanyName,
          companyCode: self.CompanyCode,
          telephoneNo: self.TelephoneNumber,
          email: self.Email,
          country: self.Country,
          city: self.City,
          address: self.Address,
          status: true,
          employeeExtJoinRequest: {
            userID: 0,
            memberID: 0,
            username: self.UserID,
            firstName: "string",
            lastName: "string",
            email: "string",
            password: self.Password,
            userType: "string",
            roleID: 0,
            mobile: "string",
            employeeName: "string",
            positionID: 0,
            companyID: 0,
            activeFlag: true,
            flagAdmin: true,
            EmployeePermission : [{
              PermissionID :1
            }],
          },
          activeFlag: true,
        };
        self.flagLoadingSend = true;
        axios
          .post(`${self.url}Company/AddCompany`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = response.data.message;
              self.flagAlertSend = true;
              self.flagLoadingSend = false;
              self.$router.push("/companymanagement");
            } else {
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = "Company Code/Email/UserID เคยลงทะเบียนไปแล้ว !";
              self.flagAlertSend = true;
              self.flagLoadingSend = false;
            }
          })
          .catch(function (error) {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
          });
      }
    },
  },
  watch: {
    CompanyName: function (val) {
      if (val != "" || val != null) {
        this.v_CompanyName = false;
      } else {
        this.v_CompanyName = true;
      }
    },
    CompanyCode: function (val) {
      if (val != "" || val != null) {
        this.v_CompanyCode = false;
      } else {
        this.v_CompanyCode = true;
      }
    },
    Email: function (val) {
      if (val != "" || val != null) {
        this.v_Email = false;
      } else {
        this.v_Email = true;
      }
    },
    TelephoneNumber: function (val) {
      if (val != "" || val != null) {
        this.v_Phone = false;
      } else {
        this.v_Phone = true;
      }
    },
    Country: function (val) {
      if (val != "" || val != null) {
        this.v_Country = false;
      } else {
        this.v_Country = true;
      }
    },
    City: function (val) {
      if (val != "" || val != null) {
        this.v_City = false;
      } else {
        this.v_City = true;
      }
    },
    Address: function (val) {
      if (val != "" || val != null) {
        this.v_Address = false;
      } else {
        this.v_Address = true;
      }
    },
    UserID: function (val) {
      if (val != "" || val != null) {
        this.v_UserID = false;
      } else {
        this.v_UserID = true;
      }
    },
    Password: function (val) {
      if (val != "" || val != null) {
        this.v_Password = false;
      } else {
        this.v_Password = true;
      }
    },
    
  },
  mounted() {
    this.UserID = this.GenerateUserID();
    this.Password = this.GeneratePassword();
  }
};
</script>
<style scoped>
.card {
  border-radius: 10px !important;
  margin-bottom: 2rem;
  height: 90vh;
  overflow: auto;
  margin: 0;
  padding: 0;
}
.flex {
  margin-left: 2rem;
  margin-right: 0rem;
}
.circular--portrait {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

.circular--portrait img {
  width: 100%;
  height: auto;
}
.input-border {
  border: 1px solid #EBEBF2;
  height: 35px;
  padding: 4px;
}
.header-title {
  font-size: 13px;
  font-weight: 300;
}
::-webkit-input-placeholder { 
    color: #444444;
    opacity: 20%;
    font-size: 14px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
</style>