<template>
  <div>
    <v-container fluid>
      <v-flex class="flex">
        
        <div class="text-center" v-show="UploadPreviewFlag">
          <v-row justify="end" class="ml-5 mr-5">
            <v-spacer />
            <v-col cols="12" md="4">
              <div>
                <v-btn
                  @click="onPickPreviewFile"
                  color="primary"
                  class="white--text mt-2 text-capitalize"
                  router
                  width="225"
                  >Upload Document Preview</v-btn
                >
                <input
                  ref="uploadPreviewFile"
                  id="preview-file-upload"
                  name="preview-file-input"
                  type="file"
                  style="display: none"
                  @change="handlePreviewFileInput"
                  accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
                />
              </div>
            </v-col>
            <v-spacer />
          </v-row>
          <br />
        </div>

        <div class="text-center" v-show="!UploadPreviewFlag && UploadFlag">
          <v-row justify="end" class="ml-5 mr-5">
            <v-col cols="12" md="4"></v-col>
            <v-col cols="12" md="4">
              <div>
                <v-btn
                  @click="onPickFile"
                  color="primary"
                  class="white--text mt-2 text-capitalize"
                  router
                  width="225"
                  >Upload Document</v-btn
                >
                <input
                  ref="uploadFile"
                  id="file-upload"
                  name="file-input"
                  type="file"
                  style="display: none"
                  @change="handleFileInput"
                  accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
                />
              </div>
            </v-col>
            <v-col cols="12" md="4"></v-col>
          </v-row>
          <br />
        </div>

        <!-- <div v-show="!UploadPreviewFlag">
          <div class="d-flex justify-center mt-10">
            <div style="color: #126496">
              {{ previewFileName }}
            </div>
            <button style="float: right" @click="removeFile()" title="Remove">
              <v-icon color="red">mdi-delete-forever</v-icon>
            </button>
          </div>
        </div> -->

        <div v-show="!UploadPreviewFlag" >
          <div class="d-flex justify-center">
            <v-btn
              color="#03C7F9"
              class="white--text mt-2"
              router
              width="225"
              @click="PreviewStatus = !PreviewStatus"
              >Preview Document</v-btn
            >
          </div>
        </div>
 
        <div class="text-center mt-5" v-show="PreviewStatus">
          <div id="pdf"></div>
        </div>

        <div v-show="!UploadFlag">
          <div class="d-flex justify-center mt-10">
            <div style="color: #126496">
              {{ fileName }}
            </div>
            <button style="float: right" @click="removeFile()" title="Remove">
              <v-icon color="red">mdi-delete-forever</v-icon>
            </button>
          </div>
        </div>

        <div class="text-right" v-show="!UploadFlag">
          <div class="d-flex justify-center">
            <div style="width:400px;">
              <v-text-field
                v-model="contactName"
                label="Contract Name"
                required
              ></v-text-field>
            </div>
          </div>
        </div> 

        <!-- <div v-show="!UploadFlag" >
          <div class="d-flex justify-center">
            <v-btn
              color="#03C7F9"
              class="white--text mt-2"
              router
              width="180px"
              @click="PreviewStatus = !PreviewStatus"
              >Preview Document</v-btn
            >
          </div>
        </div>
        
        <div ref='viewer' id='docxViewer' v-show="PreviewStatus" style="margin-top: 1rem"></div> -->

        <!-- <div class="text-right" v-show="!UploadFlag && (files.name != null)">
          <div class="d-flex justify-center">
            <v-btn
              color="primary"
              class="white--text mt-2"
              router
              width="180px"
              @click="GetBookmark()"
            >
            Get Bookmark
            </v-btn>
          </div>
        </div> -->

        <div class="mt-10"> 
          <v-row>
            <v-col cols="6" v-for="(bookmark, i) in bookmarkList" :key="i">
              <v-row class="d-flex justify-center">
                <v-col cols="1">
                  {{ i + 1 }}
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    :label="bookmark.bookmarkName"
                    solo-inverted
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select
                    :items="bookmarkTypeList"
                    v-model="bookmark.bookmarkType"
                    label="Select"
                    solo
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <div class="d-flex justify-space-between">
          <v-btn color="#B8B8B8" class="mt-10" style="color: white; font-size: 12px;" @click="GotoTemplateContactList()">Cancel</v-btn>
          <v-btn color="#1976D2" class="mt-10" style="color: white;" @click="SaveDialog = true" :disabled="IsSaveDisable()">Save Template</v-btn>
        </div>
      </v-flex>
    </v-container>
    
    <br>
    <br>
    <br>
    <br>
    <Footer />

    <v-dialog v-model="SaveDialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span>
          </span>
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 20px">
            Save Contract Template 
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  Do you want to Save Contract Template?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            @click="SaveDialog = false"
            text
            >Cancel</v-btn
          >
          <v-btn
            color="#126496"
            class="mr-2 white--text text-capitalize"
            width="120"
            @click="SaveTemplateContact()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Loading
      :flagLoading="flagLoadingSend"
    />

    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import Footer from "./../components/Footer.vue";
// import store from "../store";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components:{
    Footer,
    Loading,
    Alert,
  },
  props: {
  },
  data: () => ({
    // initLoadWebviewer: false,
    // counter: 0, 
    docxBase64: '',
    previewDocxBase64: '',
    PreviewStatus: false,
    url: enurl.apiUrl,
    flagLoadingSend: false,
    flagAlertSend: false,
    mesAlertSend: "",
    typeAlertSend: "success",
    tempTemplateContactID: null,
    UploadPreviewFlag: true,
    UploadFlag: true,
    ShowBookmarkFlag: false,
    previewFiles: [],
    files: [],
    fileString: "",
    previewFileLink: "",
    fileLink: "",
    previewFileName: "",
    fileName: "",
    contactName: "",
    bookmarkList: [],
    bookmarkTypeList: [
      'character',
      'number',
    ],
    SaveDialog: false,
  }),
  watch: { 
  },
  mounted() {
    let self = this;
    if(self.$route.params.TemplateContactID != null && self.$route.params.TemplateContactID != undefined)
    {
      self.tempTemplateContactID = self.$route.params.TemplateContactID;
      // console.log(self.tempTemplateContactID);
      // console.log(self.tempTemplateContactID);
      self.GetTemplateContact();
    } 
  },
  methods: {
    IsSaveDisable(){
      if((!this.UploadFlag && !this.UploadPreviewFlag) || this.fileLink){
        return false
      }else{
        return true
      }
    },
    ClearDataAlert() {
      let self = this;
      self.flagAlertSend = false;
      // self.typeAlertSend = ""; //success warning error
      self.mesAlertSend = "";
    },
    onPickPreviewFile(){ 
      this.$refs.uploadPreviewFile.click();
    },
    onPickFile() {
      let self = this;
      self.$refs.uploadFile.click();
    },
    async handlePreviewFileInput(e) {
      let self = this; 
      self.flagLoadingSend = true;
      self.previewFiles = [];
      let files = e.target.files;

      const validateFileType = () => {
        const allowedTypes = [
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/msword"
        ];
        return allowedTypes.includes(files[0].type);
      };

      const validateFileSize = () => {
        const maxSize = 20480000; // 20MB
        return files[0].size <= maxSize;
      };

      if (!validateFileType()) {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "Please attach .doc or .docx file only.";
        self.flagAlertSend = true;
      } else if (!validateFileSize()) {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "Please upload a file no larger than 20MB.";
        self.flagAlertSend = true;
      } else {
        // console.log(files[0]);
        self.previewFileName = files[0].name;
        self.previewFiles = files[0];
        self.UploadPreviewFlag = false;

        let pdfBase64 = await self.ConvertDocxToPdf(await self.fileToBase64(self.previewFiles));
        await self.SetDataPreviewPDF(pdfBase64);
      }

      self.flagLoadingSend = false;
    },

    async handleFileInput(e) {
      let self = this;
      self.flagLoadingSend = true;
      self.files = [];
      let files = e.target.files;
      if(files[0].type != "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && files[0].type != "application/msword")
      {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "Please attach .doc or .docx file only.";
        self.flagAlertSend = true;
        self.flagLoadingSend = false;
      }
      else if(files[0].size > 20480000)
      {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "Please upload file no more than 20mb.";
        self.flagAlertSend = true;
        self.flagLoadingSend = false;
      }
      else
      {
        // console.log(files[0]);
        self.fileName = files[0].name;
        self.files = files[0];
        self.UploadFlag = false; 
        
        await self.GetBookmark();
        self.flagLoadingSend = false;
      }  
    },

    async fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        
        reader.onload = () => {
          const base64 = reader.result.split(',')[1];
          resolve(base64);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    },

    SetDataPreviewPDF(pdfBase64) { 
      let self = this;
      
      const pdfElement = document.getElementById("pdf"); 
      
      // Clear the PDF element
      while (pdfElement.firstChild) {
        pdfElement.removeChild(pdfElement.firstChild);
      }
      
      const blobPDF = self.base64ToBlob(pdfBase64, "application/pdf");
      const linkSource = URL.createObjectURL(blobPDF);

      const obj = document.createElement("embed");
      obj.style.width = "100%";
      obj.style.height = "550px";
      obj.type = "application/pdf";
      obj.src = linkSource; 

      // Append the new PDF embed object to the PDF element
      pdfElement.appendChild(obj); 
    },

    async ConvertDocxToPdf(base64){
      let pdfBase64 = ""

      let temp = {
        docxBase64: base64.toString()
      }
      await axios
        .post(`${this.url}TemplateContact/ConvertDocxToPdf`, temp)
        .then(function (res) {
          pdfBase64 = res.data.data
        })

      return pdfBase64
    },

    removeFile() {
      let self = this;
      self.flagLoadingSend = true;
      self.$refs.uploadFile.value = null;
      self.bookmarkList = [];
      self.previewFiles = [];
      self.files = [];
      self.previewFileName = "";
      self.fileName = "";
      self.fileString = "";
      self.UploadPreviewFlag = true;
      self.UploadFlag = true;
      self.flagLoadingSend = false;
      self.PreviewStatus = false 
      self.contactName = ""

      this.$refs.uploadPreviewFile.value = "";
      this.$refs.uploadFile.value = "";
      
      // Clear the PDF element
      const pdfElement = document.getElementById("pdf"); 
      while (pdfElement.firstChild) {
        pdfElement.removeChild(pdfElement.firstChild);
      }
    },
    GetTemplateContact() {
      let self = this;
      self.flagLoadingSend = true
      let tempdata = {
        id: self.tempTemplateContactID,
      };
      axios
        .post(`${self.url}TemplateContact/GetTemplateContactById`, tempdata)
        .then(function (response) {
          if (response.data.status == 0) {
            var dataRes = response.data.data;
            // console.log(dataRes);
            self.flagLoadingSend = false;
            self.UploadPreviewFlag = false;
            self.UploadFlag = false;
            self.bookmarkList = [];
            self.contactName = dataRes.contactName;
            self.fileName = dataRes.fileName;
            self.fileLink = dataRes.strFile;
            self.previewFileLink = dataRes.previewStrFile;
            self.docxBase64 = dataRes.docxBase64;
            self.previewDocxBase64 = dataRes.previewDocxBase64;
            dataRes.bookmark.forEach(function (item) {
              var tempBookmark = {
                bookmarkName: item.bookmarkName,
                bookmarkType: item.bookmarkType,
              };
              self.bookmarkList.push(tempBookmark);
            });
 
            let pewviewPdfBase64 = response.data.data.previewDocxToPdfBase64
            self.SetDataPreviewPDF(pewviewPdfBase64)
 
            // const file = self._Base64IntoFile(dataRes.docxBase64);
 
            // self.LoadDocxPreview(file) 
            // self.bookmarkList = response.data.data.bookmark;
          } else {
            self.flagLoadingSend = false;
          }
          self.flagLoadingSend = false
        })
        .catch(function (error) {
          self.flagLoadingSend = false;
          alert(error);
        });
    }, 

    base64ToBlob(base64, type = "application/octet-stream") {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);

      // Convert base64 string to Uint8Array
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }

      // Create and return a Blob from the Uint8Array
      return new Blob([arr], { type: type });
    },


    _Base64IntoFile(docxBase64){
      const byteCharacters = atob(docxBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

      // Create a new File object with the Blob and set the filename
      const file = new File([blob], 'filename.docx', { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

      return file;
    },
    GetBookmark() {
      let self = this;
      var reader = new FileReader();
      reader.readAsDataURL(self.files);
      reader.onload = function () {
        var getdata = reader.result.split(",");
        self.fileString = getdata[getdata.length - 1];
        // console.log(self.fileString);
        if (self.fileString != "") {
          let tempdata = {
            strFile: self.fileString,
          };
          axios
            .post(`${self.url}TemplateContact/GetBookmarks`, tempdata)
            .then(function (response) {
              if (response.data.status == 0) {
                if(response.data.data.fontEmbed == false)
                {
                  self.typeAlertSend = "warning"; //success warning error
                  self.mesAlertSend = "Please use a document with embedded fonts.";
                  self.flagAlertSend = true;
                  self.removeFile();
                }
                else if(response.data.data.bookmarkCount < 1)
                {
                  self.typeAlertSend = "warning"; //success warning error
                  self.mesAlertSend = "The bookmark was not found, please upload the file again.";
                  self.flagAlertSend = true;
                  self.removeFile();
                }
                else
                {
                  self.bookmarkList = [];
                  self.fileLink = response.data.data.linkDoc;
                  response.data.data.bookmark.forEach(function (item) {
                    var tempBookmark = {
                      bookmarkName: item,
                      bookmarkType: null,
                    };
                    self.bookmarkList.push(tempBookmark);
                  });
                }
                self.flagLoadingSend = false;
                // self.bookmarkList = response.data.data.bookmark;
              }
              else 
              {
                self.flagLoadingSend = false;
              }
            })
            .catch(function (error) {
              self.flagLoadingSend = false;
              alert(error);
            });
        } else {
          self.typeAlertSend = "warning"; //success warning error
          self.mesAlertSend = "Please attach file";
          self.flagAlertSend = true;
        }
      };
    },
    async SaveTemplateContact() {
      let self = this;
      self.flagLoadingSend = true;
      const flagBookmarkType = self.bookmarkList.filter(item => item.bookmarkType == null || item.bookmarkType == undefined);
      if (!self.fileName){
        self.flagLoadingSend = false;
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "Please upload document.";
        self.flagAlertSend = true;
      }
      else if(self.contactName == "")
      {
        self.flagLoadingSend = false;
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "Please specify a file name.";
        self.flagAlertSend = true;
      }
      else if (!self.fileName){
        self.flagLoadingSend = false;
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "Please specify a file name.";
        self.flagAlertSend = true;
      }
      else if (self.bookmarkList.length < 1) {
        self.flagLoadingSend = false;
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "The bookmark was not found, please upload the file again.";
        self.flagAlertSend = true;
      } else {
        if(flagBookmarkType.length > 0)
        {
          self.flagLoadingSend = false;
          self.typeAlertSend = "warning"; //success warning error
          self.mesAlertSend = "Please select all bookmark types.";
          self.flagAlertSend = true;
        }
        else
        { 

          let previewFileString = '' 
          if(self.previewFiles.length == undefined){
            previewFileString = await self.getFileString()
          }else{ 
            previewFileString = self.previewDocxBase64
          }

          let strFile = self.fileString
          console.log(self.fileString)
          console.log(self.docxBase64)
          if(self.fileString == ""){
            strFile = self.docxBase64
          } 

          let tempdata = {
            id: self.tempTemplateContactID,
            fileName: self.fileName,
            contactName: self.contactName, 
            strFile,
            previewStrFile: previewFileString,
            bookmark: self.bookmarkList,
          };
          // console.log(tempdata)
          axios
            .post(`${self.url}TemplateContact/SaveTemplateContact`, tempdata)
            .then(function (response) {
              if (response.data.status == 0) {
                self.flagLoadingSend = false;
                self.GotoTemplateContactList();
              } else {
                self.flagLoadingSend = false;
              }
            })
            .catch(function (error) {
              self.flagLoadingSend = false;
              alert(error);
            });
        }
      }
    },
    async getFileString() {
      return new Promise((resolve, reject) => {
        let self = this;
        let fileString = "";

        let reader = new FileReader();
        reader.readAsDataURL(self.previewFiles);
        reader.onload = function () {
          let getdata = reader.result.split(","); 
          fileString = getdata[getdata.length - 1];
          // console.log(fileString);
          resolve(fileString);
        };
        reader.onerror = function (error) {
          reject(error);
        };
      });
    },

    GotoTemplateContactList(){
      let self = this;
      self.$router.push("/TemplateContract");
    },
  },
};
</script>
<style scoped>
:deep(.Header){
  display: none !important;
}
:deep(.HeaderToolsContainer){
  display: none !important;
}
.card {
  border-radius: 10px !important;
  margin-bottom: 2rem;
  height: 90vh;
  overflow: auto;
  margin: 0;
  padding: 0;
}
.flex {
  margin-left: 2rem;
  margin-right: 0rem;
}
.circular--portrait {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

.circular--portrait img {
  width: 100%;
  height: auto;
}
.input-border {
  border: 1px solid #EBEBF2;
  height: 35px;
  padding: 4px;
}
.header-title {
  font-size: 13px;
  font-weight: 300;
}
::-webkit-input-placeholder { 
    color: #444444;
    opacity: 20%;
    font-size: 14px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
</style>