<template>
  <div>
    <v-row class="ma-0">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" style="padding-bottom: 0px;">
        <!-- <p style="font-size: 14px; color:#126496; margin-bottom: 0px; cursor: pointer;" @click="goToPreviousPage()"><v-icon color="primary">mdi-chevron-left</v-icon> Back to Home page</p> -->
        <span style="font-size: 14px; color:#126496; margin-bottom: 0px; cursor: pointer;" @click="goToPreviousPage()"><v-icon color="primary">mdi-chevron-left</v-icon> Back to Home page</span>
      </v-col>
    </v-row>
    <v-card style="box-shadow: 1px 1px 1px -1px rgb(0 0 0 / 3%), 0px 1px 1px 1px rgb(0 0 0 / 3%), 0px 1px 1px 0px rgb(0 0 0 / 3%)">
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 18px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Company Name : {{CompanyName}}
          <!-- <span class="ml-5 text-caption" style="color: #126496"
            >{{ ProductPlanCount }} Total records</span -->
        </div>
        <v-card-text>
          <v-row class="mt-5">
            <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
              <div class="d-flex">
                <v-btn
                    style="
                        background-color: #126496;
                        color: #ffffff;
                        text-transform: none !important;
                      "
                    @click="NewSubscription()"
                    absolute
                  >New Subscription</v-btn>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="1">
              <v-menu
                offset-y
                :close-on-content-click="false"
                v-model="menu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  style="border: 1px solid #EBEBF2"
                    block
                    height="40"
                    class="text-capitalize"
                    outlined
                    color="rgba(0,0,0,0.5)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Filter
                    <v-icon>mdi-filter-variant</v-icon>
                  </v-btn>
                </template>
                <v-card style="overflow: auto">
                  <v-card-text style="max-height: 50vh">
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="3">
                        <p class="mt-3">ผู้ขาย</p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="6">
                        <v-autocomplete
                              hide-no-data
                              return-object
                              :items="ListContactSale"
                              v-model="Filter.sale"
                              :search-input.sync="InputSearchSales"
                              item-text="fullName"
                              item-value="id"
                              placeholder="Sales"
                              outlined
                              dense
                              hide-details="auto"
                          ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-1"></v-divider>
                    <v-row class="mt-0">
                      <v-col cols="12" xs="12" sm="12" md="4" lg="3">
                        <p class="mt-3">สถานะงาน</p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="3">
                        <v-checkbox
                          dense
                          hide-details="auto"
                          label="ACTIVE"
                          v-model="Filter.workStatus"
                          value="2"
                        ></v-checkbox>    
                        <v-checkbox
                          dense
                          hide-details="auto"
                          label="REQUEST"
                          v-model="Filter.workStatus"
                          value="1"
                        ></v-checkbox>                                        
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="3">
                        <v-checkbox
                          dense
                          hide-details="auto"
                          label="EXPIRED"
                          v-model="Filter.workStatus"
                          value="3"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          hide-details="auto"
                          label="REJECT"
                          v-model="Filter.workStatus"
                          value="4"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-1"></v-divider>
                    <v-row class="mt-3">
                      <v-col cols="12" xs="12" sm="12" md="4" lg="3">
                        <p class="mt-3">วันที่ StartDate</p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <v-menu
                          ref="menu1"
                          v-model="menu1"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-model="Filter.StartDate"
                              readonly
                              v-on="on"
                              outlined
                              label="dd/mm/yyyy"
                              append-icon="mdi-calendar-blank"
                              dense
                              hide-details="auto"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date1"
                            no-title
                            @input="save3"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="1" class="hidden-md-and-down">
                        <p class="text-center mt-2">-</p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <v-menu
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-model="Filter.EndDate"
                              readonly
                              v-on="on"
                              outlined
                              label="dd/mm/yyyy"
                              append-icon="mdi-calendar-blank"
                              dense
                              hide-details="auto"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date2"
                            no-title
                            @input="save4"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row class="mt-3">
                      <v-col cols="12" xs="12" sm="12" md="4" lg="3">
                        <p class="mt-3">วันที่ ExpiredDate</p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <v-menu
                          ref="menuStartExpiredDate"
                          v-model="menuStartExpiredDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-model="Filter.StartExpiredDate"
                              readonly
                              v-on="on"
                              outlined
                              label="dd/mm/yyyy"
                              append-icon="mdi-calendar-blank"
                              dense
                              hide-details="auto"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="startExpiredDate"
                            no-title
                            @input="saveStartExpiredDate"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="1" class="hidden-md-and-down">
                        <p class="text-center mt-2">-</p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <v-menu
                          ref="menuEndExpiredDate"
                          v-model="menuEndExpiredDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-model="Filter.EndExpiredDate"
                              readonly
                              v-on="on"
                              outlined
                              label="dd/mm/yyy"
                              append-icon="mdi-calendar-blank"
                              dense
                              hide-details="auto"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="endExpiredDate"
                            no-title
                            @input="saveEndExpiredDate"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-3"></v-divider>
                    <div class="d-flex mt-3">
                      <v-btn
                        text
                        class="text-capitalize"
                        color="error"
                        @click="ClearSearchFilter(), date1 = null, date2 = null"
                        >Clear all filters</v-btn
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        class="text-capitalize"
                        @click="menu = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        class="text-capitalize"
                        color="primary"
                        @click="FlagSearch = true; SearchFilter()"
                        >Apply</v-btn
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="8" lg="7" xl="8">
              <v-text-field
                :class="[$vuetify.breakpoint.xlOnly ? 'ml-5' : '']"
                label="Search"
                v-model="search"
                outlined
                dense
                hide-details="auto"
                v-on:keyup.enter="SearchFilter()"
              >
              <template v-slot:prepend-inner>
                <v-icon color="primary">mdi-magnify</v-icon>
              </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            class="mt-5"
            :headers="headers"
            :items="ProductPlanList"
            hide-default-footer
            style="color: #444444;box-shadow: 1px 1px 1px -1px rgb(0 0 0 / 7%), 0px 1px 1px 1px rgb(0 0 0 / 7%), 0px 1px 1px 0px rgb(0 0 0 / 7%)"
            :items-per-page="itemsPerPage"
            :page.sync="page"
            single-line
            hide-details
          >
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <center><span>{{ item.name }}</span></center>
                    </td>
                    <td>
                        <center v-if="item.limitUser == null && item.limitSignature == null && item.checkUnlimitUser == true"><span>{{ item.limitUserUse == null ? "0" : (item.limitUserUse) }}</span></center>
                        <center v-else-if="item.limitUser == null && item.limitSignature == null && item.checkUnlimitSignature == true"><span>{{ item.limitSignatureUse == null ? "0" : (item.limitSignatureUse) }}</span></center> 
                        <center v-else-if="item.limitUser != null"><span>{{ item.limitUserUse == null ? "0" : (item.limitUser - item.limitUserUse) }}/{{ item.limitUser == null ? "-" : item.limitUser }}</span></center>
                        <center v-else><span>{{ item.limitSignatureUse == null ? "-" : (item.limitSignature - item.limitSignatureUse) }}/{{ item.limitSignature == null ? "-" : item.limitSignature }}</span></center>
                    </td>
                    <td>
                        <center><span>{{ item.sales }}</span></center>
                    </td>
                    <td>
                        <center><span>{{ item.startDate }}</span></center>
                    </td>
                    <td>
                        <center><span>{{ item.expireDate }}</span></center>
                    </td>
                    <td>
                      <center><span>{{ item.reminderDate }}</span></center>
                    </td>
                    <td>
                        <center><span>{{ item.createDate }}</span></center>
                    </td>
                    <td>
                        <center><span>{{ item.createBy }}</span></center>
                    </td>
                    <td>
                        <center><span>{{ item.updateDate }}</span></center>
                    </td>
                    <td>
                        <center><span>{{ item.updateBy }}</span></center>
                    </td>
                    <td>
                        <center>
                            <div
                                :style="{'background-color': getColor(item.status)}"
                                style="width: 150px;border-radius: 16px;font-size:14px;height:32px;margin-right:auto;margin-left:auto"
                            >
                                <p style="padding-top:5px" :style="{ color: getColorText(item.status) }">{{item.status == 1? "REQUEST": item.status == 2? "ACTIVE" : item.status == 4 ? "REJECT" : "EXPIRED"}}</p>
                            </div>
                        </center>
                    </td>
                    <td>
                        <center v-if="item.status != 3">
                            <v-menu transition="slide-y-transition" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    color="#126496"
                                    style="text-transform: none !important"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                                </template>
                                <v-list v-if="item.status == 1">
                                <v-list-item
                                    v-for="(temp, i) in itemsActionRequest"
                                    :key="i"
                                    @click="SelectAction(temp.title, item)"
                                >
                                    <v-list-item-title>{{ temp.title }}</v-list-item-title>
                                </v-list-item>
                                </v-list>
                                <v-list v-else-if="item.status == 2">
                                <v-list-item
                                    v-for="(temp, i) in itemsActionActive"
                                    :key="i"
                                    @click="SelectAction(temp.title, item)"
                                >
                                    <v-list-item-title>{{ temp.title }}</v-list-item-title>
                                </v-list-item>
                                </v-list>
                            </v-menu>
                        </center>
                    </td>
                </tr>
            </template>

          </v-data-table>
          <v-row class="mt-5">
            <v-col cols="12" xs="12" sm="12" md="4">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  style="border: 1px solid #EBEBF2"
                    v-bind="attrs"
                    v-on="on"
                    class="text-capitalize mt-1"
                    outlined
                  >
                    {{ itemsPerPage }}
                    <v-icon color="primary">mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in paginationCount"
                    :key="index"
                    @click="
                      itemsPerPage = item.title;
                      ChangePerPageProductPlan(itemsPerPage);
                    "
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4">
              <v-pagination
                
                v-model="page"
                :total-visible="6"
                :length="pageCount"
                @input="ChangePageProductPlan(page)"
              ></v-pagination>
              
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="dialogAlert" persistent max-width="600px">
      <v-card>
        <v-card-title>
        <!-- <span class="headline">{{$t('menumanagement')}}</span> -->
        <span class="headline">

        </span>
        </v-card-title>
        <v-card-text>
        <v-container>
            <div>
            {{textAlert}}
            </div>
        </v-container>
        <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <div>
            <!-- <v-btn class="btn-light" @click="ClosePopupDelete()">Close</v-btn> -->
            <v-btn class="btn-light" @click="dialogAlert = false">Close</v-btn>
        </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="flagSubscription" persistent max-width="600">
        <v-card>
          <div
            class="pt-5"
            style="
              text-color: #444444;
              font-size: 18px;
              font-weight: medium;
              margin-left: 24px;
              margin-right: 1rem;
              color: #444444;
            "
          >{{textSubscription}}</div>
          <v-card-text>
            <!-- <v-form ref="form" v-model="validateModel"> -->
              <v-row>
                <v-col cols="12" md="12" sm="12">
                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle">
                            <p>Company Name : </p>
                        </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">   
                          <div class="mt-2 txtStyle">
                            <p>{{CompanyName}}</p>
                          </div>                       
                          <!-- <v-text-field
                            class="txtStyle"
                            v-model="CompanyName"
                            outlined
                            style="border-color: #ebebf2"
                            dense
                            required
                            disabled
                          ></v-text-field> -->
                        </v-col>
                    </v-row>                  
                    <v-row no-gutters style="margin-top: -20px">
                        <v-col cols="12" sm="6" md="3">
                            <div class="mt-2 txtStyle" style="margin-right: 20px">
                                <p>Start Date : </p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                            <v-menu
                            ref="menuStartDate"
                            v-model="menuStartDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-bind="attrs"
                                v-model="formatStartDate"
                                readonly
                                v-on="on"
                                outlined
                                label="dd/mm/yyy"
                                append-icon="mdi-calendar-blank"
                                dense
                                hide-details="auto"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="StartDate"
                                no-title
                                @input="save1"
                            ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                            <div class="mt-2 txtStyle" style="margin-right: 20px">
                                <p>End Date : </p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                            <v-menu
                            ref="menuEndDate"
                            v-model="menuEndDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-bind="attrs"
                                v-model="formatEndDate"
                                readonly
                                v-on="on"
                                outlined
                                label="dd/mm/yyyy"
                                append-icon="mdi-calendar-blank"
                                dense
                                hide-details="auto"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="EndDate"
                                no-title
                                @input="save2"
                                :min="StartDate"
                            ></v-date-picker>
                            </v-menu>
                        </v-col>
                      </v-row>
                    
                    <v-row no-gutters>
                      <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                            <p>Reminder Date : </p>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="9">
                        <v-menu
                          ref="menuReminderDate"
                          v-model="menuReminderDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          >
                          <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                              v-bind="attrs"
                              v-model="formatReminderDate"
                              readonly
                              v-on="on"
                              outlined
                              label="dd/mm/yyyy"
                              append-icon="mdi-calendar-blank"
                              dense
                              hide-details="auto"
                              ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="ReminderDate"
                              no-title
                              @input="saveReminderDate"
                              :max="EndDate"
                          ></v-date-picker>
                        </v-menu> 
                      </v-col>
                    </v-row>
                    
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                            <p>Package : </p>
                        </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                            <v-autocomplete
                                hide-no-data
                                return-object
                                :items="ListPackage"
                                v-model="Package"
                                :search-input.sync="InputSearchPackage"
                                item-text="name"
                                item-value="id"
                                placeholder="Package"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                            <p>Sales : </p>
                        </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                          <v-autocomplete
                              hide-no-data
                              return-object
                              :items="ListContactSale"
                              v-model="ContactSale"
                              :search-input.sync="InputSearchSales"
                              item-text="fullName"
                              item-value="id"
                              placeholder="Sales"
                              outlined
                              dense
                              hide-details="auto"
                          ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                            <p>Remark : </p>
                        </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                        <v-textarea
                            class="txtStyle"
                            v-model="Detail"
                            outlined
                            style="border-color: #ebebf2"
                            dense
                            required
                            placeholder="Remark"
                        ></v-textarea>
                        </v-col>
                    </v-row>
                </v-col>
              </v-row>
              <v-divider style="color: #ebebf2"></v-divider>
            <!-- </v-form> -->
          </v-card-text>
          <v-card-actions style="margin-right: 20px" class="pb-5">
            <v-spacer></v-spacer>
            <v-btn color="#444444" width="100px;" @click="flagSubscription = false" text>
              <p class="mt-4 text-capitalize ml-2">Cancel</p>
            </v-btn>
            <v-btn
              @click="AddSubscription()"
              style="background-color: #126496; color: #ffffff; width: 100px"
              text
            >
              <!-- @click="Submit()" -->
              <p class="mt-4 text-capitalize">Save</p>
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="flagEditSubscription" persistent max-width="600">
        <v-card>
          <div
            class="pt-5"
            style="
              text-color: #444444;
              font-size: 18px;
              font-weight: medium;
              margin-left: 24px;
              margin-right: 1rem;
              color: #444444;
            "
          >Edit Customer Subscription</div>
          <v-card-text>
            <!-- <v-form ref="form" v-model="validateModel"> -->
              <v-row>
                <v-col cols="12" md="12" sm="12">
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                            <p>Sales : </p>
                        </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                          <v-autocomplete
                              hide-no-data
                              return-object
                              :items="ListContactSale"
                              v-model="ContactSale"
                              :search-input.sync="InputSearchSales"
                              item-text="fullName"
                              item-value="id"
                              placeholder="Sales"
                              outlined
                              dense
                              hide-details="auto"
                          ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                            <div class="mt-2 txtStyle" style="margin-right: 20px">
                                <p>Start Date : </p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                            <v-menu
                            ref="menuStartDate2"
                            v-model="menuStartDate2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-bind="attrs"
                                v-model="formatStartDate"
                                readonly
                                v-on="on"
                                outlined
                                label="dd/mm/yyyy"
                                append-icon="mdi-calendar-blank"
                                dense
                                hide-details="auto"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="StartDate"
                                no-title
                                @input="save1"
                            ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                            <div class="mt-2 txtStyle" style="margin-right: 20px">
                                <p>End Date : </p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                            <v-menu
                            ref="menuEndDate2"
                            v-model="menuEndDate2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-bind="attrs"
                                v-model="formatEndDate"
                                readonly
                                v-on="on"
                                outlined
                                label="dd/mm/yyyy"
                                append-icon="mdi-calendar-blank"
                                dense
                                hide-details="auto"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="EndDate"
                                no-title
                                @input="save2"
                                :min="StartDate"
                            ></v-date-picker>
                            </v-menu>
                        </v-col>
                      </v-row>
                    
                    <v-row no-gutters>
                      <v-col cols="12" sm="6" md="3">
                            <div class="mt-2 txtStyle" style="margin-right: 20px">
                                <p>Reminder Date : </p>
                            </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="9">
                       <v-menu
                          ref="menuReminderDate2"
                          v-model="menuReminderDate2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          >
                          <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                              v-bind="attrs"
                              v-model="formatReminderDate"
                              readonly
                              v-on="on"
                              outlined
                              label="dd/mm/yyyy"
                              append-icon="mdi-calendar-blank"
                              dense
                              hide-details="auto"
                              ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="ReminderDate"
                              no-title
                              @input="saveReminderDate"
                              :max="EndDate"
                          ></v-date-picker>
                          </v-menu>
                        </v-col>
                    </v-row>
                </v-col>
              </v-row>
              <v-divider style="color: #ebebf2"></v-divider>
            <!-- </v-form> -->
          </v-card-text>
          <v-card-actions style="margin-right: 20px" class="pb-5">
            <v-spacer></v-spacer>
            <v-btn color="#444444" width="100px;" @click="flagEditSubscription = false" text>
              <p class="mt-4 text-capitalize ml-2">Cancel</p>
            </v-btn>
            <v-btn
              @click="EditSubscription()"
              style="background-color: #126496; color: #ffffff; width: 100px"
              text
            >
              <!-- @click="Submit()" -->
              <p class="mt-4 text-capitalize">Save</p>
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="flagApproveSubscription" persistent max-width="600">
        <v-card>
          <div
            class="pt-5"
            style="
              text-color: #444444;
              font-size: 18px;
              font-weight: medium;
              margin-left: 24px;
              margin-right: 1rem;
              color: #444444;
            "
          >Approve Request</div>
          <v-card-text>
            <!-- <v-form ref="form" v-model="validateModel"> -->
              <v-row>
                <v-col cols="12" md="12" sm="12">
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                            <p>Company Name : </p>
                        </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">                          
                          <v-text-field
                            class="txtStyle"
                            v-model="CompanyName"
                            outlined
                            style="border-color: #ebebf2"
                            dense
                            required
                            disabled
                          ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                            <div class="mt-2 txtStyle" style="margin-right: 20px">
                                <p>Start Date : </p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                            <v-menu
                            ref="menu1App"
                            v-model="menu1App"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-bind="attrs"
                                v-model="formatStartDateApp"
                                readonly
                                v-on="on"
                                outlined
                                label="dd/mm/yyyy"
                                append-icon="mdi-calendar-blank"
                                dense
                                hide-details="auto"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="StartDateApp"
                                no-title
                                @input="saveSDateApp"
                            ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                            <div class="mt-2 txtStyle" style="margin-right: 20px">
                                <p>End Date : </p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                            <v-menu
                            ref="menu2App"
                            v-model="menu2App"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-bind="attrs"
                                v-model="formatEndDateApp"
                                readonly
                                v-on="on"
                                outlined
                                label="dd/mm/yyyy"
                                append-icon="mdi-calendar-blank"
                                dense
                                hide-details="auto"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="EndDateApp"
                                no-title
                                @input="saveEDateApp"
                                :min="StartDateApp"
                            ></v-date-picker>
                            </v-menu>
                        </v-col>
                      </v-row>
                    
                    <v-row no-gutters>  
                      <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                            <p>Reminder Date : </p>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="9">
                        <v-menu
                            ref="menu3App"
                            v-model="menu3App"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            >
                          <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                              v-bind="attrs"
                              v-model="formatReminderDateApp"
                              readonly
                              v-on="on"
                              outlined
                              label="dd/mm/yyyy"
                              append-icon="mdi-calendar-blank"
                              dense
                              hide-details="auto"
                              ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="ReminderDateApp"
                              no-title
                              @input="saveReminderDateApp"
                              :max="EndDateApp"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                            <p>Package : </p>
                        </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                            <v-autocomplete
                                hide-no-data
                                return-object
                                :items="ListPackage"
                                v-model="Package"
                                :search-input.sync="InputSearchPackage"
                                item-text="name"
                                item-value="id"
                                placeholder="Package"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                            <p>Sales : </p>
                        </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                          <v-autocomplete
                              hide-no-data
                              return-object
                              :items="ListContactSale"
                              v-model="ContactSale"
                              :search-input.sync="InputSearchSales"
                              item-text="fullName"
                              item-value="id"
                              placeholder="Sales"
                              outlined
                              dense
                              hide-details="auto"
                          ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                            <p>Remark : </p>
                        </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                        <v-textarea
                            class="txtStyle"
                            v-model="Detail"
                            outlined
                            style="border-color: #ebebf2"
                            dense
                            required
                            placeholder="Remark"
                        ></v-textarea>
                        </v-col>
                    </v-row>
                </v-col>
              </v-row>
              <v-divider style="color: #ebebf2"></v-divider>
            <!-- </v-form> -->
          </v-card-text>
          <v-card-actions style="margin-right: 20px" class="pb-5">
            <v-spacer></v-spacer>
            <v-btn color="#444444" width="100px;" @click="flagApproveSubscription = false;ClearData();" text>
              <p class="mt-4 text-capitalize ml-2">Cancel</p>
            </v-btn>
            <v-btn
              @click="ApproveSubscription()"
              style="background-color: #126496; color: #ffffff; width: 100px"
              text
            >
              <!-- @click="Submit()" -->
              <p class="mt-4 text-capitalize">Save</p>
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="flagRejectSubscription" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <!-- <span class="headline">{{$t('menumanagement')}}</span> -->
          <span class="headline" style="color: red;">
            <v-img src="@/assets/Group7605.png" width="30" class="mx-auto"></v-img>Reject
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div>Are you sure you want to reject this package ?</div>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div>
            <v-btn class="btn-light" @click="flagRejectSubscription = false; TProductID = null">Close</v-btn>
            <v-btn class="btn-danger" @click="RejectSubscription()">Reject</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />
    
    <Loading
      :flagLoading="flagLoadingSend"
    />
    
  </div>
</template>

<script>
import axios from "axios";
// import store from "../store";
import enurl from "@/api/environment";
import * as moment from "moment/moment";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components: {
    Alert,
    Loading,
  },
  data: () => ({
    flagAlertSend: false,
    mesAlertSend: "",
    typeAlertSend: "success",
    flagDel: true,
    textSubscription: "",
    flagSubscription: false,
    flagEditSubscription: false,
    flagApproveSubscription: false,
    flagRejectSubscription: false,
    TProductID: null,
    InputSearchCompany: null,
    ListCompany: [],
    Company: "",
    menuStartDate: false,
    menuStartDate2: false,
    menuEndDate: false,
    menuEndDate2: false,
    StartDate: null,
    EndDate: null,
    formatStartDate: null,
    formatEndDate: null,
    formatReminderDate: null,
    InputSearchSales: null,
    ListContactSale: [],
    ContactSale: "",
    InputSearchPackage: null,
    ListPackage: [],
    Package: "",
    Detail: "",
    url: enurl.apiUrl,
    dialogAlert: false,
    textAlert: "",
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    items: [10, 15, 20],
    ProductPlanList: [],
    CompanyID: 0,
    CompanyName: "",
    ProductPlanCount: null,
    flagLoadingSend: false,
    no_run: null,
    Filter: {
      sale: null,
      workStatus: null,
      StartDate: null,
      EndDate: null,
      StartExpiredDate: null,
      EndExpiredDate: null
    },
    menu: false,
    date1: null,
    menu1: false,
    date2: null,
    menu2: false,

    menuStartExpiredDate: null,
    startExpiredDate: null,
    menuEndExpiredDate: null,
    endExpiredDate: null,
    menuReminderDate: null,
    menuReminderDate2: null,
    ReminderDate: null,

    menu1App: false,
    formatStartDateApp: null,
    StartDateApp: null,
    menu2App: false,
    formatEndDateApp: null,
    EndDateApp: null,
    menu3App: false,
    formatReminderDateApp: null,
    ReminderDateApp: null,
    FlagSearch: false,

    itemsActionRequest: [
      { title: "Approve Request" },
      { title: "Reject Request" },
    ],
    itemsActionActive: [
      { title: "Edit Package" },
    ],
    paginationCount: [
      { title: 10 },
      { title: 20 },
      { title: 30 },
      { title: 40 },
    ],
  }),
  computed: {
    headers() {
      return [
        {
          text: "Current Package",
          sortable: false,
          align: "center",
          class: "darkprimary white--text ",
        },
        {
          text: "Current Usage",
          sortable: false,
          align: "center",
          class: "darkprimary white--text",
        },
        {
          text: "Sales",
          sortable: false,
          align: "center",
          class: "darkprimary white--text",
        },
        {
          text: "Start Date",
          sortable: false,
          align: "center",
          class: "darkprimary white--text",
        },
        {
          text: "Expire Date",
          sortable: false,
          align: "center",
          class: "darkprimary white--text",
        },
        {
          text: "Reminder Date",
          sortable: false,
          align: "center",
          class: "darkprimary white--text",
        },
        {
          text: "Add Date",
          sortable: false,
          align: "center",
          class: "darkprimary white--text",
        },
        {
          text: "Add By",
          sortable: false,
          align: "center",
          class: "darkprimary white--text",
        },
        {
          text: "Edit Date",
          sortable: false,
          align: "center",
          class: "darkprimary white--text",
        },
        {
          text: "Edit BY",
          sortable: false,
          align: "center",
          class: "darkprimary white--text",
        },
        {
          text: "Status",
          sortable: false,
          align: "center",
          class: "darkprimary white--text",
        },
        {
          text: "More",
          sortable: false,
          align: "center",
          class: "darkprimary white--text rounded-tr-lg",
        },
      ];
    },
  },
  methods: {
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    save1(StartDate) {
      this.formatStartDate = this.formatDate(StartDate);
      // this.menuEndDate = false;
      this.menuStartDate = false;
    },
    save2(EndDate) {
      let tempDate = new Date(EndDate)
      this.formatEndDate = this.formatDate(EndDate);
      tempDate.setDate(tempDate.getDate()-30);
      this.formatReminderDate = this.formatDate((new Date(tempDate).getFullYear()) +"-" + 
                                ((new Date(tempDate).getMonth()+1).toString().padStart(2,"0")) +"-"+
                                ((new Date(tempDate).getDate()).toString().padStart(2,"0")));
      
      // this.menuStartDate = false;
      this.menuEndDate = false;
    },
    saveReminderDate(ReminderDate) {
      this.formatReminderDate = this.formatDate(ReminderDate);
      // this.menuStartDate = false;
      this.menuReminderDate = false;
    },
    saveSDateApp(StartDate) {
      this.formatStartDateApp = this.formatDate(StartDate);
      // this.menuEndDate = false;
      this.menu1App = false;
    },
    saveEDateApp(EndDate) {
      let tempDate = new Date(EndDate)
      this.formatEndDateApp = this.formatDate(EndDate);
      tempDate.setDate(tempDate.getDate()-30);
      this.formatReminderDateApp = this.formatDate((new Date(tempDate).getFullYear()) +"-" + 
                                ((new Date(tempDate).getMonth()+1).toString().padStart(2,"0")) +"-"+
                                ((new Date(tempDate).getDate()).toString().padStart(2,"0")));
      // this.menuStartDate = false;
      this.menu2App = false;
    },
    saveReminderDateApp(ReminderDate) {
      this.formatReminderDateApp = this.formatDate(ReminderDate);
      // this.menuReminderDate = false;
      this.menu3App = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    ChangeFormatDate(value){
      if (!value) return null;
      let date = value.split("/");
      let day = date[0];
      let month = date[1];
      let year = date[2];
      if(year > 2500){
        year = year - 543;
      }
      return `${day}/${month}/${year}`;
    },

    save3(date1) {
      this.Filter.StartDate = this.formatDate(date1);
      this.menu1 = false;
    },
    save4(date2) {
      this.Filter.EndDate = this.formatDate(date2);
      this.menu2 = false;
    },
    saveStartExpiredDate(date) {
      this.Filter.StartExpiredDate = this.formatDate(date);
      this.menuStartExpiredDate = false;
    },
    saveEndExpiredDate(date) {
      this.Filter.EndExpiredDate = this.formatDate(date);
      this.menuEndExpiredDate = false;
    },
    getColor(e) {
      if (e == 1) {
        return "rgb(18, 100, 150,0.3)";
      } else if (e == 2) {
        return "rgb(92, 184, 92,0.3)";
      } 
      else if (e == 4) {
        return "rgb(103, 58, 183, 0.3)";
      }
      else {
        return "rgb(232, 34, 34,0.3)";
      }
    },
    getColorText(e) {
      if (e == 1) {
        return "rgb(18, 100, 150)";
      } else if (e == 2) {
        return "rgb(92, 184, 92)";
      } 
      else if (e == 4) {
        return "rgb(103, 58, 183)";
      }
      else {
        return "rgb(232, 34, 34)";
      }
    },

    ClearSearchFilter(){
      let self = this;
      self.Filter.workStatus = null;
      self.Filter.sale = null;
      self.Filter.StartDate = null;
      self.Filter.EndDate = null;
      self.Filter.StartExpiredDate = null;
      self.Filter.EndExpiredDate = null;
    },
    
    goToPreviousPage(){
      this.$router.push('/SubscriptionManagement');
    },

    SelectAction(value, id) {
      let self = this;
      if (value == "Edit Package") {
        self.flagLoadingSend = true;
        let temp = {
          ID: id.tProductID,
        };
        axios
          .post(`${self.url}ProductPlan/GetProductPlanById`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
                self.TProductID = response.data.data.tProduct_CompanyID;
                self.Company = response.data.data.company;
                self.ListCompany.push(response.data.data.company);
                self.formatStartDate = response.data.data.startDate;
                self.formatEndDate = response.data.data.endDate;
                if(response.data.data.reminderDate==null){
                  self.save2(self.formatDate(self.formatEndDate.replaceAll("/","-")).replaceAll("/","-"));
                }else{
                  self.formatReminderDate = response.data.data.reminderDate;
                }
                self.Package = response.data.data.productPlan;
                self.ListPackage.push(response.data.data.productPlan);
                self.ContactSale = response.data.data.sales;
                self.ListContactSale.push(response.data.data.sales);
                self.Detail = response.data.data.detail;
                self.flagEditSubscription = true;
                self.flagLoadingSend = false;
            }
            else{
              self.flagLoadingSend = false;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            alert(error);
          });
      }
      if(value == "Approve Request")
      {
        self.TProductID = id.tProductID;
        let temp = {
          ID: self.TProductID,
        };
        axios
          .post(`${self.url}ProductPlan/GetProductPlanById`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
                self.TProductID = response.data.data.tProduct_CompanyID;
                self.Company = response.data.data.company;
                self.ListCompany.push(response.data.data.company);
                self.formatStartDateApp = response.data.data.startDate;
                self.formatEndDateApp = response.data.data.endDate;
                if(response.data.data.reminderDate==null){
                  self.saveEDateApp(self.formatDate(self.formatEndDateApp.replaceAll("/","-")).replaceAll("/","-"));
                }else{
                  self.formatReminderDateApp = response.data.data.reminderDate;
                }
                self.Package = response.data.data.productPlan;
                self.ListPackage.push(response.data.data.productPlan);
                self.ContactSale = response.data.data.sales;
                self.ListContactSale.push(response.data.data.sales);
                self.Detail = response.data.data.detail;
                self.flagLoadingSend = false;
                self.flagApproveSubscription = true;   
            }
            else{
              self.flagLoadingSend = false;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            alert(error);
          });            
      }
      if (value == "Reject Request") 
      {
        self.TProductID = id.tProductID;
        self.flagRejectSubscription = true;
      }
    },
    NewSubscription(){
        let self = this;
        self.Company = "";
        self.formatStartDate = "";
        self.formatEndDate = "";
        self.formatReminderDate = "";
        self.Package = "";
        self.ContactSale = "";
        self.Detail = "";
        const dateNow = (new Date(Date.now()).getFullYear()) +"-" + 
                                ((new Date(Date.now()).getMonth()+1).toString().padStart(2,"0")) +"-"+
                                ((new Date(Date.now()).getDate()).toString().padStart(2,"0"))
        self.StartDate = dateNow;
        self.EndDate = dateNow;
        self.ReminderDate = dateNow;
        self.textSubscription = "New Subscription";
        self.flagSubscription = true;
    },

    ClearData(){
      let self = this;
      self.Company = "";
      self.formatStartDate = "";
      self.formatEndDate = "";
      self.formatReminderDate = "";
      self.formatStartDateApp = "";
      self.formatEndDateApp = "";
      self.formatReminderDateApp = "";
      self.Package = "";
      self.ContactSale = "";
      self.Detail = "";
      self.StartDateApp = "";
      self.EndDateApp = "";
      self.ReminderDateApp = "";
    },

    downloadURI(uri, name) {
      var link = document.createElement("a");
      // link.download = name;
      link.setAttribute("download", name);
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // delete link;
    },

    GetProductPlan: function () {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        Page: self.page,
        Perpage: self.itemsPerPage,
        Search: self.search,
        CompanyID: parseInt(self.CompanyID),
        Filter: self.FlagSearch == true ? self.Filter : {sale: null, workStatus: null, StartDate: null, EndDate: null, StartExpiredDate: null, EndExpiredDate: null}
      };
      axios
        .post(`${self.url}ProductPlan/GetTProductCompanyByCompanyList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ProductPlanList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.ProductPlanCount = response.data.data.dataCount;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.pageCount = 0;
            self.ProductPlanList = [];
          }
        })
        .catch(function (error) {
          self.flagLoadingSend = false;
          alert(error);
        });
    },

    ChangePageProductPlan: function (value) {
      let self = this;
      self.flagLoadingSend = true;
        let temp = {
          Search: self.search,
          Page: value,
          Perpage: self.itemsPerPage,
          CompanyID: parseInt(self.CompanyID),
          Filter: self.FlagSearch == true ? self.Filter : {sale: null, workStatus: null, StartDate: null, EndDate: null, StartExpiredDate: null, EndExpiredDate: null}
        };
        axios
          .post(`${self.url}ProductPlan/GetTProductCompanyByCompanyList`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.ProductPlanList = response.data.data.listData;
              self.pageCount = response.data.data.count;
              self.no_run = (self.page - 1) * self.itemsPerPage;
              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
              self.ProductPlanList = [];
              self.pageCount = 0;
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = "ไม่พบข้อมูล";
              self.flagAlertSend = true;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            alert(error);
          });
    },

    ChangePerPageProductPlan: function (value) {
      let self = this;
      self.flagLoadingSend = true;
        let tempdata = {
          Search: self.search,
          Page: 1,
          Perpage: value,
          CompanyID: parseInt(self.CompanyID),
          Filter: self.FlagSearch == true ? self.Filter : {sale: null, workStatus: null, StartDate: null, EndDate: null, StartExpiredDate: null, EndExpiredDate: null}
        };
        axios
          .post(`${self.url}ProductPlan/GetTProductCompanyByCompanyList`, tempdata)
          .then(function (response) {
            if (response.data.status == 0) {
              self.ProductPlanList = response.data.data.listData;
              self.pageCount = response.data.data.count;
              self.page = 1;
              self.no_run = (self.page - 1) * self.itemsPerPage;

              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
              self.ProductPlanList = [];
              self.pageCount = 0;
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = "ไม่พบข้อมูล";
              self.flagAlertSend = true;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            alert(error);
          });
    },
    SearchFilter() {
      let self = this;
      if((new Date(self.date1) <= new Date(self.date2) || (self.date1 == null && self.date2 == null)) && (new Date(self.startExpiredDate) <= new Date(self.endExpiredDate) || (self.startExpiredDate == null && self.date2 == null))){
        self.flagLoadingSend = true;
        let temp = {
          Page: 1,
          Perpage: self.itemsPerPage,
          Search: self.search,
          CompanyID: parseInt(self.CompanyID),
          Filter: self.FlagSearch == true ? self.Filter : {sale: null, workStatus: null, StartDate: null, EndDate: null, StartExpiredDate: null, EndExpiredDate: null}
        };

        axios
        .post(`${self.url}ProductPlan/GetTProductCompanyByCompanyList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ProductPlanList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.page = 1;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.pageCount = 0;
            self.ProductPlanList = [];
          }
        })
        .catch(function (error) {
            self.flagLoadingSend = false;
            alert(error);
        });
      }
      else{
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณาเลือก StartDate และ EndDate ให้ถูกต้อง";
        self.flagAlertSend = true;
      }       
    },

    async AddSubscription () {
      let self = this;
      if((self.CompanyName != null && self.CompanyName != "") && (self.Package != null && self.Package != "") && (self.ContactSale != null && self.ContactSale != ""))
      {
        let TempSend = {
            CompanyID : parseInt(self.CompanyID),
            StartDate : self.formatStartDate,
            EndDate : self.formatEndDate,
            ReminderDate : self.formatReminderDate,
            ProductPlanID : self.Package.id,
            SalesID : self.ContactSale.id,
            Detail : self.Detail,
        }
        // console.log(TempSend);
        await axios
        .post(`${self.url}ProductPlan/AddSubscription`, TempSend)
        .then(function (response) {
            if (response.data.status == 0) {
            // console.log(response.data);
            self.flagSubscription = false;
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
            self.ClearData();
            self.GetProductPlan();
            }
        })
        .catch(function (error) {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
      }
      else{
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณากรอกข้อมูลให้ครบถ้วน !";
        self.flagAlertSend = true;
      }
    },

    async ApproveSubscription () {
      let self = this;
      if((self.CompanyName != null && self.CompanyName != "") && (self.Package != null && self.Package != "") && (self.ContactSale != null && self.ContactSale != ""))
      {
        self.flagLoadingSend = true;
        let TempSend = {
            TProductCompanyID: parseInt(self.TProductID),
            CompanyID : parseInt(self.CompanyID),
            // StartDate : self.formatStartDateApp,
            // EndDate : self.formatEndDateApp,
            StartDate : self.ChangeFormatDate(self.formatStartDateApp),
            EndDate : self.ChangeFormatDate(self.formatEndDateApp),
            ProductPlanID : parseInt(self.Package == null ? null : self.Package.id),
            SalesID : parseInt(self.ContactSale == null ? null : self.ContactSale.id),
            Detail : self.Detail,
        }
        // console.log(TempSend);
        await axios
        .post(`${self.url}ProductPlan/ApproveSubscription`, TempSend)
        .then(function (response) {
            if (response.data.status == 0) {
            self.flagApproveSubscription = false;
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
            self.GetProductPlan();
            }
            self.flagLoadingSend = false;
        })
        .catch(function (error) {
          self.flagLoadingSend = false;
          alert(error);
        });
      }
      else{
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณากรอกข้อมูลให้ครบถ้วน !";
        self.flagAlertSend = true;
      }
    },

    async RejectSubscription(){
      let self = this;
        self.flagLoadingSend = true;
        let temp = {
          ID: self.TProductID,
        };
        axios
          .post(`${self.url}ProductPlan/RejectProductPlanById`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.flagLoadingSend = false;
              self.flagRejectSubscription = false;
              self.GetProductPlan();
            }
            else{
              self.flagLoadingSend = false;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            alert(error);
          });
    },
    
    async EditSubscription () {
      let self = this;
      if(self.ContactSale != null && 
      self.formatStartDate != null && self.formatStartDate != "" && 
      self.formatEndDate != null && self.formatEndDate != "")
      {
        let temp = {
          TProduct_CompanyID: self.TProductID,
          SalesID : self.ContactSale.id,
          StartDate : self.formatStartDate,
          EndDate : self.formatEndDate,
          ReminderDate : self.formatReminderDate,
        };
        axios
          .post(`${self.url}ProductPlan/UpdateSubscription`, temp)
          .then(function (response) {
              if (response.data.status == 0) {
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = response.data.message;
              self.flagAlertSend = true;
              self.flagEditSubscription = false;
              self.GetProductPlan();
              }
          })
          .catch(function (error) {
              alert(error);
          });
      }
    },

    UTCToLocal(dateStr, format) {
      if (typeof dateStr == "string") {
        let newDateStr = dateStr;
        if (!newDateStr.match(/Z$/)) {
          newDateStr += "Z";
        }
        return moment(newDateStr).format(format);
      }
      return "";
    },
  },

  watch: {
      InputSearchCompany: function (val) {
      if (val != null && val != "") {
        if (val.length >= 2) {
          let self = this;
          self.flagLoadingSend = true;
          let tempdata = {
            Search: val,
          };
          axios
            .post(`${self.url}Company/RetrieveCompanyByCompanyName`, tempdata)
            .then(function (response) {
              if (response.data.status == 0) {
                self.ListCompany = response.data.data;
              } else {
                self.ListCompany = [];
              }
              self.flagLoadingSend = false;
            })
            .catch(function (error) {
              self.flagLoadingSend = false;
              self.ListCompany = [];
              alert(error);
            });
        }
      }
    },
    InputSearchSales: function (val) {
      if (val != null && val != "") {
        if (val.length >= 2) {
          let self = this;
          self.flagLoadingSend = true;
          let tempdata = {
            Search: val,
          };
          axios
            .post(`${self.url}Sales/GetSalesBySalesNameAsync`, tempdata)
            .then(function (response) {
              if (response.data.status == 0) {
                self.ListContactSale = response.data.data;
              } else {
                self.ListContactSale = [];
              }
              self.flagLoadingSend = false;
            })
            .catch(function (error) {
              self.flagLoadingSend = false;
              self.ListContactSale = [];
              alert(error);
            });
        }
      }
    },
    InputSearchPackage: function (val) {
      if (val != null && val != "") {
        if (val.length >= 2) {
          let self = this;
          self.flagLoadingSend = true;
          let tempdata = {
            Search: val,
          };
          axios
            .post(`${self.url}ProductPlan/GetDataProductPlanByPlanName`, tempdata)
            .then(function (response) {
              if (response.data.status == 0) {
                self.ListPackage = response.data.data;
              } else {
                self.ListPackage = [];
              }
              self.flagLoadingSend = false;
            })
            .catch(function (error) {
              self.flagLoadingSend = false;
              self.ListPackage = [];
              alert(error);
            });
        }
      }
    },
  },

  mounted() {
    this.CompanyID = this.$route.params.CompanyID;
    this.CompanyName = this.$route.params.CompanyName;
    this.CompanyID = localStorage.getItem("CompanyID");
    this.CompanyName = localStorage.getItem("CompanyName");
    this.GetProductPlan();
    const elements = document.querySelectorAll(".v-pagination__navigation");
    
    for (const [index, element] of elements.entries()) {
      if (index % 2 != 0) {
        element.textContent = "Next";
      } else {
        element.textContent = "Previous";
      }
    }
  },
};
</script>
<style scoped>
@media only screen and (min-width: 1030px) and (max-width: 1409px) {
  .per_page {
    margin-left: -5rem !important;
  }
  .v-select > .v-input__control > .v-input__slot {
    width: 80px !important;
  }
}
tbody tr:nth-of-type(even) {
  background-color: #1264960d;
}
.v-text-field--outlined fieldset {
  color: #ebebf2 !important;
}
.v-data-table-header {
  background-color: #126496 !important;
  color: #ffffff !important;
}
.v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-pagination .v-pagination__item--active {
  background: #126496 !important;
  color: white !important;
}
*>>>.v-pagination__item{
  margin: unset !important;
  border-radius: unset !important;
  box-shadow: unset !important;
  border: 1px solid rgba(0, 0, 0, 0.1)
}
*>>>.v-pagination__navigation{
  margin: unset !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 80px !important;
  height: 34px !important;
  border-radius: unset !important;
  box-shadow: 0px 0px 6px #0000000A !important;
}

.v-text-field--outlined >>> fieldset {
  border: 1px solid #EBEBF2
}

.downArrowAndUpArrow >>>.v-icon.v-icon{
  color: #126496;
}
</style>
<style lang="scss">
.__pagination {
  .v-pagination__navigation {
    box-shadow: none !important;
    border: 1px solid #0000001a;
    padding-left: 10px;
    padding-right: 10px;
    &:focus {
      outline: none;
    }
  }
}

.btn-danger {
  background: #e82222 !important;
  color: #ffffff !important;
}
</style>