<template>
  <div>
    <v-container fluid>
      <v-card class="card">
        <v-toolbar color="primary" dark>
          <v-toolbar-title class="title">{{$t('calendar')}}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-title class="title">{{today}}</v-toolbar-title>
        </v-toolbar>
        <v-sheet>
          <v-toolbar flat color="white">
            <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">Today</v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on }">
                <v-btn outlined color="grey darken-2" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet class="ml-4 mr-4" height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            :key="schedule"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :now="today"
            :type="type"
            @click:event="showEvent"
            @click:date="show"
            @click:more="viewDay"
            @change="getEvent"
          ></v-calendar>
        </v-sheet>
      </v-card>
    </v-container>
    <!-- AddDialog -->
    <v-dialog v-model="dialog" persistent width="550">
      <v-card class="pa-3" color="#1976D2" dark>
        <v-card-title class="pa-0">
          <span>Schedule Name</span>
          <v-spacer></v-spacer>
          <v-btn class="ma-0" fab color="#1976D2" @click="dialog = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-text-field
          label="Enter Your Schedule Name"
          single-line
          v-model="schedulename"
          solo
          light
          style="margin-top:30px"
        ></v-text-field>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="5">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date"
                    label="Start Date"
                    prepend-icon="mdi-calendar-blank-outline"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu1 = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menu1.save(date)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="5">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date1"
                    label="End Date"
                    prepend-icon="mdi-calendar-blank-outline"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date1" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menu2.save(date)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Close</v-btn>
          <v-btn text @click="AddEvent">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- UpdateDialog -->
    <v-dialog
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      width="600px"
      offset-x
    >
      <v-card class="pa-3" color="#1976D2" dark>
        <v-card-title class="pa-0">
          <span>Update Calendar Event</span>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-0 mr-2"
            fab
            color="#1976D2"
            @click="DeleteEvent(update.eventId)"
            :disabled="permission.delete"
            small
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn class="ma-0" fab color="#1976D2" @click="selectedOpen = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-text-field
          label="Enter Your Schedule Name"
          v-model="update.eventname"
          single-line
          solo
          light
          style="margin-top:30px"
        ></v-text-field>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="5">
              <v-menu
                ref="menuthree"
                v-model="menuthree"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="update.startdate"
                    label="Start Date"
                    prepend-icon="mdi-calendar-blank-outline"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="update.startdate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuthree = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menuthree.save(date)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="5">
              <v-menu
                ref="menufour"
                v-model="menufour"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="update.enddate"
                    label="End Date"
                    prepend-icon="mdi-calendar-blank-outline"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="update.enddate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menufour = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menufour.save(date)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="selectedOpen = false">Close</v-btn>
          <v-btn text @click="UpdateEvent">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "../store";
import Alert from '@/components/Alert';
export default {
  components: {
    Alert,
  },
  data: () => ({
    flagAlertSend: false,
    mesAlertSend: "",
    typeAlertSend: "success",
    url: enurl.apiUrl,
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    userid: store.state.userid,
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week"
    },
    permission: {
      delete: false
    },
    update: {
      eventId: null,
      eventname: null,
      startdate: null,
      enddate: null
    },
    date1: null,
    menufour: null,
    Startdate: null,
    Enddate: null,
    menu2: null,
    menu1: null,
    menuthree:null,
    name: null,
    details: null,
    start: null,
    end: null,
    schedule: null,
    schedulename: null,
    // title: null,
    date: null,
    currentlyEditing: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    dialog: false,
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1"
    ]
  }),
  computed: {
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long"
      });
    }
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    show() {
      let self = this;
      self.dialog = true;
    },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "week";
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    formatDate(a) {
      return a
        ? `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
    getEvent({ start, end }) {
      let self = this;
      let events = [];

      axios
        .post(`${self.url}CalendarEvent/GetCalendarEventALL`)
        .then(function(response) {
          self.eventList = response.data.data;

          for (let i = 0; i < self.eventList.length; i++) {
            let eid = response.data.data[i].eventID;
            let name1 = response.data.data[i].eventName;
            let startdate = response.data.data[i].startDate.split("-");
            let time1 = startdate[2].split("T");
            let realdate1 = startdate[0] + "-" + startdate[1] + "-" + time1[0];
            let StartDate = new Date(realdate1);
            let enddate = response.data.data[i].endDate.split("-");
            let time2 = enddate[2].split("T");
            let realdate2 = enddate[0] + "-" + enddate[1] + "-" + time2[0];
            let EndDate = new Date(realdate2);
            let rndcolor = self.colors[self.rnd(0, self.colors.length - 1)];
            events.push({
              eventid: eid,
              name: name1,
              start: self.formatDate(StartDate),
              end: self.formatDate(EndDate),
              color: rndcolor
            });
          }

          self.events = events;
          self.start = start;
          self.end = end;
        })
        .catch(function(error) {
          alert(error);
        });
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.update.eventId = this.selectedEvent.eventid;
        this.update.eventname = this.selectedEvent.name;
        this.update.startdate = this.selectedEvent.start;
        this.update.enddate = this.selectedEvent.end;
        this.selectedElement = nativeEvent.target;
        // setTimeout(() => (this.selectedOpen = true), 10);
      };
      // if (this.selectedOpen) {
      //   this.selectedOpen = false;
      //   setTimeout(open, 10);
      // } else {
      open();
      // }
      nativeEvent.stopPropagation();
    },
    AddEvent() {
      let self = this;
      if (
        self.schedulename != null &&
        self.date != null &&
        self.date1 != null
      ) {
        let tempschedule = {
          userID: self.userid,
          eventName: self.schedulename,
          startDate: self.date,
          endDate: self.date1
        };
        axios
          .post(`${self.url}CalendarEvent/AddCalendarEvent`, tempschedule)
          .then(function(response) {
            if (response.data.status == 0) {
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = response.data.message;
              self.flagAlertSend = true;
              self.dialog = false;
              self.userid = null;
              self.schedulename = null;
              self.date = null;
              self.date1 = null;
              window.location.reload();
              self.getEvent();
            }
          });
      } else {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "please add your schedule information";
        self.flagAlertSend = true;
      }
    },
    UpdateEvent() {
      let self = this;
      let tempschedule = {
        eventID: self.update.eventId,
        userId: self.userid,
        eventName: self.update.eventname,
        startDate: self.update.startdate,
        endDate: self.update.enddate
      };

      axios
        .post(`${self.url}CalendarEvent/UpdateCalendarEvent`, tempschedule)
        .then(function(response) {
          if (response.data.status == 0) {
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
            self.update.eventId = null;
            self.userid = null;
            self.update.eventname = null;
            self.update.startname = null;
            self.update.endname = null;
            self.selectedOpen = false;
            window.location.reload();
            self.getEvent();
          }
        });
    },
    DeleteEvent(id) {
      let r = confirm("Are you sure delete this data?");
      if (r == true) {
        let self = this;
        axios
          .post(`${self.url}CalendarEvent/DeleteCalendarEvent?id=${id}`)
          .then(function(response) {
            if (response.data.status == 0) {
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = response.data.message;
              self.flagAlertSend = true;
              self.selectedOpen = false;
              window.location.reload();
            }
          })
          .catch(function(error) {
            alert(error);
          });
      }
    }
  }
};
</script>
<style>
.v-calendar-daily__body {
  display: none !important;
}
.card {
  border-radius: 10px !important;
  margin-bottom: 2rem;
  height: 90vh;
  overflow: auto;
  margin: 0;
  padding: 0;
}
</style>