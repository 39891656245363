<template>
  <div>
    <v-card>
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 18px;
            margin-left: 1rem;n
            margin-right: 1rem;
            color: #444444;
          "
        >Report - 6 Company History Report
          <span class="ml-5 text-caption" style="color: #126496"
              >{{ CompanyHistoryReportListCount }} Total records</span
            >
        </div>
        <v-card-text>
          <v-row class="mt-5">
            <v-col cols="12" sm="6" md="3"> 
              <label class="for--text">Company Name</label>
              <v-autocomplete
                  hide-no-data
                  return-object
                  :items="ListCompany"
                  v-model="Company"
                  :search-input.sync="InputSearchCompany"
                  item-text="companyName"
                  item-value="companyID"
                  placeholder="Company Name"
                  outlined
                  dense
                  hide-details="auto"
              ></v-autocomplete> 
              <!-- <v-col cols="12" sm="6" md="9">
                            <v-autocomplete
                                hide-no-data
                                return-object
                                :items="ListPackage"
                                v-model="Package"
                                :search-input.sync="InputSearchPackage"
                                item-text="name"
                                item-value="id"
                                placeholder="Package"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-autocomplete>
                        </v-col> -->
            </v-col>            
            <v-col cols="12" sm="6" md="2" >
              <label class="for--text">Start Date</label>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    readonly
                    v-model="StartDate"
                    @change="v => {StartDate = v;CheckValueStartDate(StartDate)}"
                    outlined
                    dense
                  >
                    <template v-slot:append>
                      <div
                        v-on="on"
                        style="background-color:rgb(25, 118, 210);width:39px;height:40px;cursor:pointer;margin-top:-8px;margin-right: -11px;border-radius:3px"
                      >
                        <img
                          src="@/assets/Mask Group 27.png"
                          width="20"
                          style="margin-left:10px;margin-top:10px"
                        />
                      </div>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  @change="CheckValueStartDate(date)"
                  @input="menu1 = false" 
                  :max="getEndDate"
                  :min="getStartDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <label class="for--text">End Date </label>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    readonly
                    v-model="EndDate"
                    @change="v => {EndDate = v;CheckValueEndDate(EndDate)}"
                    outlined
                    dense
                  >
                    <template v-slot:append>
                      <div
                        v-on="on"
                        style="background-color:rgb(25, 118, 210);width:39px;height:40px;cursor:pointer;margin-top:-8px;margin-right: -11px;border-radius:3px"
                      >
                        <img
                          src="@/assets/Mask Group 27.png"
                          width="20"
                          style="margin-left:10px;margin-top:10px"
                        />
                      </div>
                    </template>
                  </v-text-field>
                  
                </template>
                <v-date-picker
                  v-model="date1" 
                  @change="CheckValueEndDate(date1)"
                  no-title
                  @input="endDateDis(),(menu2 = false)" 
                  :max="getEndDate"
                  :min="getStartDate"
                ></v-date-picker>
              </v-menu>
            </v-col> 

            <v-col cols="12"  sm="6" md="1" lg="1">
              <v-btn width="100%" color="#1976d2 " class="white--text mt-5 applyBtn" router @click="SearchCompanyReport()">Apply</v-btn>
            </v-col>
            <v-col cols="12"  sm="6" md="1" lg="1" >
              <v-btn width="100%" color="#1976d2 " class="white--text mt-5 " router @click="ClearSearchData()">Clear</v-btn>
            </v-col>  
            
            <!-- <v-col cols="12"  sm="4" md="2"  lg="3" xl="1"> -->
            <v-col cols="12"  sm="2" md="1"  lg="1" xl="1">
            <!-- <v-col class="d-flex justify-space-between align-center flex-wrap"> -->
              <div  width="100%" class="d-flex flex-row align-center  justify-space-around" >  
                <v-checkbox class="mt-5 align-center" label="Export all" v-model="exportAll" hide-details > </v-checkbox> 
              </div>
            </v-col>
            <v-col cols="12" sm="4" md="2"  lg="2" xl="1">
                  <v-btn width="100%" 
                  color="#1976d2 "
                  class="white--text moveRight flex-sm-grow-0 flex-grow-1 mt-5"
                  router
                  @click="ExportCSV()"
                >Export CSV</v-btn>   
            </v-col>
          </v-row>

            <v-data-table
              class="mt-5"
              :headers="headers"
              :items="CompanyReportList"
              hide-default-footer
              style="color: #444444"
              :items-per-page="itemsPerPage"
              :page.sync="page"
              single-line
              hide-details
              :mobile-breakpoint="0"
            >
              <template v-slot:item="{ item, index }">
                <tr>
                  <td>{{ no_run + (index + 1) }}</td>
                  <td>{{ item.fileName }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.date }}</td>
                  <td>{{ item.typeWork }}</td>
                </tr>
              </template>
            </v-data-table>

          <br>
          <v-row>
            <v-col cols="12" xs="3" sm="3" md="1">
              <v-autocomplete
                dense
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="ChangePerPage(itemsPerPage)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="9" sm="9" md="11">
              <v-pagination
                v-model="page"
                :total-visible="7"
                :length="pageCount"
                @input="ChangePage(page)"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>
    <Loading
      :flagLoading="flagLoadingSend"
    />
    
    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />

  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import * as moment from "moment/moment";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";
export default {
  components: {
    Alert,
    Loading,
  },
  props: {},
  data() {
    return {
      prevSearch: "",
      isSearch: false,
      prevStartDate: "",
      isStartDate: false,
      prevEndDate: "",
      isEndDate: false,
      no_run: null,
      flagAlertSend: false,
      mesAlertSend: "",
      typeAlertSend: "success",
      url: enurl.apiUrl,
      CompanyReportList:[],
      CompanyHistoryReportListCount: 0,
      flagLoadingSend: false,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      items: [10, 15, 20],
      menu1: false,
      menu2: false,
      InputSearchCompany: null,
      Company: "",
      ListCompany: [],
      StartDate: null,
      EndDate: null,
      date: new Date().toISOString().substr(0, 10),
      date1:null,
      exportAll:false,
      dateFormatted: moment(new Date().toISOString().substr(0, 10))
        .format("DD/MM/YYYY"),
      dateFormatted1: moment(new Date().toISOString().substr(0, 10))
        .format("DD/MM/YYYY"), 
      nowDate: new Date().toISOString().slice(0,10),
    };
  },
  computed: {   
    getEndDate() { 
        let tempDate = new Date(this.date); 
        let tempDate2 = new Date(this.date1); 
        tempDate.setDate(tempDate.getDate()+180) 
        if( new Date() > tempDate && (new Date(this.date) < tempDate2|| tempDate2 == null )){
          return tempDate.toISOString().slice(0,10)
        } 
        else if(new Date(this.date) > tempDate2 && this.date1 != null){
          return tempDate2.toISOString().slice(0,10)
        }
        else{
          return this.nowDate
        }
      },
      getStartDate() { 
        let tempDate = new Date(this.date1);
        let tempDate2 = new Date(this.date)
        tempDate.setDate(tempDate.getDate()-180) 
        if( new Date() > tempDate && this.date1 != null){
          return tempDate.toISOString().slice(0,10)
        }
        else if(new Date().toDateString() != new Date(this.date).toDateString() && this.date1 == null){
          return tempDate2.toISOString().slice(0,10)
        }
        else{
          return tempDate.toISOString().slice(0,10)
        }
      },
    allowedMonths() {
      if (this.date1) {
        return (month) => month >= this.date1.getMonth();
      }
      return null;
    },
    allowedYears() {
      if (this.date1) {
        return (year) => year >= this.date1.getFullYear();
      }
      return null;
    },
    headers() {
      return [
      {
          text: "No",
          align: "left",
          sortable: false
        },
        {
          text: "File Name",
          value: "currentPackage",
          align: "left",
          sortable: false
        },
        {
          text: "Email (Sign)",
          value: "companyEmail",
          align: "left",
          sortable: false
        },
        {
          text: "Create Date",
          value: "sDate",
          align: "left",
          sortable: false
        },
        {
          text: "Work Type",
          value: "eDate",
          align: "left",
          sortable: false
        },
      ];
    }
  },
  watch: {
    InputSearchCompany: function (val) {
      if (val != null && val != "") {
        if (val.length >= 2) {
          let self = this;
          self.flagLoadingSend = true;
          let tempdata = {
            Search: val,
          };
          axios
            .post(`${self.url}Company/RetrieveCompanyByCompanyName`, tempdata)
            .then(function (response) {
              if (response.data.status == 0) {
                self.ListCompany = response.data.data;
              } else {
                self.ListCompany = [];
              }
              self.flagLoadingSend = false;
            })
            .catch(function (error) {
              self.flagLoadingSend = false;
              self.ListCompany = [];
              alert(error);
            });
        }
      }
    },
    search(oldVal, newVal){
      if(oldVal != newVal){
        this.isSearch = false
      }
    },
    startDate(oldVal, newVal){
      if(oldVal != newVal){
        this.isStartDate = false
      }
    },
    endDate(oldVal, newVal){
      if(oldVal != newVal){
        this.isEndDate = false
      }
    },
    date() {
      this.dateFormatted = this.DateFormat(this.date);
    },
    date1() {
      this.dateFormatted = this.DateFormat(this.date1);
    }, 
    
  },
  mounted() {
    
  },
  methods: {
    endDateDis() {
      if (this.date == "") {
        this.date = this.date1;
      }
    },
    allowedDatesEnd(val) {
      return val >= this.date;
    },
    searchenter: function(e) 
    {
      if(e.keyCode === 13)
      {
        this.SearchCompanyReport();
      }
    },
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    ClearSearchData(){
      this.search = "";
      this.StartDate = "";
      this.EndDate = "";
      this.date = new Date().toISOString().substr(0, 10) ;
      this.date1 =null ; 
      this.Company ="" ; 
      this.CompanyReportList = [];
      this.pageCount = 0; 
      this.nowDate = new Date().toISOString().slice(0,10)
      //window.location.reload();
    },
    DateFormat(value) {
      return moment(value)
        .format("DD/MM/YYYY");
    },
    

    CheckValueStartDate(value) {
      this.StartDate = this.DateFormat(value); 
    },
    CheckValueEndDate(value) {
      this.EndDate = this.DateFormat(value);
    },

    GetCustomerReport(){
      let self = this;
      if(this.search=="")
      {
      self.flagLoadingSend = true;
      }
      let tempdata = {
        Page: self.page,
        PerPage: self.itemsPerPage,
        Search: self.search,
        StartDate: self.StartDate,
        EndDate: self.EndDate,
      };
      axios
      .post(`${self.url}Report/GetCompanyHistoryReport`, tempdata)
        .then(function (response) { 
          if (response.data.status == 0) {
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.CompanyReportList = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.CompanyHistoryReportListCount = response.data.data.result.dataCount;
            self.flagLoadingSend = false; 
            self.prevStartDate = self.StartDate;
            self.prevEndDate = self.EndDate;
          } else {
            self.flagLoadingSend = false;
            self.CompanyReportList = [];
            self.pageCount = 0;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = "ไม่พบข้อมูล";
            self.flagAlertSend = true;
          }
      })
      .catch(error => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
    },

    ChangePerPage: function(value) {
      let self = this;
      self.flagLoadingSend = true;
      let tempdata = {
        companyID: self.Company.companyID == undefined ? "" :self.Company.companyID.toString(),
        Search: self.search,
        Page: 1,
        PerPage: value ,
        StartDate: self.StartDate,
        endDate: self.EndDate
      };
      axios
        .post(`${self.url}Report/GetCompanyHistoryReport`, tempdata)
        .then(function(response) {
          if (response.data.status == 0) {
            self.page = 1; 
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.CompanyReportList = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;  
              
            self.flagLoadingSend = false;
           }
          else {
            self.flagLoadingSend = false;
            self.CompanyReportList = [];
            self.pageCount = 0;
            self.page = 1;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = "ไม่พบข้อมูล";
            self.flagAlertSend = true;
          }
        })
        .catch(error => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
    },

    ChangePage: function(value) {
      let self = this;
      self.flagLoadingSend = true;
      let temp_search = self.prevSearch
      if(self.isSearch){
        temp_search = self.search
      }
      let temp_startDate = self.prevStartDate
      if(self.isStartDate){
        temp_startDate = self.StartDate
      }
      let temp_endDate = self.prevEndDate
      if(self.isEndDate){
        temp_endDate = self.EndDate
      }
      let tempdata = {
        companyID: self.Company.companyID == undefined ? "" :self.Company.companyID.toString(),
        StartDate: temp_startDate,
        EndDate: temp_endDate,
        Search: temp_search,
        Page: value,
        PerPage: self.itemsPerPage
      };
      axios
        .post(`${self.url}Report/GetCompanyHistoryReport`, tempdata)
        .then(function(response) {
          if (response.data.status == 0) {
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.CompanyReportList = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.CompanyReportList = [];
            self.pageCount = 0;
            self.page = 1;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = "ไม่พบข้อมูล";
            self.flagAlertSend = true;
          }
        })
        .catch(error => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
    },

    SearchCompanyReport: function() {
      let self = this;
      if((self.StartDate != "" && self.EndDate != "" && (self.Company != null))){
        if( self.Company.companyID != null || self.Company.companyID != undefined){
      self.flagLoadingSend = true;
      let tempdata = {
        companyID: self.Company.companyID == undefined ? "" :self.Company.companyID.toString(),
        StartDate: self.StartDate,
        endDate: self.EndDate,
        Page: 1,
        PerPage: self.itemsPerPage,
        Search: self.search
      };
      axios
        .post(`${self.url}Report/GetCompanyHistoryReport`, tempdata)
        .then(function(response) {
          if (response.data.status == 0) {
            self.page = 1;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.CompanyReportList = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.CompanyHistoryReportListCount = response.data.data.result.dataCount;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.ListData = [];
            self.pageCount = 0;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = "ไม่พบข้อมูล";
            self.flagAlertSend = true;
          }
          self.prevSearch = self.search;
          self.prevStartDate = self.StartDate;
          self.prevEndDate = self.EndDate;
          this.isSearch = true;
          this.isStartDate = true;
          this.isEndDate = true;
          

        })
        .catch(error => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
      } else if(self.Company.companyID == null || self.Company.companyID == undefined ){
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณากรอกชื่อบริษัท";
        self.flagAlertSend = true;
      }
      }
      else if( self.Company == null){
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณากรอกชื่อบริษัท";
        self.flagAlertSend = true;
      }
      else if(self.StartDate == "" && self.EndDate == "") {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณาเลือกวันที่เริ่มต้นและวันที่สิ้นสุด";
        self.flagAlertSend = true;
      }
      else if (self.StartDate == ""){
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณาเลือกวันที่เริ่มต้น";
        self.flagAlertSend = true;
      }
      else if (self.EndDate == ""){
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณาเลือกวันที่สิ้นสุด";
        self.flagAlertSend = true;
      }
    },
    ExportCSV: function() {
      let self = this; 
      let tempdata = {}
      if((self.StartDate != "" && self.EndDate != "") && (self.StartDate != null && self.EndDate != null ) || (self.Company != null && self.exportAll == false)){ 
        if(self.exportAll == false && (self.Company.companyID == null )){ 
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณากรอกชื่อบริษัท";
        self.flagAlertSend = true;
      }else{
        if(self.Company == null){
          tempdata = { 
            companyID: "",
            StartDate: self.StartDate,
            EndDate: self.EndDate, 
            ExportAll: self.exportAll
          };
        }else{
          tempdata = { 
            companyID: self.Company.companyID == undefined ? "" :self.Company.companyID.toString(),
            StartDate: self.StartDate,
            EndDate: self.EndDate, 
            ExportAll: self.exportAll
          };
        }
        self.flagLoadingSend = true;
        axios
          .post(`${self.url}Report/ExportCSVCompanyHistoryReport`, tempdata)
          .then(function(response) {
            if (response.data.status == 0) {
              var tempdata = response.data.data.result;
              const blobPDF = self.base64ToBlob(
                tempdata,
                "text/csv;charset=utf-8;"
              );
              const linkSource = URL.createObjectURL(blobPDF);
              var link = document.createElement("a");
              var date = new Date();
              link.download = "Report6_" + date.toLocaleString("th-TH") + ".csv";
              link.href = linkSource;
              link.click();

              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
            }
          })
          .catch(error => {
            console.log(error.response);
            self.flagLoadingSend = false;
          });
        }
      }    
      else if(self.Company == null ){ 
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณากรอกชื่อบริษัท";
        self.flagAlertSend = true;
      }
      else if((self.StartDate == "" && self.EndDate == "") || (self.StartDate == null && self.EndDate == null)) {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณาเลือกวันที่เริ่มต้นและวันที่สิ้นสุด";
        self.flagAlertSend = true;
      }
      else if (self.StartDate == "" && self.StartDate == null ){
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณาเลือกวันที่เริ่มต้น";
        self.flagAlertSend = true;
      }
      else if (self.EndDate == "" && self.StartDate == null){
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณาเลือกวันที่สิ้นสุด";
        self.flagAlertSend = true;
      }
    },
    base64ToBlob(base64, type = "application/octet-stream") {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    },
  }
};
</script>

<style scoped>
@media only screen and (min-width: 960px) {
  .moveRight{
    float:right;
  }
}

* >>> .clearBtn {
margin-left: 5px !important;
} 


* >>> .v-data-table-header {
  background-color: #126496 !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
* >>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
* >>> .theme--light.v-pagination .v-pagination__item--active {
  background: #126496 !important;
  color: white !important;
}
</style>

<style lang="scss">

.__pagination {
  .v-pagination__navigation {
    box-shadow: none !important;
    border: 1px solid #0000001a;
    width: auto !important;
    padding-left: 10px;
    padding-right: 10px;
    &:focus {
      outline: none;
    }
  }
}
</style>
