<template>
  <v-app>
    <NavBar v-if="$route.path != '/login' " />
    <v-content>
      <div v-if="$route.path == '/login'">
        <router-view></router-view>
      </div>
      <div style="margin:1.5rem;" v-else>
        <router-view></router-view>
      </div>
    </v-content>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar";
export default {
  name: "App",

  components: {
    NavBar,
  },
};
</script>

<style>
@font-face {
  font-family: 'English';
  src: local("English"),
  url(./assets/Font/Kanit-Regular.ttf)
}
@font-face {
  font-family: "DB Helvethaica X Reg";
  src: url("//db.onlinewebfonts.com/t/9e29a765855a9000ab8e4aa2883d5384.eot");
  src: url("//db.onlinewebfonts.com/t/9e29a765855a9000ab8e4aa2883d5384.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/9e29a765855a9000ab8e4aa2883d5384.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/9e29a765855a9000ab8e4aa2883d5384.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/9e29a765855a9000ab8e4aa2883d5384.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/9e29a765855a9000ab8e4aa2883d5384.svg#DB Helvethaica X Reg")
      format("svg");
}
.v-content__wrap {
  background-color: white;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 959px) {
.v-content__wrap {
  background-color: white;
  width: 80%;
}
}
*{
    font-family: 'English';
  }
  
#app {
  /* font-family: "DB Helvethaica X Reg"; */
  color:#444444;
}
</style>
