<template>
  <div>
    <v-container fluid>
      <v-card class="card">
        <v-toolbar color="primary" dark>
          <v-toolbar-title class="title">{{$t('usergroup')}}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-title class="title">{{today}}</v-toolbar-title>
        </v-toolbar>
        <v-flex class="flex">
          <v-row style="margin-top:2rem;">
            <v-col cols="3">
              <!-- <v-text-field v-model="groupname" :label="$t('groupname')"></v-text-field> -->
                <v-autocomplete
                v-model="groupname"
                :items="GroupListDDL"
                dense
                :label="$t('groupname')"
                item-text="groupName"
                item-value="groupName"
                clearable
              ></v-autocomplete>
            </v-col>

            <v-col cols="3">
              <v-btn class="primary white--text mt-3" @click="Search">{{$t('search')}}</v-btn>
            </v-col>
            <v-col cols="3"></v-col>
            <v-col cols="3" justify="end" align="end">
              <v-btn
                class="primary white--text mt-3"
                @click="CreateGroup"
                :disabled="permission.add"
              >{{$t('add')}}</v-btn>
            </v-col>
          </v-row>
          <v-data-table :items="filteredGroups">
            <template v-slot:header="{ props: { headers } }">
              <thead>
                <tr>
                  <th style="width:35rem;">
                    <v-select
                      :label="$t('groupname')"
                      :items="GroupList"
                      v-model="filters.groupName"
                      multiple
                      clearable
                      item-text="groupName"
                      item-value="groupName"
                    ></v-select>
                  </th>

                  <th>{{$t('adduseringroup')}}</th>
                  <th>{{$t('delete')}}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.groupName }}</td>
                <td>
                  <v-btn
                    icon
                    @click="EditGroup(item)"
                    :disabled="permission.edit"
                  >
                    <img :src="images.edit" />
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    icon
                    @click="DeleteGroup(item.id)"
                    :disabled="permission.delete"
                  >
                    <img :src="images.delete" />
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>

        <br />
        <br />
        <br />
      </v-card>
    </v-container>
    <!-- AddUserDialog -->
    <v-dialog v-model="Adddialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{$t('usermanagement')}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6" >
                <v-text-field v-model="add.groupname" :label="$t('groupname')" class="groupname" ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  v-model="userList"
                  :items="UserList"
                  chips
                  :label="$t('username')"
                  multiple
                  item-text="employeeName"
                  item-value="userID"
                  :readonly="!isEditing"
                  persistent-hint
                >
                  <template v-slot:append-outer>
                    <v-slide-x-reverse-transition mode="out-in"></v-slide-x-reverse-transition>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="Adddialog = false">{{$t('close')}}</v-btn>
          <v-btn color="blue darken-1" text @click="AddGroup">{{$t('add')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EditUserDialog -->
    <v-dialog v-model="Editdialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{$t('usergroup')}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-2">
                <v-text-field v-model="gpname" :label="$t('groupname')" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  v-model="userList"
                  :items="UserList"
                  chips
                  :label="$t('username')"
                  multiple
                  item-text="employeeName"
                  item-value="userID"
                  :readonly="!isEditing"
                  persistent-hint
                >
                  <template v-slot:append-outer>
                    <v-slide-x-reverse-transition mode="out-in"></v-slide-x-reverse-transition>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="Editdialog = false">{{$t('close')}}</v-btn>
          <v-btn color="blue darken-1" text @click="UpdateGroup">{{$t('update')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />

    <Loading
      :flagLoading="flagLoadingSend"
    />
    
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/api/function.js";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components: {
    Alert,
    Loading,
  },
  name: "UserGroup",
  mixins: [LogTrace],
  data: () => ({
      flagAlertSend: false,
      mesAlertSend: "",
      typeAlertSend: "success",
      permission: {
        add: true,
        edit: true,
        delete: true
      },
    filters: {
      groupName: []
    },
    flagLoadingSend: false,
    add: { id: null, value: null, groupname: null },
    isEditing: true,
    gpname: null,
    groupname: "",
    groupID: null,
    url: enurl.apiUrl,
    Adddialog: false,
    Editdialog: false,
    isUpdating: false,
    today: null,
    items: [],
    values: [],
    value: null,
    UserList: [],
    userList: [],
    GroupList: [],
    GroupListDDL:[],
    header: [
      {
        text: "Group Name",
        value: "name"
      },
      { text: "Add User in Group", value: "user" },
      { text: "Delete", value: "edit" }
    ],
    users: [],
    images: {
      delete: require("@/assets/delete1.png"),
      edit: require("@/assets/edit1.png")
    }
  }),
  computed: {
    filteredGroups() {
      return this.GroupList.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    }
  },
  methods: {
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    Search() {
      let self = this;
      self.flagLoadingSend = true;
      if (self.groupname != "" &&  self.groupname != undefined) {
        let List = self.GroupList;
        self.GroupList = [];
        for (let i = 0; i < List.length; i++) {
          if (List[i].groupName == self.groupname) {
            self.GroupList.push(List[i]);
          }
        }
        self.flagLoadingSend = false;
      } else {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "Please enter Group Name!!";
        self.flagAlertSend = true;
        self.GetGroup();
        self.flagLoadingSend = false;
      }
    },
    GetDate() {
      let today1 = new Date();
      let dd = today1.getDate();
      let mm = today1.getMonth() + 1; //January is 0!

      let yyyy = today1.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      this.today = dd + "/" + mm + "/" + yyyy;
    },
    GetGroup() {
      try{
      let self = this;
      self.flagLoadingSend = true;
      axios
        .post(`${self.url}Group/GetAllGroups`)
        .then(function(response) {
          self.GroupList = response.data.data;
          console.log(self.GroupList);
          self.GroupListDDL=response.data.data;
          self.flagLoadingSend = false;
        })
        .catch(function(error) {
          alert(error);
        });
      }catch(error){
        this.LogTrace(error,"GetGroup")
      }
    },
    GetUser() {
      let self = this;
      axios
        .get(`${self.url}EmployeeCom/GetAllEmployeeCompanies`)
        .then(function(response) {
          self.UserList = response.data.data;
        })
        .catch(function(error) {
          alert(error);
        });
    },
    EditGroup(temp) {
      try{
      var self = this;
      self.Editdialog = true;
      self.gpname = temp.groupName;
      self.groupID = temp.id;
      self.userList = [];

      var temps = temp.userID.split(",");
      for (var i = 0; i < temps.length; i++) {
        var list = self.UserList.filter(x => x.userID == temps[i]);
        self.userList.push({
          employeeID: list[0].employeeID,
          salaryID: list[0].salaryID,
          userID: list[0].userID,
          memberID: list[0].memberID,
          username: list[0].username,
          firstName: list[0].firstName,
          lastName: list[0].lastName,
          employeeName: list[0].employeeName
        });
      }
      }catch(error){
        this.LogTrace(error,"EditGroup")
      }
    },
    UpdateGroup() {
      try{
      var self = this;
      self.flagLoadingSend = true;
      var tempuserList = self.userList + "";
      var tempUser = {
        id: self.groupID,
        groupName: self.gpname,
        userID: tempuserList == "" ? null : tempuserList,
        createBy: null,
        updateBy: null
      };
      self.Editdialog = false;
      axios
        .post(`${self.url}Group/UpdateGroup`, tempUser)
        .then(function(response) {
          if (response.data.status == 0) {
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
            self.gpname = null;
            self.userList = [];
            self.GetGroup();
            self.flagLoadingSend = true;
          }
        })
        .catch(function(error) {
          alert(error);
          self.Editdialog = false;
        });
      }catch(error){
        this.LogTrace(error,"UpdateGroup")
      }
    },
    DeleteGroup(id) {
      try{
      var deleteconfirm = confirm("Are you sure to delete this data?");
      if (deleteconfirm == true) {
        let self = this;
        self.flagLoadingSend = true;
        axios
          .post(`${self.url}Group/DeleteGroup?id=${id}`)
          .then(function(response) {
            if (response.data.status == 0) {
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = response.data.message;
              self.flagAlertSend = true;
              self.GetGroup();
              self.flagLoadingSend = false;
            }
          })
          .catch(function(error) {
            alert(error);
          });
      } else {
        self.flagLoadingSend = false;
      }
      }catch(error){
        this.LogTrace(error,"DeleteGroup")
      }
    },
    CreateGroup() {
      var self = this;
      self.Adddialog = true;
      self.add.groupname = null;
      self.userList = [];
    },
    AddGroup() {
      try{
      var self = this;
      self.flagLoadingSend = true;
      if (self.add.groupname != null && self.userList.length != 0) {
        var temp = self.userList + "";
        var tempUser = {
          groupName: self.add.groupname,
          userID: temp,
          createBy: null,
          updateBy: null
        };

        axios
          .post(`${self.url}Group/AddGroup`, tempUser)
          .then(function(response) {
            if (response.data.status == 0) {
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = response.data.message;
              self.flagAlertSend = true;
              self.add.groupname = null;
              self.userList = [];
              self.GetGroup();
              self.Adddialog = false;
              self.flagLoadingSend = false;
            } else {
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = "Group Name already have";
              self.flagAlertSend = true;
            }
          })
          .catch(function(error) {
            alert(error);
          });
      } else {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "Please fill all fields!!";
        self.flagAlertSend = true;
      }
      }catch(error){
        this.LogTrace(error,"AddGroup")
      }
    }
  },
  mounted() {
    this.permission = this.checkPermissionFour(5, 6, 7, 8);
    this.GetDate();
    this.GetGroup();
    this.GetUser();
  }
};
</script>
<style scoped>
.groupname{
  margin-top: 0.85rem;
}
.card {
  border-radius: 10px !important;
  margin-bottom: 2rem;
  height: 90vh;
  overflow: auto;
  margin: 0;
  padding: 0;
}
.flex {
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>
<style>
.theme--light.v-data-table thead tr th {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
}
</style>