<template>
  <div>
    <v-card-text class="card-style">
      <h3 class="text--black">Report</h3>
      <br />
      <v-expansion-panels class="card--margin" v-model="panel" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header style>
            <template v-slot:actions>
              <v-icon
                size="30"
                style="background-color:#1F3781;border-radius:50%;opacity: 1"
                color="white"
              >mdi-chevron-down</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-btn
              color="#3E8EF7"
              class="text-decoration-underline r-btn"
              text
              @click="$router.push({path: '/report1'})"
            >1. Report 1: User Activity Report</v-btn>
          </v-expansion-panel-content>

          <v-expansion-panel-content>
            <v-btn
              color="#3E8EF7"
              class="text-decoration-underline r-btn"
              text
              @click="$router.push({path: '/report2'})"
            >2. Report 2: Volumn Sign Per User (Day/ Month)</v-btn>
          </v-expansion-panel-content>

          <v-expansion-panel-content>
            <v-btn
              color="#3E8EF7"
              class="text-decoration-underline r-btn"
              text
              @click="$router.push({path: '/report3'})"
            >3. Report 3: User use Cert/Group Cert</v-btn>
          </v-expansion-panel-content>

          <v-expansion-panel-content>
            <v-btn
              color="#3E8EF7"
              class="text-decoration-underline r-btn"
              text
              @click="$router.push({path: '/report4'})"
            >4. Report 4: Cert expired (on behalf)</v-btn>
          </v-expansion-panel-content>
          <v-expansion-panel-content>
            <v-btn
              color="#3E8EF7"
              class="text-decoration-underline r-btn"
              text
              @click="$router.push({path: '/report5'})"
            >5. Report 5: Customer Report</v-btn>
          </v-expansion-panel-content>
          <v-expansion-panel-content>
            <v-btn
              color="#3E8EF7"
              class="text-decoration-underline r-btn"
              text
              @click="$router.push({path: '/report6'})"
            >6. Report 6: Company History Report</v-btn>
          </v-expansion-panel-content>
          <v-expansion-panel-content>
            <v-btn
              color="#3E8EF7"
              class="text-decoration-underline r-btn"
              text
              @click="$router.push({path: '/report7'})"
            >7. Report 7: Login Activities Report</v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </div>
</template>
<script>

export default {
  props: {},
  data() {
    return {
      panel: [0]
    };
  },
};
</script>
<style scoped>
</style>
