<template>
  <div>
    <v-card style="box-shadow: 1px 1px 1px -1px rgb(0 0 0 / 3%), 0px 1px 1px 1px rgb(0 0 0 / 3%), 0px 1px 1px 0px rgb(0 0 0 / 3%)">
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="font-size: 18px; margin-left: 1rem; margin-right: 1rem; color: #444444;"
        >
        Template Contract
        </div>
        <v-card-text>
          <v-row class="mt-5">
            <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
              <div class="d-flex">
                <v-btn
                    style="
                        background-color: #126496;
                        color: #ffffff;
                        text-transform: none !important;
                      "
                    @click="GotoAddTemplateContact()"
                    class="flex-sm-grow-0 flex-grow-1"
                  >+ Add Template</v-btn>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
              <v-text-field
                :class="[$vuetify.breakpoint.xlOnly ? 'ml-5' : '']"
                label="Search"
                v-model="search"
                outlined
                dense
                hide-details="auto"
                v-on:keyup.enter="GetTemplateContactList()"
              >
              <template v-slot:prepend-inner>
                <v-icon color="primary">mdi-magnify</v-icon>
              </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            class="mt-5"
            :headers="headers"
            :items="TemplateContactList"
            hide-default-footer
            style="color: #444444;box-shadow: 1px 1px 1px -1px rgb(0 0 0 / 7%), 0px 1px 1px 1px rgb(0 0 0 / 7%), 0px 1px 1px 0px rgb(0 0 0 / 7%)"
            :items-per-page="itemsPerPage"
            :page.sync="page"
            single-line
            hide-details
          >
            <template v-slot:item="{ item, index }">
                <tr>
                    <td>
                        <center><span>{{ item.no + (index + 1) }}</span></center>
                    </td>
                    <td>
                        <center><span>{{ item.templateContactName }}</span></center>
                    </td>
                    <td>
                        <center><span>{{ item.fileName }}</span></center>
                    </td>
                    <td>
                        <center><span>{{ item.bookmarkCount }}</span></center>
                    </td>
                    <td>
                        <center>
                            <v-menu transition="slide-y-transition" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    color="#126496"
                                    style="text-transform: none !important"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                                </template>
                                <v-list>
                                <v-list-item
                                    v-for="(temp, i) in itemsAction"
                                    :key="i"
                                    @click="SelectAction(temp.title, item)"
                                >
                                    <v-list-item-title>{{ temp.title }}</v-list-item-title>
                                </v-list-item>
                                </v-list>
                            </v-menu>
                        </center>
                    </td>
                </tr>
            </template>

          </v-data-table>
          <v-row class="mt-5">
            <v-col cols="12" xs="12" sm="12" md="4">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  style="border: 1px solid #EBEBF2"
                    v-bind="attrs"
                    v-on="on"
                    class="text-capitalize mt-1"
                    outlined
                  >
                    {{ itemsPerPage }}
                    <v-icon color="primary">mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in paginationCount"
                    :key="index"
                    @click="
                      itemsPerPage = item.title;
                      ChangePerPageTemplateContact(itemsPerPage);
                    "
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4">
              <v-pagination
                
                v-model="page"
                :total-visible="6"
                :length="pageCount"
                @input="ChangePageTemplateContact(page)"
              ></v-pagination>
              
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="DeleteDialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span>
            
            <!-- <v-img
              style="width: 32px; height: 32px; margin-bottom: -30px"
              src="@/assets/Group7605.png"
            ></v-img> -->
          </span>
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 20px">
           <span><v-icon color="red" large>mdi-alert-circle-outline</v-icon></span> Template Contract
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  Are you sure you want to Delete ?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            @click="DeleteDialog = false"
            text
            >Cancel</v-btn
          >
          <v-btn
            color="#FF2727"
            class="mr-2 white--text text-capitalize"
            width="120"
            @click="DeleteTemplateContact()"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />

    <Loading
      :flagLoading="flagLoadingSend"
    />
    
  </div>
</template>

<script>
import axios from "axios";
// import store from "../store";
import enurl from "@/api/environment";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components: {
    Alert,
    Loading,
  },
  data: () => ({
    flagAlertSend: false,
    mesAlertSend: "",
    typeAlertSend: "success",
    flagDel: true,
    tempTemplateContactID: null,
    url: enurl.apiUrl,
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    items: [10, 15, 20],
    TemplateContactList: [],
    flagLoadingSend: false,
    no_run: null,
    DeleteDialog: false,
    CompanyID: 0,

    itemsAction: [
      { title: "Edit Template Contract" },
      { title: "Delete Template Contract" },
    ],
    paginationCount: [
      { title: 10 },
      { title: 20 },
      { title: 30 },
      { title: 40 },
    ],
  }),
  computed: {
    headers() {
      return [
        {
          text: "#",
          sortable: false,
          align: "center",
          class: "darkprimary white--text rounded-tl-lg",
        },
        {
          text: "Template Contract Name",
          sortable: false,
          align: "center",
          class: "darkprimary white--text ",
        },
        {
          text: "File Name",
          sortable: false,
          align: "center",
          class: "darkprimary white--text ",
        },
        {
          text: "Bookmark Count",
          sortable: false,
          align: "center",
          class: "darkprimary white--text",
        },
        {
          text: "More",
          sortable: false,
          align: "center",
          class: "darkprimary white--text rounded-tr-lg",
        },
      ];
    },
  },
  mounted() {
    let self = this;
    self.GetTemplateContactList();
    const elements = document.querySelectorAll(".v-pagination__navigation");
    
    for (const [index, element] of elements.entries()) {
      if (index % 2 != 0) {
        element.textContent = "Next";
      } else {
        element.textContent = "Previous";
      }
    }
  },
  methods: {
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = ""; //success warning error
      self.mesAlertSend = "";
    },

    SelectAction(value, item) {
      let self = this;
      if (value == "Edit Template Contract") {
        self.flagLoadingSend = true;
        var TemplateContactID = item.templateContactID;
        // localStorage.setItem("CompanyName", CompanyName);
        // setTimeout(() => {
            self.$router.push({
            name: "AddTemplateContract",
            params: { TemplateContactID },
          });
        // });
        self.flagLoadingSend = false;
      }
      if (value == "Delete Template Contract") {
        self.DeleteDialog = true;
        self.tempTemplateContactID = item.templateContactID;
      }
    },

    GotoAddTemplateContact(){
      let self = this;
      self.$router.push("/AddTemplateContract");
    },

    async GetTemplateContactList() {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        page: self.page,
        perPage: self.itemsPerPage,
        Search: self.search == "" ? null : self.search
      };
      axios
        .post(`${self.url}TemplateContact/GetTemplateContactList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            let listData = response.data.data;
            console.log(listData);
            self.TemplateContactList = listData.templateContact;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.pageCount = 0;
            self.TemplateContactList = [];
          }
        })
        .catch(function (error) {
          self.flagLoadingSend = false;
          alert(error);
        });
    },

    ChangePageTemplateContact(value) {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        Page: value,
        Perpage: self.itemsPerPage,
        Search: self.search == "" ? null : self.search,
      };
      axios
        .post(`${self.url}TemplateContact/GetTemplateContactList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            let listData = response.data.data;
            self.TemplateContactList = listData.templateContact;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.TemplateContactList = [];
            self.pageCount = 0;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = "ไม่พบข้อมูล";
            self.flagAlertSend = true;
          }
        })
        .catch(function (error) {
          self.flagLoadingSend = false;
          alert(error);
        });
    },

    ChangePerPageTemplateContact(value) {
      let self = this;
      self.flagLoadingSend = true;
      let tempdata = {
        Page: 1,
        Perpage: value,
        Search: self.search == "" ? null : self.search,
      };
      axios
        .post(`${self.url}TemplateContact/GetTemplateContactList`, tempdata)
        .then(function (response) {
          if (response.data.status == 0) {
            let listData = response.data.data;
            self.TemplateContactList = listData.templateContact;
            self.pageCount = response.data.data.count;
            self.page = 1;
            self.no_run = (self.page - 1) * self.itemsPerPage;

            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.TemplateContactList = [];
            self.pageCount = 0;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = "ไม่พบข้อมูล";
            self.flagAlertSend = true;
          }
        })
        .catch(function (error) {
          self.flagLoadingSend = false;
          alert(error);
        });
    },
    DeleteTemplateContact() {
      let self = this;
      self.flagLoadingSend = true;
      let tempdata = {
        id: self.tempTemplateContactID
      };
      axios
        .post(`${self.url}TemplateContact/DeleteTemplateContact`, tempdata)
        .then(function (response) {
          if (response.data.status == 0) {
            self.flagLoadingSend = false;
            self.DeleteDialog = false;
            window.location.reload();
          }
          else{
            self.DeleteDialog = false;
            self.flagLoadingSend = false;
          }
        })
        .catch(function (error) {
          self.flagLoadingSend = false;
          alert(error);
        });
    },
  },

  watch: {
  },
};
</script>
<style scoped>
@media only screen and (min-width: 1030px) and (max-width: 1409px) {
  .per_page {
    margin-left: -5rem !important;
  }
  .v-select > .v-input__control > .v-input__slot {
    width: 80px !important;
  }
}
tbody tr:nth-of-type(even) {
  background-color: #1264960d;
}
.v-text-field--outlined fieldset {
  color: #ebebf2 !important;
}
.v-data-table-header {
  background-color: #126496 !important;
  color: #ffffff !important;
}
.v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-pagination .v-pagination__item--active {
  background: #126496 !important;
  color: white !important;
}
*>>>.v-pagination__item{
  margin: unset !important;
  border-radius: unset !important;
  box-shadow: unset !important;
  border: 1px solid rgba(0, 0, 0, 0.1)
}
*>>>.v-pagination__navigation{
  margin: unset !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 80px !important;
  height: 34px !important;
  border-radius: unset !important;
  box-shadow: 0px 0px 6px #0000000A !important;
}

.v-text-field--outlined >>> fieldset {
  border: 1px solid #EBEBF2
}

.downArrowAndUpArrow >>>.v-icon.v-icon{
  color: #126496;
}
</style>
<style lang="scss">
.__pagination {
  .v-pagination__navigation {
    box-shadow: none !important;
    border: 1px solid #0000001a;
    padding-left: 10px;
    padding-right: 10px;
    &:focus {
      outline: none;
    }
  }
}
</style>