<template>
  <div>
    <v-card>
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 18px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >History log</div>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                dense
                v-model="search"
                outlined
                placeholder="Search"
                placeholder-color="#ACACAC"
                prepend-inner-icon="mdi-magnify"
                color="#126496"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4"></v-col>
            <v-col cols="12" sm="6" md="1">
              <v-autocomplete
                class="per_page"
                dense
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-pagination class="__pagination" v-model="page" :total-visible="1" :length="pageCount"></v-pagination>
            </v-col>
          </v-row>
          <div>
            <v-data-table
              :headers="headers"
              :items="HistoryLogList"
              hide-default-footer
              style="color: #444444"
              :page.sync="page3"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
              :search="search"
              single-line
              hide-details
            >
              <template v-slot:item.download="{ item }" class="text-end">
                <v-btn
                  text
                  color="#126496"
                  style="text-transform: none !important"
                  @click="downloadURI(item.certFile, item.certImageName)"
                >Download</v-btn>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <Loading
      :flagLoading="flagLoadingSend"
    />
    
    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />

  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import * as moment from "moment/moment";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components: {
    Alert,
    Loading,
  },
  data: () => ({
    flagAlertSend: false,
    mesAlertSend: "",
    typeAlertSend: "success",
    url: enurl.apiUrl,
    search: "",
    page: 1,
    pageCount: 2,
    itemsPerPage: 10,
    items: [10, 15, 20],
    HistoryLogList: [],
    time: "",
    flagLoadingSend: false,
    headers: [
      {
        text: "Date",
        align: "start",
        sortable: false,
        value: "createDate",
      },
      { text: "Time", sortable: false, value: "time" },
      { text: "File Name", sortable: false, value: "certName" },
      { text: "Cer Reason", sortable: false, value: "certReason" },
      {
        text: "Car Location",
        sortable: false,
        value: "certLocation",
      },
      {
        text: "Upload By",
        sortable: false,
        value: "updateBy",
      },
      { text: "Download", sortable: false, value: "download" },
    ],
  }),
  methods: {
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    downloadURI(uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // delete link;
    },

    getCertList: function () {
      let self = this;
      let file = null;
      self.flagLoadingSend = true;
      let strDate = null;
      axios.get(`${self.url}Cert/GetCertHistory`).then(function (response) {
        if (response.data.status == 0) {
          for (let v of response.data.data) {
            if (v.certImage != null) {
              file = "data:apllication/pfx;base64," + v.certImage;
            }
            if (v.createDate != null) {
              strDate = self.UTCToLocal(v.createDate, "DD/MM/YYYY hh:mm");
              v.createDate = strDate.split(" ");
            }
            self.HistoryLogList = response.data.data.map((v) => ({
              certName: v.certName,
              certBackground: v.certBackground,
              certID: v.certID,
              certImage: v.certImage,
              certFile: file,
              certImageName: v.certImageName,
              certReason: v.certReason,
              certLocation: v.certLocation,
              certPasswordCheck: v.certPasswordCheck,
              certPassword: v.certPassword,
              createBy: v.createBy,
              createDate: v.createDate[0],
              updateBy: v.updateBy,
              updateDate: v.updateDate,
              activeFlag: v.activeFlag,
              time: v.createDate[1],
            }));
          }
          self.flagLoadingSend = false;
        } else {
          self.flagLoadingSend = false;
          self.typeAlertSend = "warning"; //success warning error
          self.mesAlertSend = response.data.message;
          self.flagAlertSend = true;
        }
      });
    },
    UTCToLocal(dateStr, format) {
      if (typeof dateStr == "string") {
        let newDateStr = dateStr;
        if (!newDateStr.match(/Z$/)) {
          newDateStr += "Z";
        }
        return moment(newDateStr).format(format);
      }
      return "";
    },
  },
  mounted() {
    this.getCertList();
    const elements = document.querySelectorAll(".v-pagination__navigation");
    // console.log(elements);
    for (const [index, element] of elements.entries()) {
      if (index % 2 != 0) {
        element.textContent = "Next";
      } else {
        element.textContent = "Previous";
      }
    }
  },
};
</script>
<style scoped>
@media only screen and (min-width: 1030px) and (max-width: 1409px) {
  .per_page {
    margin-left: -5rem !important;
  }
*>>> .v-select > .v-input__control > .v-input__slot {
  width: 80px !important;
}
}
*>>> tbody tr:nth-of-type(even) {
  background-color: #1264960d;
}
*>>> .v-text-field--outlined fieldset {
  color: #ebebf2 !important;
}
*>>> .v-data-table-header {
  background-color: #126496 !important;
  color: #ffffff !important;
}
*>>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
*>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
*>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #126496 !important;
  color: white !important;
}
</style>
<style lang="scss">
.__pagination {
  .v-pagination__navigation {
    box-shadow: none !important;
    border: 1px solid #0000001a;
    width: auto !important;
    padding-left: 10px;
    padding-right: 10px;
    &:focus {
      outline: none;
    }
  }
}
</style>