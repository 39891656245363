import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
    
    th: {
        date:"วันที่",
        description:"ลักษณะ",
        usermanagement: "การจัดการผู้ใช้",
        username: "ชื่อผู้ใช้",
        email: "อีเมล",
        search: "ค้นหา",
        add: "เพิ่ม",
        phonenumber: "หมายเลขโทรศัพท์",
        employeename: "ชื่อพนักงาน",
        usertype: "ประเภทผู้ใช้e",
        edit: "แก้ไข",
        delete: "ลบ",
        users: "ผู้ใช้",
        usergroup: "กลุ่มผู้ใช้",
        permissionmanagement: "การจัดการสิทธิ์",
        menumanagement: "การจัดการเมนู",
        calendar: "ปฏิทิน",
        log: "เข้าสู่ระบบ",
        setting: "การตั้งค่า",
        groupname: "ชื่อกลุ่ม",
        permissionname: "ชื่อสิทธิ์",
        save: "บันทึก",
        close: "ปิด",
        update: "ปรับปรุง",
        firstname: "ชื่อจริง",
        lastname: "นามสกุล",
        indicatesrequiredfield: "แสดงถึงฟิลด์ที่จำเป็น",
        userprofile: "ประวัติผู้ใช้",
        logout: "ประวัติผู้ใช้",
        password: "รหัสผ่าน",
        rememberme: "จดจำฉัน",
        forgotpassword: "ลืมรหัสผ่าน",
        login: "เข้าสู่ระบบ",
        successMessage: "เข้าสู่ระบบเรียบร้อยแล้ว",
        adduseringroup:"เพิ่มผู้ใช้ในกลุ่ม",
        menuname:"ชื่อเมนู",
        number:"จำนวน",
        addgroupinmenu:"เพิ่มกลุ่มในเมนู"



    },
    en: {
        date:"Date",
        description:"Description",
        usermanagement: "User Management",
        username: "User Name",
        email: "Email",
        search: "Search",
        add: "Add",
        phonenumber: "Phone Number",
        employeename: "Employee Name",
        usertype: "User Type",
        edit: "Edit",
        delete: "Delete",
        users: "Users",
        usergroup: "User Group",
        permissionmanagement: "Permission Management",
        menumanagement: "Menu Management",
        calendar: "Calendar",
        log: "Log",
        setting: "Setting",
        groupname: "Group Name",
        adduseringroup: "Add user in group",
        permissionname: "Permission Name",
        save: "Save",
        close: "Close",
        update: "Update",
        firstname: "First Name",
        lastname: "Last Name",
        indicatesrequiredfield: "Indicates required field",
        userprofile: "User Profile",
        logout: "Log Out",
        password: "Password",
        rememberme: "Remember Me",
        forgotpassword: "Forgot Password",
        login: "Login",
        successMessage: "Logged In Successfully",
        menuname:"Menu Name",
        number:"No",
        addgroupinmenu:"Add group in menu"
    }
}
const i18n = new VueI18n({
    locale: "en", // set locale
    fallbackLocale: "th", // set fallback locale
    messages // set locale messages
});

Vue.filter('myLocale', function (value) {
    return i18n.t(value)
})

export default i18n;