<template>
  <div>
    <v-card class="card-preview">
      <div
        class="pt-8"
        style="
          text-color: #444444;
          font-size: 18px;
          margin-left: 1rem;
          margin-right: 1rem;
          color: #444444;
        "
      >Company Management
    
      <span class="ml-5 text-caption" style="color: #126496"
            >{{ CompanyManagementListCount }} Total records</span
          >
      </div>
      <v-row class="pt-5" style="margin-left: 25px; margin-right: 10px">
        <v-spacer></v-spacer>
        <!-- <v-btn
          width="180px"
          color="#1976d2 "
          class="white--text mt-2"
          router
          @click="addCompany"
          characters="a-z,A-Z,0-9,#"
        >+ New Company</v-btn> -->
        <!-- <v-btn
          width="180px"
          color="#1976d2 "
          class="white--text mt-2"
          router
          @click="GotoAddCompany()"
          characters="a-z,A-Z,0-9,#"
        >+ New Company</v-btn> -->
      </v-row>
      <div class="pl-5">
        <v-tabs class="mt-2" grey--text slider-color="#1976d2">
          <v-tab class="text-capitalize tabone" @click="orginalOne(!statusCompany)">Running</v-tab>
          <v-tab class="text-capitalize tabone" @click="orginalOne(!statusCompany)">Removed</v-tab>
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab-item>
            <v-row class="pt-5" style="margin-left: 11px; margin-right: 11px">
              <div class="ml-2 mt-3">
                <v-row>
                  <v-col>
                      <v-btn
                      width="180px"
                      color="#1976d2 "
                      class="white--text"
                      router
                      @click="GotoAddCompany()"
                      characters="a-z,A-Z,0-9,#"
                    >+ New Company</v-btn>
                  </v-col>
                  <v-col >
                    <v-text-field
                      autocomplete="off"
                      v-model="search"
                      v-on:keyup.enter="GetCompanyListSearch(search)"
                      solo
                      dense
                      style="
                        margin-top: -0.1rem;
                        margin-left: 1rem;
                        color: black;
                        font-size:14px;
                        width: 400px
                      "
                      prepend-inner-icon="mdi-magnify"
                      placeholder="search company code, company name, email and telephone no"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-row>
            <v-data-table
              style="margin-left: 10px; margin-right: 40px"
              class="mytable"
              :headers="headers"
              :items="CompanyList"
              :page.sync="page"
              hide-default-header
              :items-per-page="itemsPerPage"
              hide-default-footer
            >
              <template v-slot:header>
                <thead>
                  <tr>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFun('companyCode')"
                    >
                      {{ headers[0].text }}
                      <!-- <v-icon small v-if="!companyCode">mdi-arrow-down</v-icon>
                      <v-icon small v-if="companyCode">mdi-arrow-up</v-icon>-->
                    </th>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFun('companyName')"
                    >
                      {{ headers[1].text }}
                      <!-- <v-icon small v-if="!companyName">mdi-arrow-down</v-icon>
                      <v-icon small v-if="companyName">mdi-arrow-up</v-icon>-->
                    </th>
                    <th
                      @click="sortFun('email')"
                      class="b-text"
                      style="cursor: pointer; min-width: 180px !important"
                    >
                      {{ headers[2].text }}
                      <!-- <v-icon small v-if="!email">mdi-arrow-down</v-icon>
                      <v-icon small v-if="email">mdi-arrow-up</v-icon>-->
                    </th>

                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFun('tele_Number')"
                    >
                      {{ headers[3].text }}
                      <!-- <v-icon small v-if="!tele_Number">mdi-arrow-down</v-icon>
                      <v-icon small v-if="tele_Number">mdi-arrow-up</v-icon>-->
                    </th>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFun('status')"
                    >
                      {{ headers[4].text }}
                      <!-- <v-icon small v-if="!status">mdi-arrow-down</v-icon>
                      <v-icon small v-if="status">mdi-arrow-up</v-icon>-->
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.companyCode }}</td>
                  <td>{{ item.companyName }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.telephoneNo }}</td>
                  <td class="pl-1">
                    <v-btn
                      rounded
                      width="100"
                      text
                      :style="{
                        color: item.status == true ? '#22A534' : ' #FF6565',
                        background: item.status == true ? '#C6E8CB' : '#FFD8D8',
                      }"
                    >{{ item.status == true ? "Active" : "Inactive" }}</v-btn>
                  </td>
                  <td class="pl-1">
                    <v-btn icon @click="goNewCompany(item)">
                      <img :src="images.edit" />
                    </v-btn>
                    <v-btn icon @click="DeleteDialog(item.companyID)">
                      <img :src="images.delete" />
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-spacer></v-spacer>

            <div class="text-right">
              <v-row justify="end" class="ml-5 mt-5 mr-5">
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                    color="#FFCD2C"
                    :total-visible="6"
                    @input="GetCompanyListChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="2" class="mt-1">
                  <v-select
                    dense
                    style="width: 120px; float: right"
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    :items="items"
                    @input="GetCompanyListChangePerPage(itemsPerPage)"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
          <v-tab-item>
            <v-row class="pt-5" style="margin-left: 11px; margin-right: 11px">
              <div style="width: 450px" class="ml-2 mt-3">
                <v-row>
                  <v-col>
                    <v-text-field
                      autocomplete="off"
                      v-model="deletesearch"
                      v-on:keyup.enter="GetCompanyListSearch(deletesearch)"
                      solo
                      dense
                      style="
                        margin-top: -0.8rem;
                        margin-left: -1rem;
                        color: black;
                        font-size:14px;
                      "
                      prepend-inner-icon="mdi-magnify"
                      placeholder="search company code, company name, email and telephone no"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-row>
            <v-data-table
              style="margin-left: 10px; margin-right: 40px"
              class="mytable"
              :headers="removeheaders"
              :items="Deletecompanylist"
              :search="deletesearch"
              :page.sync="page1"
              hide-default-header
              :items-per-page="itemsPerPage1"
              hide-default-footer
            >
              <template v-slot:header="{ props: {} }">
                <thead>
                  <tr>
                    <th @click="sortdelete('companyCode')">
                      {{ removeheaders[0].text }}
                      <!-- <v-icon small v-if="!companyCode">mdi-arrow-down</v-icon>
                      <v-icon small v-if="companyCode">mdi-arrow-up</v-icon>-->
                    </th>
                    <th @click="sortdelete('companyName')">
                      {{ removeheaders[1].text }}
                      <!-- <v-icon small v-if="!companyName">mdi-arrow-down</v-icon>
                      <v-icon small v-if="companyName">mdi-arrow-up</v-icon>-->
                    </th>
                    <th @click="sortdelete('company_Email')">
                      {{ removeheaders[2].text }}
                      <!-- <v-icon small v-if="!company_Email">mdi-arrow-down</v-icon>
                      <v-icon small v-if="company_Email">mdi-arrow-up</v-icon>-->
                    </th>
                    <th @click="sortdelete('tele_Number')">
                      {{ removeheaders[3].text }}
                      <!-- <v-icon small v-if="!tele_Number">mdi-arrow-down</v-icon>
                      <v-icon small v-if="tele_Number">mdi-arrow-up</v-icon>-->
                    </th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.companyCode }}</td>
                  <td>{{ item.companyName }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.telephoneNo }}</td>

                  <td>
                    <v-btn width="100" class="white--text delbtn" text>
                      {{
                      $t("delete")
                      }}
                    </v-btn>
                  </td>
                  <td>
                    <v-btn icon color="white" @click="detailcompany(item)">
                      <img width="40" height="40" :src="viewicon" alt />
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div class="text-right">
              <v-row justify="end" class="ml-5 mt-5 mr-5">
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page1"
                    :length="pageCount1"
                    color="#FFCD2C"
                    :total-visible="6"
                    @input="GetCompanyListChangePage(page1)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="2" class="mt-1">
                  <v-select
                    dense
                    style="width: 120px; float: right"
                    solo
                    label="10/page"
                    v-model="itemsPerPage1"
                    :items="items"
                    @input="GetCompanyListChangePerPage(itemsPerPage1)"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-card>
    <!-- delete  dialog -->
    <v-dialog v-model="dialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">Delete Company</p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">Are you sure want to delete?</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn class="mr-2 text-capitalize" width="120" @click="dialog = false" text>Cancel</v-btn>
          <v-btn
            color="#FF2727"
            class="mr-2 white--text text-capitalize"
            width="120"
            @click="DeleteCompany()"
          >Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="adddialog" persistent width="800">
      <v-card>
        <v-card-title>
          <p>Add Company</p>
          <v-spacer></v-spacer>
          <v-btn style="margin-top: -10px" @click="closeadddialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <p class="ml-6" style="color: #1976d2">General Information</p>
        <hr class="ml-5 mr-4" />
        <v-row class="ml-5 mr-2 mt-3">
          <v-col md="6" col="6">
            <p class="mytitle">Company Name *</p>
            <input class="input-border" type="text" v-model="AddcompanyName" />
            <br />
            <span v-if="v_CompanyName" class="validation-class">Please fill Company Name.</span>
          </v-col>
          <v-col md="6" col="6">
            <p class="mytitle">Company Code *</p>
            <input
              class="input-border"
              maxlength="5"
              @keypress="validateCompanyCode($event)"
              type="text"
              v-model="companyAbbr"
            />
            <br />
            <span v-if="v_CompanyCode" class="validation-class">Please fill Company Code.</span>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2 mt-3">
          <v-col md="6" col="6">
            <p class="mytitle">Telephone Number *</p>
            <input
              class="input-border"
              type="text"
              maxlength="11"
              @keypress="isNumber($event)"
              v-model="AddtelephoneNo"
            />
            <br />
            <span v-if="checkphone" class="validation-class">Please fill correct Phone Number(9-11).</span>
            <span v-if="v_Phone" class="validation-class">Please fill Phone Number.</span>
          </v-col>
          <v-col md="6" col="6">
            <p class="mytitle">Email *</p>
            <input class="input-border" type="text" v-model="Addemail" />
            <br />
            <span v-if="checkemail" class="validation-class">Please fill correct email format.</span>
            <span v-if="v_Email" class="validation-class">Please fill Email.</span>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2 mt-3">
          <v-col md="6" col="6">
            <p class="mytitle">Country *</p>
            <input class="input-border" type="text" v-model="Addcountry" />
            <br />
            <span v-if="v_Country" class="validation-class">Please fill Country.</span>
          </v-col>
          <v-col md="6" col="6">
            <p class="mytitle">City *</p>
            <input class="input-border" type="text" v-model="Addcity" />
            <br />
            <span v-if="v_City" class="validation-class">Please fill City.</span>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2 mt-3">
          <v-col md="6" col="6">
            <p class="mytitle">Address *</p>
            <input class="input-border" type="text" v-model="Addaddress" />
            <br />
            <span v-if="v_Address" class="validation-class">Please fill Address.</span>
          </v-col>
        </v-row>
        <p class="ml-6 mt-3" style="color: #1976d2">Login Credentials</p>
        <hr class="ml-4 mr-4" />
        <v-row class="ml-5 mr-2 mt-2">
          <v-col md="6" col="6">
            <p class="mytitle">UserID *</p>
            <input class="input-border" type="text" v-model="AdduserID" />
            <br />
            <span v-if="v_UserID" class="validation-class">Please fill User ID.</span>
          </v-col>
          <v-col md="6" col="6">
            <p class="mytitle">Password *</p>
            <input id="password" class="input-border" type="password" v-model="Addpassword" />
            <v-icon v-show="AddflagPassword" style="margin-left: -30px; cursor: pointer;" @click="TogglePassword()">mdi-eye</v-icon>
            <v-icon v-show="!AddflagPassword" style="margin-left: -30px; cursor: pointer;" @click="TogglePassword()">mdi-eye-off</v-icon>
            <br />
            <span v-if="v_Password" class="validation-class">Please fill Password.</span>
          </v-col>
        </v-row>
        <!-- <v-row class="ml-5 mr-2 mt-2">
          <v-col md="5" col="6">
            <v-checkbox v-model="status" :label="Status"></v-checkbox>
          </v-col>
          <v-col md="5" col="6">
            <v-btn color="#1976d2 " @click="addCompany">Cancel</v-btn>
            <v-btn color="#1976d2 " @click="addCompany">Save</v-btn>
          </v-col>
        </v-row>-->
        <v-card-actions style="margin-right: 3%;margin-left:3%;" class="pb-6">
          <input type="checkbox" v-model="Addstatus" />
          <span style="padding-left:7px;">Status</span>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="closeadddialog"
            text
          >Cancel</v-btn>
          <v-btn
            color="#1976d2"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="AddNewCompany()"
          >Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editdialog" persistent width="800">
      <v-card>
        <v-card-title>
          <p>Edit Company</p>
          <v-spacer></v-spacer>
          <v-btn style="margin-top: -10px" @click="closeeditdialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <p class="ml-6" style="color: #1976d2">General Information</p>
        <hr class="ml-5 mr-4" />
        <v-row class="ml-5 mr-2 mt-3">
          <v-col md="6" col="6">
            <p class="mytitle">Company Name *</p>
            <input class="input-border" type="text" v-model="UpdatecompanyName" />
            <br />
            <span v-if="v_CompanyName" class="validation-class">Please fill Company Name.</span>
          </v-col>
          <v-col md="6" col="6">
            <p class="mytitle">Company Code *</p>
            <input
              class="input-border"
              maxlength="5"
              type="text"
              @keypress="validateCompanyCode($event)"
              v-model="companyAbbrEdit"
            />
            <br />
            <span v-if="v_CompanyCode" class="validation-class">Please fill Company Code.</span>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2 mt-3">
          <v-col md="6" col="6">
            <p class="mytitle">Telephone Number *</p>
            <input
              class="input-border"
              type="text"
              maxlength="11"
              @keypress="isNumber($event)"
              v-model="UpdatetelephoneNo"
            />
            <br />
            <span v-if="checkphone" class="validation-class">Please fill correct Phone Number(9-11).</span>
            <span v-if="v_Phone" class="validation-class">Please fill Phone Number.</span>
          </v-col>
          <v-col md="6" col="6">
            <p class="mytitle">Email *</p>
            <input class="input-border" type="text" v-model="Updateemail" />
            <br />
            <span v-if="checkemail" class="validation-class">Please fill correct email format.</span>
            <span v-if="v_Email" class="validation-class">Please fill Email.</span>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2 mt-3">
          <v-col md="6" col="6">
            <p class="mytitle">Country *</p>
            <input class="input-border" type="text" v-model="Updatecountry" />
            <br />
            <span v-if="v_Country" class="validation-class">Please fill Country.</span>
          </v-col>
          <v-col md="6" col="6">
            <p class="mytitle">City *</p>
            <input class="input-border" type="text" v-model="Updatecity" />
            <br />
            <span v-if="v_City" class="validation-class">Please fill City.</span>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2 mt-3">
          <v-col md="6" col="6">
            <p class="mytitle">Address *</p>
            <input class="input-border" type="text" v-model="Updateaddress" />
            <br />
            <span v-if="v_Address" class="validation-class">Please fill Address.</span>
          </v-col>
        </v-row>
        <p class="ml-6 mt-3" style="color: #1976d2">Login Credentials</p>
        <hr class="ml-4 mr-4" />
        <v-row class="ml-5 mr-2 mt-2">
          <v-col md="6" col="6">
            <p class="mytitle">UserID *</p>
            <input class="input-border" type="text" v-model="UpdateuserID" />
            <br />
            <span v-if="v_UserID" class="validation-class">Please fill User ID.</span>
          </v-col>
          <v-col md="6" col="6">
            <p class="mytitle">Password *</p>
            <input class="input-border" type="password" v-model="Updatepassword" />
            <br />
            <span v-if="v_Password" class="validation-class">Please fill Password.</span>
          </v-col>
        </v-row>
        <v-card-actions style="margin-right: 3%;margin-left:3%;" class="pb-6">
          <input type="checkbox" v-model="Updatestatus" />
          <span style="padding-left:7px;">Status</span>
          <!-- <v-checkbox v-model="Updatestatus" label="Status"></v-checkbox> -->
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="closeeditdialog"
            text
          >Cancel</v-btn>
          <v-btn
            color="#1976d2"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="UpdateCompany()"
          >Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detaildialog" persistent width="800">
      <v-card>
        <v-card-title>
          <p>Detail Company</p>
          <v-spacer></v-spacer>
          <v-btn style="margin-top: -10px" @click="detaildialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <p class="ml-6" style="color: #1976d2">General Information</p>
        <hr class="ml-5 mr-4" />
        <v-row class="ml-5 mr-2 mt-3">
          <v-col md="6" col="6">
            <p class="mytitle">Company Name</p>
            <input class="input-border" type="text" v-model="DetailcompanyName" disabled />
          </v-col>
          <v-col md="6" col="6">
            <p class="mytitle">Company Code</p>
            <input class="input-border" type="text" v-model="DetailcompanyCode" disabled />
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2 mt-3">
          <v-col md="6" col="6">
            <p class="mytitle">Telephone Number</p>
            <input
              class="input-border"
              type="text"
              maxlength="11"
              disabled
              @keypress="isNumber($event)"
              v-model="DetailtelephoneNo"
            />
          </v-col>
          <v-col md="6" col="6">
            <p class="mytitle">Email</p>
            <input class="input-border" type="text" v-model="Detailemail" disabled />
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2 mt-3">
          <v-col md="6" col="6">
            <p class="mytitle">Country</p>
            <input class="input-border" type="text" v-model="Detailcountry" disabled />
          </v-col>
          <v-col md="6" col="6">
            <p class="mytitle">City</p>
            <input class="input-border" type="text" v-model="Detailcity" disabled />
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2 mt-3">
          <v-col md="6" col="6">
            <p class="mytitle">Address</p>
            <input class="input-border" type="text" v-model="Detailaddress" disabled />
          </v-col>
        </v-row>
        <p class="ml-6 mt-3" style="color: #1976d2">Login Credentials</p>
        <hr class="ml-4 mr-4" />
        <v-row class="ml-5 mr-2 mt-2">
          <v-col md="6" col="6">
            <p class="mytitle">UserID</p>
            <input class="input-border" type="text" v-model="DetailuserID" disabled />
          </v-col>
          <v-col md="6" col="6">
            <p class="mytitle">Password</p>
            <input
              class="input-border"
              type="password"
              placeholder="******"
              v-model="Detailpassword"
              disabled
            />
          </v-col>
        </v-row>
        <v-card-actions style="margin-right: 3%;margin-left:3%;" class="pb-6">
          <input type="checkbox" v-model="Detailstatus" />
          <span style="padding-left:7px;">Status</span>
          <!-- <v-checkbox v-model="Detailstatus" label="Status"></v-checkbox> -->
          <v-spacer></v-spacer>
          <!-- <v-btn
            class="mr-2 white--text text-capitalize"
            width="120"
            color="#1976d2"
            max-height="35"
            @click="detaildialog = false"
            text
          >Ok</v-btn>-->
          <v-btn
            color="#1976d2"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="detaildialog = false"
          >Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />
    
    <Loading
      :flagLoading="flagLoadingSend"
    />
    
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
// import store from "@/store";
// import LogTrace from "@/api/Function.js";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components: {
    Alert,
    Loading,
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    size: {
      type: String,
      default: "10",
    },
    characters: {
      type: String,
      default: "a-z,A-Z,0-9,#",
    },
    placeholder: {
      type: String,
      default: "Password",
    },
    auto: [String, Boolean],
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      flagAlertSend: false,
      mesAlertSend: "",
      typeAlertSend: "success",
      search: "",
      deletesearch: "",
      v_CompanyName: false,
      v_CompanyCode: false,
      v_Phone: false,
      v_Email: false,
      v_Country: false,
      v_City: false,
      v_Address: false,
      v_UserID: false,
      v_Password: false,
      //userid: store.state.username,
      flagLoadingSend: false,
      detaildialog: false,
      adddialog: false,
      editdialog: false,
      status: false,
      companyName: null,
      companyCode: null,
      tele_Number: null,
      activeFlag: null,
      company_ID: 0,
      userid: 0,
      items: [10, 20, 30],
      url: enurl.apiUrl,
      //add
      AddcompanyName: "",
      AddcompanyCode: "",
      AddtelephoneNo: "",
      checkphone: false,
      checkemail: false,
      Addemail: "",
      Addcountry: "",
      Addcity: "",
      Addaddress: "",
      AdduserID: "",
      Addpassword: "",
      AddflagPassword: false,
      Addstatus: false,

      //Detail
      DetailcompanyName: null,
      DetailcompanyCode: null,
      DetailtelephoneNo: null,
      Detailemail: null,
      Detailcountry: null,
      Detailcity: null,
      Detailaddress: null,
      DetailuserID: 0,
      Detailpassword: null,
      Detailstatus: false,

      //Update
      UpdatecompanyName: "",
      UpdatecompanyCode: "",
      UpdatetelephoneNo: "",
      Updateemail: "",
      Updatecountry: "",
      Updatecity: "",
      Updateaddress: "",
      UpdateuserID: "",
      Updatepassword: "",
      Updatestatus: false,

      //delete
      companyID: 0,

      images: {
        edit: require("@/assets/edit.svg"),
        delete: require("@/assets/delete1.png"),
      },
      inactivecolor: "text-danger",

      dialog: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,

      //Inactive Company
      page1: 1,
      pageCount1: 0,
      itemsPerPage1: 10,

      statusCompany: true,
      
      CompanyManagementListCount: 0,
      CompanyList: [],
      Deletecompanylist: [],
      email: "",
      deletecompany: {
        companyname: null,
        companycode: null,
        teleph: null,
        email: null,
        country: null,
        city: null,
        address: null,
        expiredate: null,
        userid: null,
      },
      viewicon: require("@/assets/Group2652(1).png"),
    };
  },
  computed: {
    companyAbbr: {
      get() {
        return this.AddcompanyCode;
      },
      set(v) {
        if (v.match(/^[a-zA-Z0-9.]+$/) && v.length <= 5) {
          this.AddcompanyCode = v;
          //this.oldAbbr = v;
        } else {
          this.AddcompanyCode = new String(
            this.AddcompanyCode || null ? this.AddcompanyCode : ""
          );
          if (!v.length || !v) {
            this.AddcompanyCode = "";
            //this.oldAbbr = "";
          }
        }
      },
    },
    companyAbbrEdit: {
      get() {
        return this.UpdatecompanyCode;
      },
      set(v) {
        if (v.match(/^[a-zA-Z0-9.]+$/) && v.length <= 5) {
          this.UpdatecompanyCode = v;
          //this.oldAbbr = v;
        } else {
          this.UpdatecompanyCode = new String(
            this.UpdatecompanyCode || null ? this.UpdatecompanyCode : ""
          );
          if (!v.length || !v) {
            this.UpdatecompanyCode = "";
            //this.oldAbbr = "";
          }
        }
      },
    },
    headers() {
      return [
        {
          text: "Company Code",
          align: "left",
          sortable: true,
          value: "companyCode",
        },
        {
          text: "Company Name",
          value: "companyName",
          align: "left",
          sortable: true,
        },
        {
          text: "Email",
          value: "email",
          align: "left",
          sortable: true,
        },
        {
          text: "Telephone Number",
          align: "left",
          value: "telephoneNo",
          sortable: true,
        },

        {
          text: "Status",
          align: "left",
          value: "status",
          sortable: true,
        },
        {
          text: "Active",
          value: "actions",
          sortable: true,
          align: "left",
        },
      ];
    },
    removeheaders() {
      return [
        {
          text: "Company Code",
          align: "left",
          sortable: true,
          value: "companyCode",
        },
        {
          text: "Company Name",
          value: "companyName",
          align: "left",
          sortable: true,
        },

        {
          text: "Email",
          align: "left",
          value: "email",
          sortable: true,
        },
        {
          text: "Telephone Number",
          align: "left",
          value: "telephoneNo",
          sortable: true,
        },
        {
          text: "Status",
          align: "left",

          sortable: true,
        },
        {
          text: "Active",
          value: "removeactions",
          sortable: true,
          align: "left",
        },
      ];
    },
  },
  watch: {
    AddcompanyName: function (val) {
      if (val != "" || val != null) {
        this.v_CompanyName = false;
      } else {
        this.v_CompanyName = true;
      }
    },
    companyAbbr: function (val) {
      if (val != "" || val != null) {
        this.v_CompanyCode = false;
      } else {
        this.v_CompanyCode = true;
      }
    },
    Addemail: function (val) {
      if (val != "" || val != null) {
        this.v_Email = false;
      } else {
        this.v_Email = true;
      }
    },
    AddtelephoneNo: function (val) {
      if (val != "" || val != null) {
        this.v_Phone = false;
      } else {
        this.v_Phone = true;
      }
    },
    Addcountry: function (val) {
      if (val != "" || val != null) {
        this.v_Country = false;
      } else {
        this.v_Country = true;
      }
    },
    Addcity: function (val) {
      if (val != "" || val != null) {
        this.v_City = false;
      } else {
        this.v_City = true;
      }
    },
    Addaddress: function (val) {
      if (val != "" || val != null) {
        this.v_Address = false;
      } else {
        this.v_Address = true;
      }
    },
    AdduserID: function (val) {
      if (val != "" || val != null) {
        this.v_UserID = false;
      } else {
        this.v_UserID = true;
      }
    },
    Addpassword: function (val) {
      if (val != "" || val != null) {
        this.v_Password = false;
      } else {
        this.v_Password = true;
      }
    },
    UpdatecompanyName: function (val) {
      if (val != "" || val != null) {
        this.v_CompanyName = false;
      } else {
        this.v_CompanyName = true;
      }
    },
    companyAbbrEdit: function (val) {
      if (val != "" || val != null) {
        this.v_CompanyCode = false;
      } else {
        this.v_CompanyCode = true;
      }
    },
    Updateemail: function (val) {
      if (val != "" || val != null) {
        this.v_Email = false;
      } else {
        this.v_Email = true;
      }
    },
    UpdatetelephoneNo: function (val) {
      if (val != "" || val != null) {
        this.v_Phone = false;
      } else {
        this.v_Phone = true;
      }
    },
    Updatecountry: function (val) {
      if (val != "" || val != null) {
        this.v_Country = false;
      } else {
        this.v_Country = true;
      }
    },
    Updatecity: function (val) {
      if (val != "" || val != null) {
        this.v_City = false;
      } else {
        this.v_City = true;
      }
    },
    Updateaddress: function (val) {
      if (val != "" || val != null) {
        this.v_Address = false;
      } else {
        this.v_Address = true;
      }
    },
    UpdateuserID: function (val) {
      if (val != "" || val != null) {
        this.v_UserID = false;
      } else {
        this.v_UserID = true;
      }
    },
    Updatepassword: function (val) {
      if (val != "" || val != null) {
        this.v_Password = false;
      } else {
        this.v_Password = true;
      }
    },
  },
  mounted() {
    this.GetCompanyList();
  },

  methods: {
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    GotoAddCompany(){
      let self = this;
      self.$router.push("/AddCompany");
    },

    orginalOne(val) {
      this.companyCode = null;
      this.companyName = null;
      this.email = null;
      this.company_Email = null;
      this.tele_Number = null;
      this.search = "";
      this.deletesearch = "";
      this.statusCompany = val;
      this.GetCompanyList();
    },
    sortFun(rowName) {
      // console.log(rowName);

      let self = this;
      let keyName = [
        "companyCode",
        "companyName",
        "status",
        "tele_Number",
        "company_Email",
      ];
      if (
        rowName == "companyCode" ||
        rowName == "companyName" ||
        rowName == "company_Email"
      ) {
        self.CompanyList = self.CompanyList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.CompanyList = self.CompanyList.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    sortdelete(rowName) {
      let self = this;
      let keyName = [
        "companyCode",
        "companyName",
        "company_Email",
        "tele_Number",
      ];
      if (
        rowName == "companyCode" ||
        rowName == "companyName" ||
        rowName == "company_Email"
      ) {
        self.Deletecompanylist = self.Deletecompanylist.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.Deletecompanylist = self.Deletecompanylist.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    DeleteDialog(id) {
      this.companyID = id;
      this.dialog = true;
    },
    TogglePassword() {
      var x = document.getElementById("password");
      if (x.type === "password") {
        x.type = "text";
        this.AddflagPassword = false;
      } else {
        x.type = "password";
        this.AddflagPassword = true;
      }
    },
    goNewCompany(temp) {
      let self = this;
      self.$router.push({ name: "EditCompany", params: { temp } });
      // try {
      // var self = this;
      // self.editdialog = true;
      // self.companyID = temp.companyID;
      // self.UpdatecompanyName = temp.companyName;
      // self.UpdatecompanyCode = temp.companyCode;
      // self.UpdatetelephoneNo = temp.telephoneNo;
      // self.Updateemail = temp.email;
      // self.Updatecountry = temp.country;
      // self.Updatecity = temp.city;
      // self.Updateaddress = temp.address;
      // self.Updatestatus = temp.status;

      //   let tempsent = {
      //     id: parseInt(temp.companyID)
      //   };

      //   axios.post(`${self.url}Company/GetUserCompany`, tempsent)
      //   .then(
      //     function (response) {
      //       self.UpdateuserID = response.data.data.username;
      //       self.userid = response.data.data.userID;
      //       self.employeeID = response.data.data.employeeID;
      //       self.Updatepassword = response.data.data.password;
      //   }).catch(
      //     function (error) {
      //       console.log(error);
      //     }
      //   )
      // } catch (error) {
      //   console.log(error);
      // }
    },
    GetCompanyList() {
      try {
        let self = this;
        let temp = {
          page: 1,
          perPage: 10,
          search: self.search == "" ? null : self.search,
          activeFlag: self.statusCompany
        };
        self.flagLoadingSend = true;
        axios.post(`${self.url}Company/GetAllCompanies`, temp).then(
          function (response) {
            self.flagLoadingSend = false;
            let conlist = response.data.data;
            if(conlist.listData.length != 0){
              if(self.statusCompany == true){
                self.CompanyList = conlist.listData.filter((e) => e.activeFlag == true);
                self.pageCount = conlist.count;
                self.CompanyManagementListCount = response.data.data.dataCount;
              }else{
                self.Deletecompanylist = conlist.listData.filter((e) => e.activeFlag == false);
                self.pageCount1 = conlist.count;
                self.CompanyManagementListCount = response.data.data.dataCount;
              }
              self.page = 1;
              self.page1 = 1;
            }
          //self.LogTrace(null, "Get Company", 12, "Critical");
        }).catch(function (error){
          self.flagLoadingSend = false;
          alert(error);
        });
      } catch (error) {
        //self.LogTrace(error, "Get Company Fail", 12, "Critical");
        self.flagLoadingSend = false;
      }
    },
    GetCompanyListChangePage(val) {
      try {
        let self = this;
        let temp = {
          page: val,
          perPage: self.itemsPerPage,
          search: self.search == "" ? null : self.search,
          activeFlag: self.statusCompany
        };
        self.flagLoadingSend = true;
        axios.post(`${self.url}Company/GetAllCompanies`, temp).then(
          function (response) {
            self.flagLoadingSend = false;
            let conlist = response.data.data;
            if(conlist.listData.length != 0){
              if(self.statusCompany == true){
                self.CompanyList = conlist.listData.filter((e) => e.activeFlag == true);
                self.pageCount = conlist.count;
              
              }else{
                self.Deletecompanylist = conlist.listData.filter((e) => e.activeFlag == false);
                self.pageCount1 = conlist.count;
              }
              
            }
          //self.LogTrace(null, "Get Company", 12, "Critical");
        }).catch(function (error){
          self.flagLoadingSend = false;
          alert(error);
        });
      } catch (error) {
        //self.LogTrace(error, "Get Company Fail", 12, "Critical");
        self.flagLoadingSend = false;
      }
    },
    GetCompanyListChangePerPage(val) {
      try {
        let self = this;
        let temp = {
          page: 1,
          perPage: val,
          search: self.search == "" ? null : self.search,
          activeFlag: self.statusCompany
        };
        self.flagLoadingSend = true;
        axios.post(`${self.url}Company/GetAllCompanies`, temp).then(
          function (response) {
            self.flagLoadingSend = false;
            let conlist = response.data.data;
            if(conlist.listData.length != 0){
              if(self.statusCompany == true){
                self.CompanyList = conlist.listData.filter((e) => e.activeFlag == true);
                self.pageCount = conlist.count;
                
              }else{
                self.Deletecompanylist = conlist.listData.filter((e) => e.activeFlag == false);
                self.pageCount1 = conlist.count;
               
              }
              self.page = 1;
            }
          //self.LogTrace(null, "Get Company", 12, "Critical");
        }).catch(function (error){
          self.flagLoadingSend = false;
          alert(error);
        });
      } catch (error) {
        //self.LogTrace(error, "Get Company Fail", 12, "Critical");
        self.flagLoadingSend = false;
      }
    },
    GetCompanyListSearch(val) {
      // if(val.length > 0){
        try {
          let self = this;
          let temp = {
            page: 1,
            perPage: self.itemsPerPage,
            search: val == "" ? null : val,
            activeFlag: self.statusCompany
          };
          self.search = val;
          self.flagLoadingSend = true;
          axios.post(`${self.url}Company/GetAllCompanies`, temp).then(
            function (response) {
              self.flagLoadingSend = false;
              let conlist = response.data.data;
              if(conlist.listData && conlist.listData.length != 0){
                if(self.statusCompany == true){
                  self.CompanyList = conlist.listData.filter((e) => e.activeFlag == true);
                  self.pageCount = conlist.count;
                  self.CompanyManagementListCount = response.data.data.dataCount;
                }else{
                  self.Deletecompanylist = conlist.listData.filter((e) => e.activeFlag == false);
                  self.pageCount1 = conlist.count;
                  self.CompanyManagementListCount = response.data.data.dataCount;
                }
                self.page = 1;
                self.page1 = 1;
              }
            //self.LogTrace(null, "Get Company", 12, "Critical");
          }).catch(function (error){
          self.flagLoadingSend = false;
          alert(error);
        });
        } catch (error) {
          //self.LogTrace(error, "Get Company Fail", 12, "Critical");
          self.flagLoadingSend = false;
        }
      // }
    },
    DeleteCompany() {
      let self = this;
      //   let id = self.company_ID;
      //   let temp = {
      //     company_ID: id,
      //   };
      self.dialog = false;
      self.flagLoadingSend = true;
      axios
        .post(`${self.url}Company/DeleteCompany?id=${self.companyID}`)
        .then(function (response) {
          if (response.data.status == 0) {
            // alert(response.data.message);
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
            //self.LogTrace(null, "Delete Company", 12, "High");
            self.flagLoadingSend = false;
            self.GetCompanyList();
          }
        })
        .catch(function (error) {
          //self.LogTrace(error, "Delete company Fail", 12, "High");
          self.flagLoadingSend = false;
          alert(error);
        });
    },
    // GetDeletecompanylist() {
    //   try {
    //     let self = this;
    //     self.flagLoadingSend = true;
    //     axios
    //       .get(`${self.url}Company/GetDeleteCompany`)
    //       .then(function (response) {
    //         self.Deletecompanylist = response.data.data;
    //         console.log(self.Deletecompanylist);
    //         //self.LogTrace(null, "Get Delete Company", 12, "Critical");
    //         self.flagLoadingSend = false;
    //       });
    //   } catch (error) {
    //     alert(error);
    //     //self.LogTrace(error, "Get Delete Company Fail", 12, "Critical");
    //     self.flagLoadingSend = false;
    //   }
    // },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    validateCompanyCode(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z0-9.]+$/.test(char)) return true;
      else e.preventDefault(); // If not match, don't add to input text
    },

    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(email) || "invalid";
    },
    generateID: function () {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }

      for (let i = 0; i < 6; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      return password;
    },
    generate: function () {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }

      for (let i = 0; i < this.size; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      return password;
    },
    formatDate(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[1] + "/" + finaldate[2] + "/" + finaldate[0];
    },

    closeadddialog() {
      let self = this;
      self.adddialog = false;
      self.clearData();
    },

    closeeditdialog() {
      let self = this;
      self.editdialog = false;
      self.clearData();
    },

    addCompany() {
      let self = this;
      self.adddialog = true;
      self.Addpassword = this.generate();
      self.AdduserID = this.generateID();
    },

    AddNewCompany() {
      var self = this;
      //console.log(self.validateEmail(self.Addemail));
      if (
        self.AddcompanyName == "" ||
        self.AddcompanyCode == "" ||
        self.AddtelephoneNo == "" ||
        self.Addemail == "" ||
        self.Addcountry == "" ||
        self.Addcity == "" ||
        self.Addaddress == "" ||
        self.AdduserID == "" ||
        self.Addpassword == "" ||
        (self.AddtelephoneNo.length < 9 ||
        self.AddtelephoneNo.length > 11 ) ||
        self.validateEmail(self.Addemail) == "invalid"
      ) {
        // alert("Please fill in all fields.");
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "Please fill in all fields.";
        self.flagAlertSend = true;
        if (this.AddcompanyName == "") {
          this.v_CompanyName = true;
        } else {
          this.v_CompanyName = false;
        }
        if (this.AddcompanyCode == "") {
          this.v_CompanyCode = true;
        } else {
          this.v_CompanyCode = false;
        }
        if (this.AddtelephoneNo == "") {
          this.v_Phone = true;
        } else {
          if (this.AddtelephoneNo != null) {
            if (
              this.AddtelephoneNo.length < 9 ||
              this.AddtelephoneNo.length > 11
            ) {
              this.checkphone = true;
            } else {
              this.checkphone = false;
            }
            this.v_Phone = false;
          }
        }
        if (this.Addemail == "") {
          this.v_Email = true;
        } else {
          if (self.validateEmail(self.Addemail) == "invalid") {
            this.checkemail = true;
          } else {
            this.checkemail = false;
          }
          this.v_Email = false;
        }
        if (this.Addcountry == "") {
          this.v_Country = true;
        } else {
          this.v_Country = false;
        }
        if (this.Addcity == "") {
          this.v_City = true;
        } else {
          this.v_City = false;
        }
        if (this.Addaddress == "") {
          this.v_Address = true;
        } else {
          this.v_Address = false;
        }
        if (this.AdduserID == "") {
          this.v_UserID = true;
        } else {
          this.v_UserID = false;
        }
        if (this.Addpassword == "") {
          this.v_Password = true;
        } else {
          this.v_Password = false;
        }
      } else {
        var temp = {
          companyName: self.AddcompanyName,
          companyCode: self.AddcompanyCode,
          telephoneNo: self.AddtelephoneNo,
          email: self.Addemail,
          country: self.Addcountry,
          city: self.Addcity,
          address: self.Addaddress,
          status: self.Addstatus,
          employeeExtJoinRequest: {
            userID: 0,
            memberID: 0,
            username: self.AdduserID,
            firstName: "string",
            lastName: "string",
            email: "string",
            password: self.Addpassword,
            userType: "string",
            roleID: 0,
            mobile: "string",
            employeeName: "string",
            positionID: 0,
            companyID: 0,
            createBy: "string",
            createDate: "2020-12-01T12:00:30.868Z",
            activeFlag: true,
            EmployeePermission : [{
              PermissionID :1
            }],
          },
          createBy: "string",
          createDate: "2020-12-01T12:00:30.868Z",
          activeFlag: true,
        };
        self.adddialog = false;
        self.flagLoadingSend = true;
        axios
          .post(`${self.url}Company/AddCompany`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              //self.LogTrace(null, "Delete Company", 12, "High");
              self.flagLoadingSend = false;
              //self.clearData();
              self.page = 1;
              self.page1 = 1;
              self.GetCompanyList();
              self.clearData();
            } else {
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = "Company Code/Email/UserID already registered.";
              self.flagAlertSend = true;
              self.flagLoadingSend = false;
              self.adddialog = true;
            }
          })
          .catch(function (error) {
            //self.LogTrace(error, "Delete company Fail", 12, "High");
            alert(error);
            self.flagLoadingSend = false;
          });
      }
    },

    clearData() {
      let self = this;
      self.AddcompanyName = "";
      self.AddcompanyCode = "";
      self.AddtelephoneNo = "";
      self.Addemail = "";
      self.Addcountry = "";
      self.Addcity = "";
      self.Addaddress = "";
      self.Addstatus = false;
      self.AdduserID = null;
      self.Addpassword = null;
      //edit
      self.UpdatecompanyName = null;
      self.UpdatecompanyCode = null;
      self.UpdatetelephoneNo = null;
      self.Updateemail = null;
      self.Updatecountry = null;
      self.Updatecity = null;
      self.Updateaddress = null;
      self.Updatestatus = false;
      self.UpdateuserID = null;
      self.Updatepassword = null;
      //validation
      self.v_CompanyName = false;
      self.v_CompanyCode = false;
      self.v_Email = false;
      self.v_Phone = false;
      self.v_Country = false;
      self.v_City = false;
      self.v_Address = false;
      self.v_UserID = false;
      self.v_Password = false;
      self.checkphone = false;
      self.checkemail = false;
      self.userid = 0;
    },

    UpdateCompany() {
      var self = this;
      if (
        self.UpdatecompanyName == "" ||
        self.UpdatecompanyCode == "" ||
        self.UpdatetelephoneNo == "" ||
        self.Updateemail == "" ||
        self.Updatecountry == "" ||
        self.Updatecity == "" ||
        self.Updateaddress == "" ||
        self.UpdateuserID == "" ||
        self.Updatepassword == "" ||
        self.UpdatetelephoneNo.length < 9 ||
        self.UpdatetelephoneNo.length > 11 ||
        self.validateEmail(self.Updateemail) == "invalid"
      ) {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "Please fill in all fields.";
        self.flagAlertSend = true;
        if (this.UpdatecompanyName == "") {
          this.v_CompanyName = true;
        } else {
          this.v_CompanyName = false;
        }
        if (this.UpdatecompanyCode == "") {
          this.v_CompanyCode = true;
        } else {
          this.v_CompanyCode = false;
        }
        if (this.UpdatetelephoneNo == "") {
          this.v_Phone = true;
        } else {
          if (this.UpdatetelephoneNo != null) {
            if (
              this.UpdatetelephoneNo.length < 9 ||
              this.UpdatetelephoneNo.length > 11
            ) {
              this.checkphone = true;
            } else {
              this.checkphone = false;
            }
            this.v_Phone = false;
          }
        }
        if (this.Updateemail == "") {
          this.v_Email = true;
        } else {
          if (self.validateEmail(self.Updateemail) == "invalid") {
            this.checkemail = true;
          } else {
            this.checkemail = false;
          }
          this.v_Email = false;
        }
        if (this.Updatecountry == "") {
          this.v_Country = true;
        } else {
          this.v_Country = false;
        }
        if (this.Updatecity == "") {
          this.v_City = true;
        } else {
          this.v_City = false;
        }
        if (this.Updateaddress == "") {
          this.v_Address = true;
        } else {
          this.v_Address = false;
        }
        if (this.UpdateuserID == "") {
          this.v_UserID = true;
        } else {
          this.v_UserID = false;
        }
        if (this.Updatepassword == "") {
          this.v_Password = true;
        } else {
          this.v_Password = false;
        }
      } else {
        var temp = {
          companyID: self.companyID,
          companyName: self.UpdatecompanyName,
          companyCode: self.UpdatecompanyCode,
          telephoneNo: self.UpdatetelephoneNo,
          email: self.Updateemail,
          country: self.Updatecountry,
          city: self.Updatecity,
          address: self.Updateaddress,
          status: self.Updatestatus,
          employeeExtJoinRequestUpdate: {
            employeeID: self.employeeID,
            userID: self.userid,
            memberID: 0,
            username: self.UpdateuserID,
            firstName: "string",
            lastName: "string",
            email: "string",
            password: self.Updatepassword,
            userType: "string",
            roleID: 0,
            mobile: "string",
            employeeName: "string",
            positionID: 0,
            companyID: 0,
            sendfrom: 1,
            updateBy: "string",
            updateDate: "2020-12-01T15:41:27.531Z",
            activeFlag: true,
          },
          updateBy: "string",
          updateDate: "2020-12-01T15:41:27.531Z",
          activeFlag: true,
        };
        self.editdialog = false;
        self.flagLoadingSend = true;
        axios
          .post(`${self.url}Company/UpdateCompany`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = response.data.message;
              self.flagAlertSend = true;
              //self.LogTrace(null, "Delete Company", 12, "High");
              self.flagLoadingSend = false;
              self.clearData();
              self.page1 = 1;
              self.page = 1;
              self.GetCompanyList();
            } else {
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = "Company Code/Email/UserID cannot duplicate.";
              self.flagAlertSend = true;
              self.flagLoadingSend = false;
              self.editdialog = true;
            }
          })
          .catch(function (error) {
            //self.LogTrace(error, "Delete company Fail", 12, "High");
            alert(error);
            self.flagLoadingSend = false;
          });
      }
    },
    detailcompany(temp) {
      try {
      let self = this;
      self.detaildialog = true;
      self.DetailcompanyName = temp.companyName;
      self.DetailcompanyCode = temp.companyCode;
      self.DetailtelephoneNo = temp.telephoneNo;
      self.Detailemail = temp.email;
      self.Detailcountry = temp.country;
      self.Detailcity = temp.city;
      self.Detailaddress = temp.address;
      self.Detailstatus = temp.status;

        let tempsent = {
          id: parseInt(temp.companyID)
        };

        axios.post(`${self.url}Company/GetUserCompany`, tempsent)
        .then(
          function (response) {
            self.DetailuserID = response.data.data.username;
            self.Detailpassword = response.data.data.password;
        }).catch(
          function (error) {
            console.log(error);
          }
        )
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.card-preview {
  border-radius: 5px !important;
  height: 100%;
  overflow: auto;
  padding: 0;
}
input[type="checkbox"] {
  width: 17px;
  height: 17px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.text-capitalize {
  font-size: 14px;
}
.mytitle {
  font-size: 14px;
  font-weight: 300;
}
.delbtn {
  background-color: #1976d2;
  border-radius: 20px;
}
*>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #1976d2 !important;
}
*>>> .theme--light.v-data-table thead tr th {
  color: #1976d2 !important;
}
/* .v-card:not(.v-sheet--tile):not(.v-card--shaped){
  border-radius: 20px;
} */
*>>> .v-dialog {
  border-radius: 20px !important;
}
.typeofleave .v-input__slot {
  min-height: 30px !important;
  width: 90rem !important;
}
*>>> .theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fff9f0 !important;
}

*>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
*>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
*>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #1976d2 !important;
  color: white !important;
}
*>>> .v-data-table tr td {
  height: 70px;
}
*>>> .theme--light.v-data-table tbody td:not(.v-data-table__mobile-row) {
  font-weight: 400;
}
*>>> .theme--light.v-data-table thead tr th {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.firsttab {
  background-color: white;
  width: 140px;
}
.tabone {
  width: 150px;
  font-size: 14px;
  border: solid 1px #1976d2;
  border-radius: 3px;
  height: 42px;
}
*>>> .mdi-paperclip::before {
  content: none;
}
*>>> .foricon .mdi:before {
  color: #1976d2;
}
.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #1976d2 !important;
}
.title {
  font-size: 20px;
}
</style>
