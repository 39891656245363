<template>
  <div>
    <v-card>
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 18px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >Report - 4 Cert expired (on behalf)</div>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="3"></v-col>
            <v-col cols="12" sm="6" md="4" lg="1">
              <v-autocomplete
                dense
                solo
                class="mt-5"
                v-model="FilterMonth"
                :items="itemsFilterMonth"
                @input="ChangeMonth(FilterMonth)"              
              ></v-autocomplete>
            </v-col>            
             <v-col cols="12" sm="6" md="4" lg="2">
              <!-- <v-text-field
                dense
                v-model="search"
                outlined
                v-on:keyup.enter="GetDataLogforReport"
                placeholder="Search"
                placeholder-color="#ACACAC"
                prepend-inner-icon="mdi-magnify"
                color="#126496"
              />-->
              <label class="for--text">วันที่เริ่มต้น</label>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="StartDate"
                    @change="v => {StartDate = v;CheckValueStartDate(StartDate)}"
                    outlined
                    dense
                  >
                    <template v-slot:append>
                      <div
                        v-on="on"
                        style="background-color:rgb(25, 118, 210);width:39px;height:40px;cursor:pointer;margin-top:-8px;margin-right: -11px;border-radius:3px"
                      >
                        <img
                          src="@/assets/Mask Group 27.png"
                          width="20"
                          style="margin-left:10px;margin-top:10px"
                        />
                      </div>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="th"
                  v-model="date"
                  no-title
                  @change="CheckValueStartDate(date)"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <label class="for--text">วันที่สิ้นสุด</label>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="EndDate"
                    @change="v => {EndDate = v;CheckValueEndDate(EndDate)}"
                    outlined
                    dense
                  >
                    <template v-slot:append>
                      <div
                        v-on="on"
                        style="background-color:rgb(25, 118, 210);width:39px;height:40px;cursor:pointer;margin-top:-8px;margin-right: -11px;border-radius:3px"
                      >
                        <img
                          src="@/assets/Mask Group 27.png"
                          width="20"
                          style="margin-left:10px;margin-top:10px"
                        />
                      </div>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="th"
                  v-model="date1"
                  @change="CheckValueEndDate(date1)"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-btn width="180px" color="#1976d2 " class="white--text mt-5" router @click="SearchDataLogVolumnSignPerUser()">Apply</v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-btn
                width="180px"
                color="#1976d2 "
                class="white--text mt-5"
                router
                @click="ExportCSV()"
              >Export CSV</v-btn>
            </v-col>
          </v-row>
          <div>
            <v-data-table
              :headers="headers"
              :items="ListData"
              hide-default-footer
              style="color: #444444"
              :items-per-page="itemsPerPage"
              :page.sync="page"
              single-line
              hide-details
            >
              <template v-slot:item="{ item, index }">
                <tr>
                  <td>{{ no_run + (index + 1) }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.certificateCreateDate }}</td>
                  <td>{{ item.certificateExpiredDate }}</td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <br>
          <v-row>
            <v-col cols="12" md="7"></v-col>
            <v-col cols="12" md="1">
              <v-autocomplete
                dense
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="ChangePerPage(itemsPerPage)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-pagination
                v-model="page"
                :total-visible="7"
                :length="pageCount"
                @input="ChangePage(page)"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>

    <Loading
      :flagLoading="flagLoadingSend"
    />
    
    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />
  </div>
</template>
<script>
import axios from "axios";
import store from "../store";
import enurl from "@/api/environment";
import * as moment from "moment/moment";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components: {
    Alert,
    Loading,
  },
  props: {},
  data() {
    return {
      flagAlertSend: false,
      mesAlertSend: "",
      typeAlertSend: "success",
      url: enurl.apiUrl,
      permissionid: store.state.permissionid,
      flag_dowload: false,
      no_run: null,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      FilterMonth: "1 เดือน",
      items: [10, 15, 20],
      itemsFilterMonth: ["1 เดือน", "2 เดือน", "3 เดือน"],
      ListData: [],
      flagLoadingSend: false,
      menu1: false,
      menu2: false,
      StartDate: "",
      EndDate: "",
      date: new Date().toISOString().substr(0, 10),
      date1: new Date().toISOString().substr(0, 10),
      dateFormatted: moment(new Date().toISOString().substr(0, 10))
        .add(543, "years")
        .lang("th")
        .format("DD/MM/YYYY"),
      dateFormatted1: moment(new Date().toISOString().substr(0, 10))
        .add(543, "years")
        .lang("th")
        .format("DD/MM/YYYY")
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "#",
          align: "left",
          sortable: false
        },
        {
          text: "Name",
          value: "name",
          align: "left",
          sortable: false
        },
        {
          text: "CertificateCreateDate",
          value: "certificateCreateDate",
          align: "left",
          sortable: false
        },
        {
          text: "CertificateExpiredDate",
          value: "certificateExpiredDate",
          align: "left",
          sortable: false
        },
      ];
    }
  },

  watch: {
    date() {
      this.dateFormatted = this.DateFormat(this.date);
    },
    date1() {
      this.dateFormatted1 = this.DateFormat(this.date1);
    }
  },

  mounted() {
    this.GetDataCertExpired();
  },

  methods: {
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    CheckValueStartDate(value) {
      if (value == "") {
        this.date = "";
      } else {
        if(this.EndDate == ""){
          this.StartDate = this.DateFormat(value);
        }else{
          const ArrEdate = this.EndDate.split("/");
          const Edate = (parseInt(ArrEdate[2]) - 543) + "-" + ArrEdate[1] + "-" + ArrEdate[0];
          // console.log(Edate);
          if(new Date(Edate) >= new Date(value)){
            this.StartDate = this.DateFormat(value);
          }else{
            this.typeAlertSend = "warning"; //success warning error
            this.mesAlertSend = "กรุณาเลือกวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น";
            this.flagAlertSend = true;
            this.date = "";
          }
        }
      }
    },

    CheckValueEndDate(value) {
      if (value == "") {
        this.date1 = "";
      } else {
        if(this.StartDate == ""){
          this.EndDate = this.DateFormat(value);
        }else{
          const ArrSdate = this.StartDate.split("/");
          const Sdate = (parseInt(ArrSdate[2]) - 543) + "-" + ArrSdate[1] + "-" + ArrSdate[0];
          // console.log(Sdate);
          if(new Date(Sdate) <= new Date(value)){
            this.EndDate = this.DateFormat(value);
          }else{
            this.typeAlertSend = "warning"; //success warning error
            this.mesAlertSend = "กรุณาเลือกวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น";
            this.flagAlertSend = true;
            // alert("Please select an end date greater than the start date.");
            this.date1 = "";
          }
        }
      }
    },

    ChangeMonth: function(value) {
      let self = this;
      self.flagLoadingSend = true;
      let tempdata = {
        StartDate: self.StartDate,
        EndDate: self.EndDate,
        Month: value == "1 เดือน" ? 1 : value == "2 เดือน" ? 2 : value == "3 เดือน" ? 3 : 0,
        Page: 1,
        PerPage: self.itemsPerPage
      };
      axios
        .post(`${self.url}Report/getDataCertExpired`, tempdata)
        .then(function(response) {
          if (response.data.status == 0) {
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.ListData = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.page = 1;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.ListData = [];
            self.pageCount = 0;
            self.page = 1;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = "ไม่พบข้อมูล";
            self.flagAlertSend = true;
          }
        })
        .catch(error => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
    },

    SearchDataLogVolumnSignPerUser: function() {
      let self = this;
      if((self.StartDate == "" && self.EndDate == "") || (self.StartDate != "" && self.EndDate != "")){
      self.flagLoadingSend = true;
      let tempdata = {
        StartDate: self.StartDate,
        EndDate: self.EndDate,
        Page: self.page,
        PerPage: self.itemsPerPage
      };
      axios
        .post(`${self.url}Report/getDataCertExpired`, tempdata)
        .then(function(response) {
          if (response.data.status == 0) {
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.ListData = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.page = 1;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.ListData = [];
            self.pageCount = 0;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = "ไม่พบข้อมูล";
            self.flagAlertSend = true;
          }
        })
        .catch(error => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
      }
      else if (self.StartDate == ""){
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณาเลือกวันที่เริ่มต้น";
        self.flagAlertSend = true;
      }
      else if (self.EndDate == ""){
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณาเลือกวันที่สิ้นสุด";
        self.flagAlertSend = true;
      }
    },

    DateFormat(value) {
      return moment(value)
        .add(543, "years")
        .lang("th")
        .format("DD/MM/YYYY");
    },

    ExportCSV: function() {
      let self = this;
      self.flagLoadingSend = true;
      axios
        .post(`${self.url}Report/ExportCsvCertExpired`)
        .then(function(response) {
          if (response.data.status == 0) {
            var tempdata = response.data.data.result;
            const blobPDF = self.base64ToBlob(
              tempdata,
              "text/csv;charset=utf-8;"
            );
            const linkSource = URL.createObjectURL(blobPDF);
            var link = document.createElement("a");
            var date = new Date();
            link.download = "Report4_" + date.toLocaleString("th-TH") + ".csv";
            link.href = linkSource;
            link.click();

            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
          }
        })
        .catch(error => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
    },

    base64ToBlob(base64, type = "application/octet-stream") {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    },

    GetDataCertExpired: function() {
      let self = this;
      self.flagLoadingSend = true;
      let tempdata = {
        Page: self.page,
        PerPage: self.itemsPerPage,
        StartDate: self.StartDate,
        EndDate: self.EndDate,
        Month: self.FilterMonth == "1 เดือน" ? 1 : self.FilterMonth == "2 เดือน" ? 2 : self.FilterMonth == "3 เดือน" ? 3 : 0
      };
      axios
        .post(`${self.url}Report/getDataCertExpired`, tempdata)
        .then(function(response) {
          if (response.data.status == 0) {
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.ListData = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.ListData = [];
            self.pageCount = 0;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = "ไม่พบข้อมูล";
            self.flagAlertSend = true;
          }
        })
        .catch(error => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
    },

    ChangePerPage: function(value) {
      let self = this;
      self.flagLoadingSend = true;
      let tempdata = {
        StartDate: self.StartDate,
        EndDate: self.EndDate,
        //Search: self.search,
        Page: 1,
        PerPage: value
      };
      axios
        .post(`${self.url}Report/getDataCertExpired`, tempdata)
        .then(function(response) {
          if (response.data.status == 0) {
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.ListData = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.page = 1;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.ListData = [];
            self.pageCount = 0;
            self.page = 1;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = "ไม่พบข้อมูล";
            self.flagAlertSend = true;
          }
        })
        .catch(error => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
    },

    ChangePage: function(value) {
      let self = this;
      self.flagLoadingSend = true;
      let tempdata = {
        StartDate: self.StartDate,
        EndDate: self.EndDate,
        //Search: self.search,
        Page: value,
        PerPage: self.itemsPerPage
      };
      axios
        .post(`${self.url}Report/getDataCertExpired`, tempdata)
        .then(function(response) {
          if (response.data.status == 0) {
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.ListData = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.ListData = [];
            self.pageCount = 0;
            self.page = 1;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = "ไม่พบข้อมูล";
            self.flagAlertSend = true;
          }
        })
        .catch(error => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
    },
  }
};
</script>
<style scoped>
@media only screen and (min-width: 1030px) and (max-width: 1409px) {
  .per_page {
    margin-left: -5rem !important;
  }
  * >>> .v-select > .v-input__control > .v-input__slot {
    width: 80px !important;
  }
}
* >>> tbody tr:nth-of-type(even) {
  background-color: #1264960d;
}
* >>> .v-text-field--outlined fieldset {
  color: #ebebf2 !important;
}
* >>> .v-data-table-header {
  background-color: #126496 !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
* >>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
* >>> .theme--light.v-pagination .v-pagination__item--active {
  background: #126496 !important;
  color: white !important;
}
</style>
<style lang="scss">
.__pagination {
  .v-pagination__navigation {
    box-shadow: none !important;
    border: 1px solid #0000001a;
    width: auto !important;
    padding-left: 10px;
    padding-right: 10px;
    &:focus {
      outline: none;
    }
  }
}
</style>
