<template>
  <div>
    <v-card class="card-preview">
      <div
        class="pt-8"
        style="
          text-color: #444444;
          font-size: 18px;
          margin-left: 1rem;
          margin-right: 1rem;
          color: #444444;
        "
      >
        User Attribute Monitor
      </div>
      <br />
      <div class="pl-5">
        <v-row style="margin-left: 11px; margin-right: 11px">
          <v-col cols="12" md="10">
            <v-text-field
              autocomplete="off"
              label="Regular"
              v-model="search"
              solo
              dense
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn
              color="#126496"
              class="mr-2 text-capitalize"
              style="color: white; float: right"
              width="120"
              @click="GetDataLog()"
              >Apply Filter</v-btn
            >
          </v-col>
        </v-row>
        <v-data-table
          style="margin-left: 10px; margin-right: 40px"
          class="mytable"
          :headers="headers"
          :items="ListData"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:header>
            <thead>
              <tr>
                <th
                  style="min-width: 180px !important; background-color: #126496"
                  class="b-text"
                >
                  {{ headers[0].text }}
                </th>
                <th
                  style="min-width: 180px !important; background-color: #126496"
                  class="b-text"
                >
                  {{ headers[1].text }}
                </th>
                <th
                  class="b-text"
                  style="min-width: 180px !important; background-color: #126496"
                >
                  {{ headers[2].text }}
                </th>

                <th
                  style="min-width: 180px !important; background-color: #126496"
                  class="b-text"
                >
                  {{ headers[3].text }}
                </th>
                <th
                  style="min-width: 180px !important; background-color: #126496"
                  class="b-text"
                >
                  {{ headers[4].text }}
                </th>
              </tr>
            </thead>
          </template>
          <!-- <template v-slot:item="{ item, index }"> -->
          <template v-slot:item="{ item }">
            <tr>
              <!-- <td>{{ no_run + (index + 1) }}</td> -->
              <td>{{ ConvertDateUTCtoLocal(item.createDate) }}</td>
              <td>{{ item.samAccountName }}</td>
              <td>{{ item.givenName }}</td>
              <td>{{ item.sn }}</td>
              <td>{{ item.description }}</td>
              <!-- <td class="pl-1">
                <img
                  src="@/assets/MagnifyingGlassIcon .png"
                  style="cursor: pointer; width: 50px; height: 50px"
                  @click="GetLoginfoData(item)"
                />
              </td> -->
            </tr>
          </template>
        </v-data-table>
        <v-spacer></v-spacer>

        <div class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="8" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="ChangePerPage(itemsPerPage)"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="10"
                @input="ChangePage(page)"
                color="#FFCD2C"
              ></v-pagination>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>

    <v-dialog v-model="LoginfoDialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span
            ><v-img
              style="width: 32px; height: 32px; margin-bottom: -30px"
              src="@/assets/Icon feather-info@2x.png"
            ></v-img
          ></span>
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 20px">
            Log info
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="left" cols="12">
                <p
                  style="font-size: 16px; color: #444444; margin-left: 40px"
                  class="pt-4"
                >
                  Change Time : {{DatetimeinDialog}}
                </p>
                <p style="font-size: 16px; color: #444444; margin-left: 40px">
                  {{DescriptioninDialog}}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            color="#126496"
            class="mr-2 white--text text-capitalize"
            width="120"
            @click="LoginfoDialog = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />

    <Loading
      :flagLoading="flagLoadingSend"
    />
    
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import moment from 'moment';
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components: {
    Alert,
    Loading,
  },
  data() {
    return {
      flagAlertSend: false,
      flagLoadingSend: false,
      mesAlertSend: "",
      typeAlertSend: "success",
      ListData: [],
      search: "",
      deletesearch: "",
      DatetimeinDialog: "",
      DescriptioninDialog: "",
      loadingdialog: false,
      no_run:  null,

      adddialog: false,
      editdialog: false,
      status: false,
      companyName: null,
      companyCode: null,
      tele_Number: null,
      activeFlag: null,
      company_ID: 0,
      items: [10, 20, 30],
      url: enurl.apiUrl,

      LoginfoDialog: false,

      //delete
      companyID: 0,
      dialog: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,

      //Inactive Company
      page1: 1,
      pageCount1: 0,
      itemsPerPage1: 10,

      CompanyList: [],
      Deletecompanylist: [],
      email: "",
    };
  },
  computed: {
    headers() {
      return [
        // {
        //   text: "#",
        //   align: "left",
        //   sortable: true,
        //   value: "companyCode",
        // },
        {
          text: "Loging DateTime",
          value: "DateTimeProcess",
          align: "left",
          sortable: true,
        },
        {
          text: "AD Account",
          align: "left",
          sortable: true,
          value: "companyCode",
        },
        {
          text: "FirstName",
          value: "companyName",
          align: "left",
          sortable: true,
        },
        {
          text: "LastName",
          value: "email",
          align: "left",
          sortable: true,
        },
        {
          text: "Log Info",
          align: "left",
          value: "status",
          sortable: true,
        },
      ];
    },
  },
  watch: {},
  mounted() {
    this.GetDataLog();
  },

  methods: {
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    ConvertDateUTCtoLocal(val){
      var localDate = moment.utc(String(new Date(val))).local();
      return localDate.format('DD/MM/YYYY HH:mm');
    },
    ChangePage: function(val){
      let self = this;
      self.loadingdialog = true;
      let temp = {
        Page: val,
        Perpage: self.itemsPerPage,
        Search: self.search
      };
      axios
        .post(`${self.url}SyncManagement/GetDataLogForAttributeMonitor`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.no_run = ((self.page - 1) * self.itemsPerPage);
            self.ListData = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.loadingdialog = false;
          } else {
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
            self.ListData = [];
            self.page = 1;
            self.pageCount = 0;
            self.loadingdialog = false;
          }
        })
        .catch(function (error) {
          alert(error);
          self.ListData = [];
          self.page = 1;
          self.pageCount = 0;
          self.loadingdialog = false;
        });
    },

    ChangePerPage: function(val){
      let self = this;
      self.loadingdialog = true;
      let temp = {
        Page: 1,
        Perpage: val,
        Search: self.search
      };
      axios
        .post(`${self.url}SyncManagement/GetDataLogForAttributeMonitor`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ListData = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.page = 1;
            self.loadingdialog = false;
          } else {
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
            self.ListData = [];
            self.page = 1;
            self.pageCount = 0;
            self.loadingdialog = false;
          }
        })
        .catch(function (error) {
          alert(error);
          self.ListData = [];
          self.page = 1;
          self.pageCount = 0;
          self.loadingdialog = false;
        });
    },

    GetDataLog: function () {
      let self = this;
      self.loadingdialog = true;
      let temp = {
        Page: 1,
        Perpage: self.itemsPerPage,
        Search: self.search
      };
      axios
        .post(`${self.url}SyncManagement/GetDataLogForAttributeMonitor`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ListData = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.page = 1;
            self.loadingdialog = false;
          } else {
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
            self.ListData = [];
            self.page = 1;
            self.pageCount = 0;
            self.loadingdialog = false;
          }
        })
        .catch(function (error) {
          alert(error);
          self.ListData = [];
          self.page = 1;
          self.pageCount = 0;
          self.loadingdialog = false;
        });
    },

    GetLoginfoData: function(value){
      let self = this;
      self.DatetimeinDialog = value.createDate;
      self.DescriptioninDialog = value.description;
      self.LoginfoDialog = true;
    },
  },
};
</script>
<style scoped>
.card-preview {
  border-radius: 5px !important;
  height: 100%;
  padding: 0;
}
input[type="checkbox"] {
  width: 17px;
  height: 17px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.text-capitalize {
  font-size: 14px;
}
.mytitle {
  font-size: 14px;
  font-weight: 300;
}
.delbtn {
  background-color: #1976d2;
  border-radius: 20px;
}
* >>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #1976d2 !important;
}
* >>> .theme--light.v-data-table thead tr th {
  /* color: #1976d2 !important; */
  color: white !important;
}
/* .v-card:not(.v-sheet--tile):not(.v-card--shaped){
  border-radius: 20px;
} */
* >>> .v-dialog {
  border-radius: 20px !important;
}
.typeofleave .v-input__slot {
  min-height: 30px !important;
  width: 90rem !important;
}
*
  >>> .theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fff9f0 !important;
}

*
  >>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
* >>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
* >>> .theme--light.v-pagination .v-pagination__item--active {
  background: #126496 !important;
  /* background: #1976d2 !important; */
  color: white !important;
}
* >>> .v-data-table tr td {
  height: 70px;
}
* >>> .theme--light.v-data-table tbody td:not(.v-data-table__mobile-row) {
  font-weight: 400;
}
* >>> .theme--light.v-data-table thead tr th {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.firsttab {
  background-color: white;
  width: 140px;
}
.tabone {
  width: 150px;
  font-size: 14px;
  border: solid 1px #1976d2;
  border-radius: 3px;
  height: 42px;
}
* >>> .mdi-paperclip::before {
  content: none;
}
* >>> .foricon .mdi:before {
  color: #1976d2;
}
.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #1976d2 !important;
}
.title {
  font-size: 20px;
}
</style>
