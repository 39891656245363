<template>
  <div>
    <v-card
      style="box-shadow: 1px 1px 1px -1px rgb(0 0 0 / 3%), 0px 1px 1px 1px rgb(0 0 0 / 3%), 0px 1px 1px 0px rgb(0 0 0 / 3%)"
      v-show="PermissionPage == false && ViewDataRolePermissionStatus == false"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 18px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >Role Management</div>
        <br />
        <div class="pl-5">
          <v-tabs class="mt-2" grey--text slider-color="#1976d2" v-model="ActiveTab">
            <v-tab class="text-capitalize tabone" @click="SelectRoleTab(1)">Permission</v-tab>
            <v-tab class="text-capitalize tabone" @click="SelectRoleTab(2)">Role</v-tab>
            <v-tabs-slider
              style="margin-top: -10px;height: 5px;"
              :color="ActiveTab == 0 ? '#126496' : '#FAA91B'"
            ></v-tabs-slider>
            <v-tab-item>
              <v-data-table
                class="mt-5"
                :headers="PermissionHeaders"
                :items="PermissionList"
                hide-default-footer
                style="color: #444444"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:top>
                  <v-row class="mb-5">
                    <v-col cols="12" md="8">
                      <v-text-field
                        label="Search"
                        v-model="PermissionSearch"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        hide-details="auto"
                        v-on:keyup.enter="SearchPermission()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:item="{ item, index }">
                  <tr>
                    <td style="text-align: center;">{{ no_run + (index + 1) }}</td>
                    <td style="text-align: center;">{{ item.permissionID }}</td>
                    <td
                      style="text-align: center;color: #24709E;"
                    >{{ item.permissionName }}</td>
                  </tr>
                </template>
              </v-data-table>
              <v-row class="mt-5">
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :color="$vuetify.theme.dark ? 'without' : '#444444'"
                        v-bind="attrs"
                        v-on="on"
                        class="text-capitalize mt-1"
                        outlined
                      >
                        {{ itemsPerPage }}
                        <v-icon color="primary">mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in paginationCount"
                        :key="index"
                        @click="
                      itemsPerPage = item.title;
                      ChangePerPage(itemsPerPage);
                    "
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-pagination
                    class="__pagination"
                    v-model="page"
                    :total-visible="6"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <div>
                <v-data-table
                  class="mt-5"
                  :headers="RoleHeaders"
                  :items="RoleList"
                  hide-default-footer
                  style="color: #444444"
                  :items-per-page="itemsRolePerPage"
                  :page.sync="pageRole"
                  single-line
                  hide-details
                >
                  <template v-slot:top>
                    <v-row class="mb-5">
                      <v-col cols="12" md="3">
                        <v-btn
                          width="180px"
                          color="#126496"
                          class="white--text"
                          router
                          @click="OpenAddRoleDialog()"
                        >+ Add Role</v-btn>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          label="Search"
                          v-model="RoleSearch"
                          prepend-inner-icon="mdi-magnify"
                          outlined
                          dense
                          hide-details="auto"
                          v-on:keyup.enter="SearchRole()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3"></v-col>
                    </v-row>
                  </template>

                  <template v-slot:item="{ item, index }">
                    <tr>
                      <td style="text-align: center;">{{ no_runRole + (index + 1) }}</td>
                      <td style="text-align: center;">{{ item.roleCode }}</td>
                      <td
                        style="text-align: center;text-decoration: underline;color: #24709E;cursor: pointer;"
                        @click="ViewDataRole(item)"
                      >{{ item.roleName }}</td>
                      <td class="pl-1" style="text-align: center;">
                        <v-btn icon>
                          <img :src="images.edit" @click="EditRole(item)" />
                        </v-btn>
                        <v-btn icon>
                          <img :src="images.delete" @click="OpenDeleteRoleDialog(item.roleID)" />
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-row class="mt-5">
                  <v-col cols="12" xs="12" sm="12" md="4">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :color="$vuetify.theme.dark ? 'without' : '#444444'"
                          v-bind="attrs"
                          v-on="on"
                          class="text-capitalize mt-1"
                          outlined
                        >
                          {{ itemsRolePerPage }}
                          <v-icon color="primary">mdi-menu-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in paginationCount"
                          :key="index"
                          @click="
                      itemsRolePerPage = item.title;
                      ChangePerPageRole(itemsRolePerPage);
                    "
                        >
                          <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4">
                    <v-pagination
                      class="__pagination"
                      v-model="pageRole"
                      :total-visible="6"
                      :length="pageRoleCount"
                      @input="ChangePageRole(pageRole)"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </div>
            </v-tab-item>
          </v-tabs>
        </div>
      </div>
    </v-card>

    <v-card
      style="box-shadow: 1px 1px 1px -1px rgb(0 0 0 / 3%), 0px 1px 1px 1px rgb(0 0 0 / 3%), 0px 1px 1px 0px rgb(0 0 0 / 3%)"
      v-show="ViewDataRolePermissionStatus == true"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 18px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        ><span><v-icon @click="HideViewDataRole">mdi-arrow-left-bold-circle-outline</v-icon></span> Signer</div>
        <br />
        <div class="pl-5">
          <v-tabs class="mt-2" grey--text slider-color="#1976d2">
            <v-tab class="text-capitalize tabone">Permission</v-tab>
            <v-tabs-slider style="margin-top: -10px;height: 5px;" color="#126496"></v-tabs-slider>
            <v-tab-item>
              <v-data-table
                class="mt-5"
                :headers="ViewRolePermissionHeaders"
                :items="ViewDataRolePermissionList"
                hide-default-footer
                style="color: #444444"
                :items-per-page="itemsPerPageViewDataRolePermission"
                :page.sync="pageViewDataRolePermission"
                single-line
                hide-details
              >
                <template v-slot:top>
                  <v-row class="mb-5">
                    <v-col cols="12" md="8">
                      <v-text-field
                        label="Search"
                        v-model="ViewDataRolePermissionSearch"
                        v-on:keyup.enter="SearchViewDataRolePermission(ViewDataRolePermissionSearch)"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:item="{ item }">
                  <tr>
                    <td style="text-align: center;">{{ item.resourceName }}</td>
                    <td style="text-align: center;">{{ item.permissionName }}</td>
                    <!-- <td style="text-align: center;">None</td> -->
                  </tr>
                </template>
              </v-data-table>
              <v-row class="mt-5">
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :color="$vuetify.theme.dark ? 'without' : '#444444'"
                        v-bind="attrs"
                        v-on="on"
                        class="text-capitalize mt-1"
                        outlined
                      >
                        {{ itemsPerPageViewDataRolePermission }}
                        <v-icon color="primary">mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in paginationCount"
                        :key="index"
                        @click="
                      itemsPerPageViewDataRolePermission = item.title;
                      ChangePerPageViewDataRolePermission(itemsPerPageViewDataRolePermission);
                    "
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-pagination
                    class="__pagination"
                    v-model="pageViewDataRolePermission"
                    :total-visible="6"
                    :length="pageCountViewDataRolePermission"
                    @input="ChangePageViewDataRolePermission(pageViewDataRolePermission)"
                  ></v-pagination>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </div>
      </div>
    </v-card>

    <v-card class="card-preview" v-show="PermissionPage == true">
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 18px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >Permission</div>
        <v-row class="pt-5" style="margin-left: 25px; margin-right: 10px">
          <div style="width: 300px" class="ml-2 mt-3">
            <v-row>
              <v-col>
                <v-text-field
                  autocomplete="off"
                  label="Regular"
                  v-model="PermissionRoleSearch"
                  solo
                  dense
                  style="
                        margin-top: -0.8rem;
                        margin-left: -1rem;
                        color: black;
                      "
                  prepend-inner-icon="mdi-magnify"
                  placeholder="search"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-row>
        <v-data-table
          style="margin-left: 10px; margin-right: 40px"
          v-model="selected"
          v-bind:headers="PermissionRoleHeaders"
          item-key="permissionID"
          show-select
          :search="PermissionRoleSearch"
          :items="PermissionRoleList"
          :page.sync="PageRolePermission"
          :items-per-page="itemsPerPageRolePermission"
          @page-count="PageCountRolePermission = $event"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <td>
                  <v-checkbox v-model="Permission_Status" @click="PermissionStatus"></v-checkbox>
                </td>
                <td>{{ PermissionRoleHeaders[0].text }}</td>
                <td>{{ PermissionRoleHeaders[1].text }}</td>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-checkbox
                  v-model="item.select"
                  @click="item.select == !item.select"
                ></v-checkbox>
              </td>
              <td>{{ item.permissionID }}</td>
              <td>{{ item.permissionName }}</td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPageRolePermission"
                :items="items"
                @input="itemsPerPageRolePermission = parseInt($event, 10)"
              ></v-select>
            </v-col>
            <v-col cols="12" md="7">
              <v-pagination
                class="__pagination"
                v-model="PageRolePermission"
                :length="PageCountRolePermission"
                :total-visible="6"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="1" class="mt-1">
              <v-btn
                class="mr-2 text-capitalize"
                width="120"
                max-height="35"
                @click="HidePermission"
                text
              >Cancel</v-btn>
            </v-col>
            <v-col cols="12" md="1" class="mt-1">
              <v-btn
                color="#126496"
                class="mr-2 white--text text-capitalize"
                width="120"
                max-height="35"
                @click="StatusEditRole == true ? EditPermissionList() : AddPermissionList()"
              >Save</v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>

    <v-dialog v-model="AddRoleDialog" persistent width="550">
      <v-card>
        <v-card-title>
          <p>Add Role</p>
          <v-spacer></v-spacer>
          <v-btn style="margin-top: -10px" @click="CloseAddRoleDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-row class="ml-5 mr-2">
          <v-col cols="12" md="3">
            <p style="margin-top: 10px;">Role Name :</p>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field placeholder="Role Name" solo v-model="AddRoleName"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -25px;">
          <v-col cols="12" md="3">
            <p style="margin-top: 10px;">Role ID :</p>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field placeholder="Role ID" solo v-model="AddRoleCode"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -25px;">
          <v-col cols="12" md="3">
            <p style="margin-top: 10px;">Permission :</p>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field placeholder="Assign Permission" solo disabled v-model="AddRolePermission"></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn
              style="margin-top: 7px;color: white;"
              color="#126496"
              @click="ClickAssignPermission"
            >Assign</v-btn>
          </v-col>
        </v-row>
        <hr class="ml-5 mr-4" />
        <br />
        <v-card-actions style="margin-right: 3%;margin-left:3%;" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="CloseAddRoleDialog"
            text
          >Cancel</v-btn>
          <v-btn
            color="#126496"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="AddRole()"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="EditRoleDialog" persistent width="550">
      <v-card>
        <v-card-title>
          <p>Edit Role</p>
          <v-spacer></v-spacer>
          <v-btn style="margin-top: -10px" @click="CloseEditRoleDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-row class="ml-5 mr-2">
          <v-col cols="12" md="3">
            <p style="margin-top: 10px;">Role Name :</p>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field placeholder="Role Name" solo v-model="EditRoleName"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -25px;">
          <v-col cols="12" md="3">
            <p style="margin-top: 10px;">Role ID :</p>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field placeholder="Role ID" solo v-model="EditRoleCode"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -25px;">
          <v-col cols="12" md="3">
            <p style="margin-top: 10px;">Permission :</p>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              placeholder="Assign Permission"
              solo
              disabled
              v-model="EditRolePermission"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn
              style="margin-top: 7px;color: white;"
              color="#126496"
              @click="ClickEditAssignPermission"
            >Assign</v-btn>
          </v-col>
        </v-row>
        <hr class="ml-5 mr-4" />
        <br />
        <v-card-actions style="margin-right: 3%;margin-left:3%;" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="CloseEditRoleDialog"
            text
          >Cancel</v-btn>
          <v-btn
            color="#126496"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="UpdateRole()"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="DeleteRoleDialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">Delete Role</p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">Are you sure want to delete?</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            @click="DeleteRoleDialog = false"
            text
          >Cancel</v-btn>
          <v-btn
            color="#FF2727"
            class="mr-2 white--text text-capitalize"
            width="120"
            @click="DeleteRole()"
          >Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAlert" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline"></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div>{{textAlert}}</div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div>
            <v-btn class="btn-light" @click="dialogAlert = false">Close</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />

    <Loading
      :flagLoading="flagLoadingSend"
    />
    
  </div>
</template>

<script>
import axios from "axios";
// import store from "../store";
import enurl from "@/api/environment";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components: {
    Alert,
    Loading,
  },
  data: () => ({
    flagAlertSend: false,
    mesAlertSend: "",
    typeAlertSend: "success",
    url: enurl.apiUrl,
    permissionid: sessionStorage.permissionid,
    freeUser: false,
    dialogAlert: false,
    textAlert: "",
    PermissionSearch: "",
    RoleSearch: "",
    page: 1,
    pageRole: 1,
    pageCount: 0,
    pageRoleCount: 0,
    itemsPerPage: 10,
    itemsRolePerPage: 10,
    items: [10, 15, 20],
    time: "",
    flagLoadingSend: false,
    no_run: null,
    no_runRole: null,
    itemsAction: [
      { title: "Download PDF" },
      { title: "Download History" },
      { title: "Delete" }
    ],
    paginationCount: [
      { title: 10 },
      { title: 20 },
      { title: 30 },
      { title: 40 }
    ],
    PermissionList: [],
    RoleList: [],
    images: {
      edit: require("@/assets/edit.svg"),
      delete: require("@/assets/delete1.png")
    },
    AddRoleDialog: false,
    EditRoleDialog: false,
    DeleteRoleDialog: false,
    RoleID: null,
    PermissionPage: false,
    AddRoleName: "",
    AddRoleCode: "",
    AddRolePermission: "",
    EditRoleID: null,
    EditRoleName: "",
    EditRoleCode: "",
    EditRolePermission: "",
    PermissionRoleList: [],
    PermissionRoleSearch: "",
    PermissionRoleHeaders: [
      {
        text: "Permission ID",
        align: "left",
        sortable: true,
        value: "permissionID"
      },
      {
        text: "Permission Name",
        align: "left",
        value: "permissionName",
        sortable: true
      }
    ],
    PageRolePermission: 1,
    PageCountRolePermission: 0,
    itemsPerPageRolePermission: 10,
    Permission_Status: false,
    ActiveTab: "",
    selected: [],
    addPermissionList: [],
    editPermissionList: [],
    tempPermission: [],
    StatusEditRole: false,

    ViewDataRolePermissionList: [],
    ViewDataRolePermissionStatus: false,
    ViewDataRolePermissionSearch: "",
    pageViewDataRolePermission: 1,
    pageCountViewDataRolePermission: 0,
    itemsPerPageViewDataRolePermission: 10,
    ResourceName: "",
  }),

  computed: {
    PermissionHeaders() {
      return [
        {
          text: "#",
          sortable: false,
          value: "no",
          align: "center",
          class: "darkprimary white--text"
        },
        {
          text: "Permission ID",
          sortable: false,
          align: "center",
          value: "permissionID",
          class: "darkprimary white--text"
        },
        {
          text: "Permission Name",
          sortable: false,
          align: "center",
          value: "permissionName",
          class: "darkprimary white--text"
        }
      ];
    },
    RoleHeaders() {
      return [
        {
          text: "#",
          sortable: false,
          value: "no",
          align: "center",
          class: "darkprimary white--text rounded-tl-lg"
        },
        {
          text: "Role ID",
          sortable: false,
          align: "center",
          class: "darkprimary white--text "
        },
        {
          text: "Role Name",
          sortable: false,
          align: "center",
          class: "darkprimary white--text "
        },
        {
          text: "Action",
          sortable: false,
          align: "center",
          class: "darkprimary white--text rounded-tr-lg"
        }
      ];
    },
    ViewRolePermissionHeaders() {
      return [
        {
          text: "Resource",
          sortable: false,
          value: "no",
          align: "center",
          class: "darkprimary white--text rounded-tl-lg"
        },
        {
          text: "Access Level",
          sortable: false,
          align: "center",
          class: "darkprimary white--text "
        },
        // {
        //   text: "Request Condition",
        //   sortable: false,
        //   align: "center",
        //   class: "darkprimary white--text "
        // }
      ];
    }
  },
  methods: {
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    ViewDataRole(value) {
      let self = this;
      self.ViewDataRolePermissionList = [];
      self.ResourceName = value.roleCode;
      self.ViewDataRolePermissionStatus = true;
      for(let i = 0; i < value.rolePermission.length; i++){
        let temp = {
          resourceName: self.ResourceName,
          permissionName: value.rolePermission[i].permissionName
        };
        self.ViewDataRolePermissionList.push(temp);
      }
      // value.rolePermission.forEach(element => {
      //   self.ViewDataRolePermissionList.push(element);
      // });
      self.pageCountViewDataRolePermission = 1;
    },

    HideViewDataRole() {
      let self = this;
      self.ViewDataRolePermissionStatus = false;
      self.ViewDataRolePermissionList = [];
    },

    GetDataPermission() {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        Page: self.page,
        PerPage: self.itemsPerPage
      };
      axios
        .post(`${self.url}Permission/GetDataPermission`, temp)
        .then(function(response) {
          if (response.data.status == 0) {
            self.PermissionList = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.flagLoadingSend = false;
          }
        })
        .catch(function(error) {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;  
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        Page: 1,
        PerPage: value
      };
      axios
        .post(`${self.url}Permission/GetDataPermission`, temp)
        .then(function(response) {
          if (response.data.status == 0) {
            self.PermissionList = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.page = 1;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.flagLoadingSend = false;
          }
        })
        .catch(function(error) {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;  
        });
    },

    ChangePage(value) {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        Page: value,
        PerPage: self.itemsPerPage
      };
      axios
        .post(`${self.url}Permission/GetDataPermission`, temp)
        .then(function(response) {
          if (response.data.status == 0) {
            self.PermissionList = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.flagLoadingSend = false;
          }
        })
        .catch(function(error) {
          self.flagLoadingSend = false;
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },

    SearchPermission() {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        Page: 1,
        PerPage: self.itemsPerPage,
        Search: self.PermissionSearch
      };
      axios
        .post(`${self.url}Permission/GetDataPermission`, temp)
        .then(function(response) {
          if (response.data.status == 0) {
            self.PermissionList = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.page = 1;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.pageCount = 0;
            self.PermissionList = [];
          }
        })
        .catch(function(error) {
          self.flagLoadingSend = false;
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },

    SelectRoleTab(val) {
      let self = this;
      self.PermissionSearch = "";
      self.RoleSearch = "";
      if (val == 1) {
        self.GetDataPermission();
      } else {
        self.GetDataRole();
      }
    },

    GetDataRole() {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        Page: self.pageRole,
        PerPage: self.itemsRolePerPage,
        Search: self.PermissionSearch
      };
      axios
        .post(`${self.url}Role/GetRoleData`, temp)
        .then(function(response) {
          if (response.data.status == 0) {
            self.RoleList = response.data.data.result.listData;
            self.pageRoleCount = response.data.data.result.count;
            self.no_runRole = (self.pageRole - 1) * self.itemsRolePerPage;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.pageRoleCount = 0;
            self.RoleList = [];
          }
        })
        .catch(function(error) {
          self.flagLoadingSend = false;
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },

    ChangePerPageRole(value) {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        Page: 1,
        PerPage: value
      };
      axios
        .post(`${self.url}Role/GetRoleData`, temp)
        .then(function(response) {
          if (response.data.status == 0) {
            self.RoleList = response.data.data.result.listData;
            self.pageRoleCount = response.data.data.result.count;
            self.pageRole = 1;
            self.no_runRole = (self.pageRole - 1) * self.itemsRolePerPage;
            self.flagLoadingSend = false;
          }
        })
        .catch(function(error) {
          self.flagLoadingSend = false;
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },

    ChangePageRole(value) {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        Page: value,
        PerPage: self.itemsRolePerPage
      };
      axios
        .post(`${self.url}Role/GetRoleData`, temp)
        .then(function(response) {
          if (response.data.status == 0) {
            self.RoleList = response.data.data.result.listData;
            self.pageRoleCount = response.data.data.result.count;
            self.no_runRole = (self.pageRole - 1) * self.itemsRolePerPage;
            self.flagLoadingSend = false;
          }
        })
        .catch(function(error) {
          self.flagLoadingSend = false;
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },

    SearchViewDataRolePermission(value){
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        Page: 1,
        PerPage: self.itemsPerPageViewDataRolePermission,
        RoleCode: self.ResourceName,
        Search: value
      };
      axios
        .post(`${self.url}Role/SearchDataRolePermission`, temp)
        .then(function(response) {
          if (response.data.status == 0) {
            self.ViewDataRolePermissionList = response.data.data.result.listData;
            self.pageCountViewDataRolePermission = response.data.data.result.count;
            self.page = 1;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.pageCount = 0;
            self.ViewDataRolePermissionList = [];
          }
        })
        .catch(function(error) {
          self.flagLoadingSend = false;
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },

    SearchRole() {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        Page: 1,
        PerPage: self.itemsRolePerPage,
        Search: self.RoleSearch
      };
      axios
        .post(`${self.url}Role/GetRoleData`, temp)
        .then(function(response) {
          if (response.data.status == 0) {
            self.RoleList = response.data.data.result.listData;
            self.pageRoleCount = response.data.data.result.count;
            self.pageRole = 1;
            self.no_runRole = (self.pageRole - 1) * self.itemsRolePerPage;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.pageRoleCount = 0;
            self.RoleList = [];
          }
        })
        .catch(function(error) {
          self.flagLoadingSend = false;
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },

    AddPermissionList() {
      let self = this;
      self.PermissionPage = false;
      self.AddRoleDialog = true;
      self.addPermissionList = [];
      self.tempPermission = this.PermissionRoleList;
      self.AddRolePermission = this.tempPermission
        .filter(x => x.select)
        .map(x => x.permissionName)
        .join();
      self.tempPermission.forEach(Element => {
        if (Element.select == true) {
          self.addPermissionList.push({
            role_ID: 0,
            permission_ID: Element.permissionID,
            createBy: "string",
            createDate: "2020-12-06T16:00:23.578Z",
            activeFlag: true
          });
        }
      });
    },

    EditPermissionList() {
      let self = this;
      self.StatusEditRole = false;
      self.PermissionPage = false;
      self.EditRoleDialog = true;
      self.editPermissionList = [];
      self.tempPermission = this.PermissionRoleList;
      self.EditRolePermission = this.tempPermission
        .filter(x => x.select)
        .map(x => x.permissionName)
        .join();
      self.tempPermission.forEach(Element => {
        if (Element.select == true) {
          self.editPermissionList.push({
            role_ID: 0,
            permission_ID: Element.permissionID,
            createBy: "string",
            createDate: "2020-12-06T16:00:23.578Z",
            activeFlag: true
          });
        }
      });
    },

    EditRole(item) {
      let self = this;
      self.EditRoleDialog = true;
      self.EditRoleID = item.roleID;
      self.EditRoleName = item.roleName;
      self.EditRoleCode = item.roleCode;
      self.EditRolePermission = item.rolePermission
        .map(x => x.permissionName)
        .join();
      self.editPermissionList = [];
      item.rolePermission.forEach(e => {
        self.editPermissionList.push(e);
      });
    },

    OpenDeleteRoleDialog(value){
      let self = this;
      self.DeleteRoleDialog = true;
      self.RoleID = value;
    },

    DeleteRole(){
      let self = this;
      self.DeleteRoleDialog = false;
      self.flagLoadingSend = true;
      axios
        .post(`${self.url}Role/DeleteRole?roleId=${self.RoleID}`)
        .then(function(response) {
          if (response.data.status == 0) {
            self.flagLoadingSend = false;
            self.DeleteRoleDialog = false;
            // self.mesAlert = "Success";
            // self.typeAlert = "success";
            // self.btnColorAlert = "green darken-4";
            // self.flagAlert = true;
            self.GetDataRole();
            window.location.reload();
          }
          self.flagLoadingSend = false;
        })
        .catch(function(error) {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
          self.DeleteRoleDialog = false;
          self.flagLoadingSend = false;
        });
    },

    async ClickEditAssignPermission() {
      let self = this;
      await self.GetPermissionList();
      self.PermissionPage = true;
      self.editRoleDialog = false;
      self.PermissionRoleList = self.PermissionRoleList.map(v => ({
        ...v,
        select: false
      }));
      for (const item of self.editPermissionList) {
        const index = self.PermissionRoleList.findIndex(
          x => x.permissionID == item.permission_ID
        );
        self.PermissionRoleList[index].select = true;
      }
      self.EditRoleDialog = false;
      self.StatusEditRole = true;
    },

    async ClickAssignPermission() {
      let self = this;
      await self.GetPermissionList();
      self.PermissionPage = true;
      self.AddRoleDialog = false;
    },

    UpdateRole() {
      var self = this;
      if (
        self.EditRoleCode == "" ||
        self.EditRoleName == "" ||
        self.editPermissionList.length == 0
      ) {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณากรอกข้อมูลให้ครบถ้วน";
        self.flagAlertSend = true;
      } else {
        self.EditRoleDialog = false;
        self.flagLoadingSend = true;
        var temp = {
          roleID: self.EditRoleID,
          roleCode: self.EditRoleCode,
          roleName: self.EditRoleName,
          rolePermission: self.editPermissionList,
          updateBy: "string",
          updateDate: "2020-12-07T10:00:18.301Z",
          activeFlag: true
        };
        axios
          .post(`${self.url}Role/UpdateRole`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              self.EditRoleDialog = false;
              self.flagLoadingSend = false;
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = "Success";
              self.flagAlertSend = true;
              self.ClearRole();
              self.GetDataRole();
            }
            self.flagLoadingSend = false;
          })
          .catch(function(error) {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
          });
      }
    },

    AddRole() {
      var self = this;
      // self.flagLoadingSend = true;     
      if (
        self.AddRoleCode == "" ||
        self.AddRoleName == "" ||
        self.addPermissionList.length == 0
      ) {
          self.typeAlertSend = "warning"; //success warning error
          self.mesAlertSend = "กรุณากรอกข้อมูลให้ครบถ้วน !";
          self.flagAlertSend = true;
      } else {        
        this.addRoleDialog = false;
        this.flagLoadingSend = true;
        var temp = {
          roleCode: this.AddRoleCode,
          roleName: this.AddRoleName,
          rolePermission: this.addPermissionList,
          createBy: "string",
          createDate: "2020-12-06T16:00:23.578Z",
          activeFlag: true
        };
        axios
          .post(`${self.url}Role/AddRole`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              self.AddRoleDialog = false;
              self.flagLoadingSend = false;
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = "Success";
              self.flagAlertSend = true;
              self.ClearRole();
              self.GetDataRole();
            }
            self.flagLoadingSend = false;
          })
          .catch(function(error) {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
          });
      }
    },

    OpenAddRoleDialog() {
      let self = this;
      self.AddRoleDialog = true;
    },

    CloseAddRoleDialog() {
      var self = this;
      self.AddRoleDialog = false;
      self.EditRoleDialog = false;
      self.ClearRole();
    },

    CloseEditRoleDialog() {
      var self = this;
      self.AddRoleDialog = false;
      self.EditRoleDialog = false;
      self.ClearRole();
    },

    ClearRole() {
      let self = this;
      self.AddRoleName = null;
      self.AddRoleCode = null;
      self.AddRolePermission = null;
      self.EditRoleName = null;
      self.EditRoleCode = null;
      self.EditRolePermission = null;
    },

    async GetPermissionList() {
      try {
        let self = this;
        self.flagLoadingSend = true;
        await axios
          .get(`${self.url}Permission/GetAllPermissions`)
          .then(function(response) {
            self.PermissionRoleList = response.data.data.map(v => ({
              ...v,
              select: false
            }));
            self.flagLoadingSend = false;
          });
      } catch (error) {
        self.flagLoadingSend = false;
        self.typeAlertSend = "error"; //success warning error
        self.mesAlertSend = error;
        self.flagAlertSend = true;
      }
    },

    PermissionStatus() {
      let self = this;
      if (self.Permission_Status == false) {
        self.Permission_Status = true;
        self.SelectAllPermissionRows();
      } else {
        self.Permission_Status = false;
        self.ClearAllPermissionRows();
      }
    },

    SelectAllPermissionRows() {
      let self = this;
      for (const item of self.PermissionRoleList) {
        item.select = true;
      }
    },

    ClearAllPermissionRows() {
      let self = this;
      for (const item of self.PermissionRoleList) {
        item.select = false;
      }
    },

    HidePermission() {
      let self = this;
      self.PermissionPage = false;
      self.EditRoleDialog = true;
    }
  },

  watch: {},

  mounted() {
    this.GetDataPermission();
    this.tempCompanyID = localStorage.getItem("companyID");
    const elements = document.querySelectorAll(".v-pagination__navigation");
    for (const [index, element] of elements.entries()) {
      if (index % 2 != 0) {
        element.textContent = "Next";
      } else {
        element.textContent = "Previous";
      }
    }
  }
};
</script>
<style scoped>
@media only screen and (min-width: 1030px) and (max-width: 1409px) {
  .per_page {
    margin-left: -5rem !important;
  }
  .v-select > .v-input__control > .v-input__slot {
    width: 80px !important;
  }
}
tbody tr:nth-of-type(even) {
  background-color: #1264960d;
}
.v-text-field--outlined fieldset {
  color: #ebebf2 !important;
}
.v-data-table-header {
  background-color: #126496 !important;
  color: #ffffff !important;
}
.v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-pagination .v-pagination__item--active {
  background: #126496 !important;
  color: white !important;
}
* >>> .v-pagination__item {
  margin: unset !important;
  border-radius: unset !important;
  box-shadow: unset !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
* >>> .v-pagination__navigation {
  margin: unset !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 80px !important;
  height: 34px !important;
  border-radius: unset !important;
  box-shadow: 0px 0px 6px #0000000a !important;
}

* >>> .__pagination .v-pagination__navigation {
  width: 80px !important;
}
.tabone {
  width: 150px;
  font-size: 14px;
  border: solid 1px #eeeef4;
  border-radius: 3px;
  height: 42px;
}
.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #f1f1f1 !important;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 3px;
}
</style>
<style lang="scss">
.__pagination {
  .v-pagination__navigation {
    box-shadow: none !important;
    border: 1px solid #0000001a;
    width: auto !important;
    padding-left: 10px;
    padding-right: 10px;
    &:focus {
      outline: none;
    }
  }
}
</style>