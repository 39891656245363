<template>
    <div>
      <v-card>
        <div style="margin-left: 10px; margin-right: 10px">
          <div
            class="pt-8"
            style="
              font-size: 20px;
              margin-left: 1rem;
              margin-right: 1rem;
              color: #444444;
            "
          >Sales Management</div>
          <v-card-text> 
            <v-row class="mt-5">
              <v-col cols="12" md="4" class="d-flex">  
                <v-btn
                  width="180px"
                  color="#126496 "
                  class="white--text flex-sm-grow-0 flex-grow-1"
                  router
                  @click="AddMasterSales()"
                >Add</v-btn>
              </v-col>
              <v-col cols="12" md="4"></v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :class="[$vuetify.breakpoint.xlOnly ? 'ml-5' : '']"
                  label="Search"
                  v-model="search"
                  outlined
                  dense
                  hide-details="auto"
                  v-on:keyup.enter="SearchFilter()"
                >
                <template v-slot:prepend-inner>
                  <v-icon color="primary">mdi-magnify</v-icon>
                </template>
                </v-text-field>
              </v-col>
            </v-row>
            <br />
            <div>
              <v-data-table
                :headers="headers"
                :items="ListData"
                hide-default-footer
                style="color: #444444"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td style="text-align: center">{{ no_run + (index + 1) }}</td>
                    <td style="text-align: center">{{ item.firstName }}</td>
                    <td style="text-align: center">{{ item.lastName }}</td>
                    <td style="text-align: center">{{ item.eMail }}</td>
                    <td>
                      <center>
                          <div
                              :style="{'background-color': getColor(item.activeFlag)}"
                              style="width: 150px;border-radius: 16px;font-size:14px;height:32px;margin-right:auto;margin-left:auto"
                          >
                              <p style="padding-top:5px" :style="{ color: getColorText(item.activeFlag) }">{{
                              item.activeFlag == true
                                  ? "ACTIVE"
                                  : "INACTIVE"
                              }}</p>
                          </div>
                      </center>
                    </td>
                    <td style="text-align: center;">
                      <v-menu transition="slide-y-transition" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="#126496"
                            style="text-transform: none !important"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </template>
                        <v-list v-if="item.activeFlag == false">
                          <v-list-item
                            v-for="(temp, i) in itemsActionInActive"
                            :key="i"
                            @click="SelectAction(temp.title, item)"
                          >
                            <v-list-item-title>{{ temp.title }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                        <v-list v-else>
                          <v-list-item
                            v-for="(temp, i) in itemsAction"
                            :key="i"
                            @click="SelectAction(temp.title, item)"
                          >
                            <v-list-item-title>{{ temp.title }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <br />
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="4">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    style="border: 1px solid #EBEBF2"
                      v-bind="attrs"
                      v-on="on"
                      class="text-capitalize mt-1"
                      outlined
                    >
                      {{ itemsPerPage }}
                      <v-icon color="primary">mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in paginationCount"
                      :key="index"
                      @click="
                        itemsPerPage = item.title;
                        ChangePerPage(itemsPerPage);
                      "
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" sm="12">
                <v-pagination
                  v-model="page"
                  :total-visible="7"
                  :length="pageCount"
                  @input="ChangePage(page)"
                ></v-pagination>
              </v-col>
              <v-col cols="12" md="4" sm="12"></v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-card>
  
      <Loading
        :flagLoading="flagLoadingSend"
      />
      
      <Alert
        :flagAlert="flagAlertSend"
        :typeAlert="typeAlertSend"
        :mesAlert="mesAlertSend"
        @flagClose="ClearDataAlert()"
      />
  
      <v-dialog v-model="AddDialog" persistent width="800">
        <v-card>
          <v-card-title>
            <p>Add Sales Management</p>
            <v-spacer></v-spacer>
            <v-btn style="margin-top: -10px" @click="CloseAddDialog" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <hr class="ml-5 mr-4" />
          <v-row class="ml-5 mr-2" style="margin-top: 20px;">
            <v-col cols="12" md="12">
              <p class="mytitle">First name *</p>
              <span v-if="v_FName" class="validation-class">Please fill First Name.</span>
              <v-text-field style="width: 450px" solo v-model="First_Name"></v-text-field>      
            </v-col>
          </v-row>
          <v-row class="ml-5 mr-2" style="margin-top: -30px;">
            <v-col cols="12" md="12">
              <p class="mytitle">Last name *</p>
              <span v-if="v_LName" class="validation-class">Please fill Last Name.</span>
              <v-text-field style="width: 450px" solo v-model="Last_Name"></v-text-field>      
            </v-col>
          </v-row> 
          <v-row class="ml-5 mr-2" style="margin-top: -30px;">
            <v-col md="12" col="6">
              <p class="mytitle">E-Mail</p>
              <v-text-field style="width: 450px;" solo v-model="E_mail"></v-text-field>          
            </v-col>
          </v-row>
          <v-card-actions style="margin-right: 3%; margin-left: 3%" class="d-flex flex-row-reverse flex-wrap justify-start pb-6 ">
            <v-btn
              color="#1976d2"
              class="mx-2 white--text text-capitalize flex-sm-grow-0 flex-grow-1 "
              width="120"
              max-height="35"
              @click="AddDataMasterSales()"
            >Add</v-btn> 
            <v-btn
              class="mx-2 text-capitalize flex-sm-grow-0 flex-grow-1 "
              width="120"
              max-height="35"
              @click="CloseAddDialog"
              text
            >Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <v-dialog v-model="EditDialog" persistent width="800">
        <v-card>
          <v-card-title>
            <p>Edit Master Sales</p>
            <v-spacer></v-spacer>
            <v-btn style="margin-top: -10px" @click="CloseEditDialog" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <hr class="ml-5 mr-4" />
          <v-row class="ml-5 mr-2 mt-3">
            <v-col cols="12" md="12">
              <p class="mytitle">First Name *</p>
              <v-text-field style="width: 450px" solo v-model="First_Name"></v-text-field>
              <span v-if="v_FName" class="validation-class">Please fill First Name.</span>
            </v-col>
          </v-row>
          <v-row class="ml-5 mr-2 mt-3">
            <v-col cols="12" md="12">
              <p class="mytitle">Last Name *</p>
              <v-text-field style="width: 450px" solo v-model="Last_Name"></v-text-field>
              <span v-if="v_LName" class="validation-class">Please fill Lastt Name.</span>
            </v-col>
          </v-row>
          <v-row class="ml-5 mr-2 mt-3">
            <v-col cols="12" md="12">
              <p class="mytitle">E-Mail</p>
              <v-text-field style="width: 450px" solo v-model="E_mail"></v-text-field>
            </v-col>
          </v-row>
          <br />
          <v-card-actions style="margin-right: 3%; margin-left: 3%" class="d-flex flex-row-reverse flex-wrap justify-start pb-6"> 
            <v-btn
              class="mr-2 text-capitalize flex-sm-grow-0 flex-grow-1"
              width="120"
              max-height="35"
              @click="CloseEditDialog"
              text
            >Cancel</v-btn>
            <v-btn
              color="#1976d2"
              class="mr-2 white--text text-capitalize flex-sm-grow-0 flex-grow-1"
              width="120"
              max-height="35"
              @click="EditDataMasterSales()"
            >Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="DeleteSale" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title> 
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 20px">
           <span><v-icon color="red" large>mdi-alert-circle-outline</v-icon></span> Delete Sale
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  Are you sure you want to Delete ?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            @click="DeleteSale = false"
            text
            >Cancel</v-btn
          >
          <v-btn
            color="#FF2727"
            class="mr-2 white--text text-capitalize"
            width="120"
            @click="DeleteSaleByID()"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
  </template>
  <script>
  import axios from "axios";
  import store from "../store";
  import enurl from "@/api/environment";
  import Alert from '@/components/Alert';
  import Loading from "@/components/Loading";
  
  export default {
    components: {
      Alert,
      Loading,
    },
    props: {},
    data() {
      return {
        flagAlertSend: false,
        flagLoadingSend: false,
        mesAlertSend: "",
        typeAlertSend: "success",
        url: enurl.apiUrl,
        permissionid: store.state.permissionid,
        no_run: null,
        search: "",
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        items: [10, 20, 30, 40],
        paginationCount: [
          { title: 10 },
          { title: 20 },
          { title: 30 },
          { title: 40 },
        ],
        ListData: [],
        loadingdialog: false,
        AddDialog: false,
        EditDialog: false,
        First_Name: "",
        Last_Name: "",
        E_mail: "",
        v_FName: false,
        v_LName: false,
        itemsAction: [{ title: "Edit" }, { title: "Delete" }],
        itemsActionInActive: [{ title: "Edit" }, { title: "Delete" }],
        DeleteSale: false,
        activeFlag: false,
      };
    },
    computed: {
      headers() {
        return [
          {
            text: "#",
            align: "center",
            // value: "no",
            sortable: false
          },
          {
            text: "Firstname",
            // value: "Firstname",
            align: "center",
            sortable: false
          },
          {
            text: "Lastname",
            align: "center",
            sortable: false
            // value: "lastname",
          },
          {
            text: "E-mail",
            // value: "Email",
            align: "center",
            sortable: false
          },{
            text: "Status",
            // value: "activeFlag",
            align: "center",
            sortable: false
          },
          {
            text: "Action",
            // value: "action",
            align: "center",
            sortable: false
          }
        ];
      }
    },
  
    watch: {
      First_Name: function (val) {
        if (val != "" || val != null) {
          this.v_FName = false;
        } else {
          this.v_FName = true;
        }
      },
      Last_Name: function (val) {
        if (val != "" || val != null) {
          this.v_LName = false;
        } else {
          this.v_LName = true;
        }
      },
    },
  
    mounted() {
      this.GetSalesList();
    },
  
    methods: {
      getColor(e) {
        if (e == true) {
          return "rgb(92, 184, 92,0.3)";
        } else {
          return "rgb(232, 34, 34,0.3)";
        }
      },
      getColorText(e) {
        if (e == true) {
          return "rgb(92, 184, 92)";
        } else {
          return "rgb(232, 34, 34)";
        }
      },
      ClearDataAlert(){
        let self = this;
        self.flagAlertSend = false;
        self.typeAlertSend = "success"; //success warning error
        self.mesAlertSend = "";
      },
      validateNumber: event => {
        let keyCode = event.keyCode;
        if (keyCode < 48 || keyCode > 57) {
          event.preventDefault();
        }
      },
      validateEmail(email) {
        // eslint-disable-next-line no-useless-escape
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email) || "invalid";
      },
      AddMasterSales() {
        let self = this;
        self.AddDialog = true;
      },
  
      CloseAddDialog() {
        let self = this;
        self.ClearData();
        self.AddDialog = false;
      },
  
      ClearData() {
        let self = this;
        self.First_Name = null;
        self.Last_Name = null;
        self.E_mail = null;
        self.AddDialog = false;
        self.EditDialog = false;
      },
  
      CloseEditDialog() {
        let self = this;
        self.ClearData();
        self.EditDialog = false;
      },
      SelectAction(temp, id) {
        let self = this;
        if (temp == "Edit") {
          self.id = id.id;
          self.loadingdialog = true;
          let TempData = {
            ID: self.id
          };
          axios
            .post(
              `${self.url}Sales/GetDataMasterSalesForEdit`,
              TempData
            )
            .then(function(response) {
              if (response.data.status == 0) {
                var data = response.data.data;
                if (data.length > 0) {
                  self.First_Name = data[0].firstname;
                  self.Last_Name = data[0].lastname;
                  self.E_mail = data[0].email;
                  self.EditDialog = true;
                  self.loadingdialog = false;
                }
              }
            })
            .catch(error => {
              self.typeAlertSend = "error"; //success warning error
              self.mesAlertSend = error.response;
              self.flagAlertSend = true;
              self.loadingdialog = false;
            });
        } else {
          self.id = id.id;
          self.activeFlag = id.activeFlag;
          self.DeleteSale = true;
        }
      },
      DeleteSaleByID(){
        let self = this;
          self.loadingdialog = true;
          let Temp = {
            ID: self.id,
            Status: self.activeFlag
          };
          axios
            .post(`${self.url}Sales/ActiveStatusSales`, Temp)
            .then(function(response) {
              if (response.data.status == 0) {
                self.DeleteSale = false;  
                self.loadingdialog = false;
                window.location.reload();
              }
            })
            .catch(error => {
              self.typeAlertSend = "error"; //success warning error
              self.mesAlertSend = error.response;
              self.flagAlertSend = true;
              self.loadingdialog = false;
            });
      },
      GetDataMasterSalesForEdit() {
        let self = this;
        self.loadingdialog = true;
        let TempData = {
          ID: self.id
        };
        axios
          .post(
            `${self.url}Sales/GetDataMasterSalesForEdit`,
            TempData
          )
          .then(function(response) {
            if (response.data.status == 0) {
              self.loadingdialog = false;
            }
          })
          .catch(error => {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error.response;
            self.flagAlertSend = true;
            self.loadingdialog = false;
          });
      },
  
      AddDataMasterSales() {
        let self = this;
        
        if (self.First_Name == "" || self.Last_Name == "") {
          self.typeAlertSend = "warning"; //success warning error
          self.mesAlertSend = "กรุณากรอกข้อมูลให้ครบถ้วน !";
          self.flagAlertSend = true;
          if (self.First_Name == "") {
            self.v_FName = true;
          } else {
            self.v_FName = false;
          }
          if (self.Last_Name == "") {
            self.v_LName = true;
          } else {
            self.v_LName = false;
          }
        } else if (self.E_mail == null || self.E_mail == "" || self.validateEmail(self.E_mail)  == "invalid"){
          self.typeAlertSend = "warning"; //success warning error
          self.mesAlertSend = "กรุณากรอก E-Mail ให้ถูกต้อง !";
          self.flagAlertSend = true;
        }
        else {
          self.loadingdialog = true;
          let Temp = {
            FirstName: self.First_Name,
            LastName: self.Last_Name,
            Email: self.E_mail 
          }; 
          axios
            .post(`${self.url}Sales/AddDataMasterSales`, Temp)
            .then(function(response) {
              if (response.data.status == 0) { 
                self.typeAlertSend = "success"; //success warning error
                self.mesAlertSend = "Success";
                self.flagAlertSend = true;       
                self.AddDialog = false;
                self.loadingdialog = false;
                self.ClearData();
                self.GetSalesList();             
              }
            })
            .catch(error => {
              self.typeAlertSend = "error"; //success warning error
              self.mesAlertSend = error.response;
              self.flagAlertSend = true;
              self.loadingdialog = false;
            });
        }
      },
  
      EditDataMasterSales() {
        let self = this;
        if (self.First_Name == "" || self.Last_Name == "") {
          self.typeAlertSend = "warning"; //success warning error
          self.mesAlertSend = "กรุณากรอกข้อมูลให้ครบถ้วน !";
          self.flagAlertSend = true;
          if (self.First_Name == "") {
            self.v_FName = true;
          } else {
            self.v_FName = false;
          }
          if (self.Last_Name == "") {
            self.v_LName = true;
          } else {
            self.v_LName = false;
          }
        } else if (self.E_mail == null || (self.E_mail == "" || self.validateEmail(self.E_mail) == "invalid")){
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = "กรุณากรอก E-Mail ให้ถูกต้อง !";
            self.flagAlertSend = true;
        }
        else {
          self.loadingdialog = true;
          let Temp = {
            ID: self.id,
            Firstname: self.First_Name,
            Lastname: self.Last_Name,
            Email: self.E_mail,
          };
          axios
            .post(`${self.url}Sales/EditDataMasterSales`, Temp)
            .then(function(response) {
              if (response.data.status == 0) {
                self.typeAlertSend = "success"; //success warning error
                self.mesAlertSend = "Success";
                self.flagAlertSend = true;
                self.loadingdialog = false;
                self.AddDialog = false;
                self.ClearData();
                self.GetSalesList();
              }
            })
            .catch(error => {
              self.typeAlertSend = "error"; //success warning error
              self.mesAlertSend = error.response;
              self.flagAlertSend = true;
              self.loadingdialog = false;
            });
        }
      },
  
      GetSalesList() {
        let self = this;
        self.loadingdialog = true;
        let TempData = {
          Page: self.page,
          Perpage: self.itemsPerPage, 
          Search: self.search,
          Filter: {workStatus: null, StartDate: null, EndDate: null}
        };
        axios
          .post(`${self.url}Sales/GetSalesList`, TempData)
          .then(function(response) {
            if (response.data.status == 0) {
              self.ListData = response.data.data.listData;
              self.pageCount = response.data.data.count;
              self.no_run = (self.page - 1) * self.itemsPerPage;
              self.loadingdialog = false;
            } else {
              self.loadingdialog = false;
              self.ListData = [];
              self.pageCount = 0;
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = "ไม่พบข้อมูล";
              self.flagAlertSend = true;
            }
          })
          .catch(error => {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error.response;
            self.flagAlertSend = true;
            self.loadingdialog = false;
          });
      },
      SearchFilter() {
      let self = this;
      
      if((new Date(self.date1) <= new Date(self.date2) || (self.date1 == null && self.date2 == null)) && (new Date(self.startExpiredDate) <= new Date(self.endExpiredDate) || (self.startExpiredDate == null && self.date2 == null)))
      {        
        self.flagLoadingSend = true;
        let temp = {
          Page: 1,
          Perpage: self.itemsPerPage,
          Search: self.search,
          Filter: self.FlagSearch == true ? self.Filter : {sale: null, workStatus: null, StartDate: null, EndDate: null, StartExpiredDate: null, EndExpiredDate: null}
        };

        self.searchTemp = temp;

        axios
        .post(`${self.url}Sales/GetSalesList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ListData = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.page = 1;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.pageCount = 0;
            self.ListData = [];
          }
        })
        .catch(function (error) {
            self.flagLoadingSend = false;
            alert(error);
        });
      }
      else
      {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณาเลือก StartDate และ EndDate ให้ถูกต้อง";
        self.flagAlertSend = true;
      }
    },
      ChangePerPage: function(value) {
        let self = this;
        self.loadingdialog = true;
        let TempData = {
          Page: 1,
          PerPage: value
        };
        axios
          .post(`${self.url}Sales/GetSalesList`, TempData)
          .then(function(response) {
            if (response.data.status == 0) {
              self.ListData = response.data.data.listData;
              self.pageCount = response.data.data.count;
              self.page = 1;
              self.no_run = (self.page - 1) * self.itemsPerPage;
              self.loadingdialog = false;
            } else {
              self.loadingdialog = false;
              self.ListData = [];
              self.pageCount = 0;
              self.page = 1;
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = "ไม่พบข้อมูล";
              self.flagAlertSend = true;
            }
          })
          .catch(error => {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error.response;
            self.flagAlertSend = true;
            self.loadingdialog = false;
          });
      },
  
      ChangePage: function(value) {
        let self = this;
        self.loadingdialog = true;
        let TempData = {
          Page: value,
          PerPage: self.itemsPerPage
        };
        axios
          .post(`${self.url}Sales/GetSalesList`, TempData)
          .then(function(response) {
            if (response.data.status == 0) {
              self.no_run = (self.page - 1) * self.itemsPerPage;
              self.ListData = response.data.data.listData;
              self.pageCount = response.data.data.count;
              self.loadingdialog = false;
            } else {
              self.loadingdialog = false;
              self.ListData = [];
              self.pageCount = 0;
              self.page = 1;
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = "ไม่พบข้อมูล";
              self.flagAlertSend = true;
            }
          })
          .catch(error => {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error.response;
            self.flagAlertSend = true;
            self.loadingdialog = false;
          });
      }
    }
  };
  </script>
  <style scoped>
  @media only screen and (min-width: 1030px) and (max-width: 1409px) {
    .per_page {
      margin-left: -5rem !important;
    }
    * >>> .v-select > .v-input__control > .v-input__slot {
      width: 80px !important;
    }
  }
  * >>> tbody tr:nth-of-type(even) {
    background-color: #1264960d;
  }
  .v-text-field--outlined fieldset {
    color: #ebebf2 !important;
  }
  * >>> .v-data-table-header {
    background-color: #126496 !important;
    color: #ffffff !important;
  }
  * >>> .v-data-table-header th {
    font-size: 14px !important;
    color: #ffffff !important;
  }
  * >>> .theme--light.v-pagination .v-pagination__item {
    background: #fff !important;
    color: rgba(0, 0, 0, 0.87);
  }
  * >>> .theme--light.v-pagination .v-pagination__item--active {
    background: #126496 !important;
    color: white !important;
  }
  .mytitle {
    font-size: 14px;
    font-weight: 300;
  }
  .input-border {
    border: 1px solid black;
    height: 35px;
    padding: 4px;
  }
  .validation-class {
    color: red;
    font-size: 12px;
  }
  </style>
  <style lang="scss">
  .__pagination {
    .v-pagination__navigation {
      box-shadow: none !important;
      border: 1px solid #0000001a;
      width: auto !important;
      padding-left: 10px;
      padding-right: 10px;
      &:focus {
        outline: none;
      }
    }
  }
  </style>
  