<template>
  <div>
    <v-img
      src="@/assets/images/login_bg.png"
      width="100%"
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh' }
          : { height: '100vh'},
      ]"
    >
      <div>
        <v-container>
          <v-row style="min-height: 100vh" justify="center" align="center">
            <v-card
              width="516"
              :class="$vuetify.breakpoint.xs ? 'rounded-0' : 'rounded-lg'"
            >
              <v-card-text style="width: auto">
                <v-alert type="error" v-model="erroralert">{{
                  errorMessage
                }}</v-alert>
                <v-alert type="info" v-model="successalert">{{
                  successMessage
                }}</v-alert>
                <v-tabs class="d-none" v-model="model"> </v-tabs>

                <v-tabs-items v-model="model">
                  <!-- login -->
                  <v-tab-item :value="`tab-1`">
                    <!-- <br> -->
                    <v-img
                      src="@/assets/VLogo.png"
                      width="200"
                      class="mx-auto"
                    ></v-img>
                    <p align="center" style="color: #05C5FE">Product Admin</p>
                    <h2 class="mb-3">เข้าสู่ระบบ</h2>
                    <label for="username" style="font-size: 16px">อีเมลหรือชื่อผู้ใช้</label> 
                    <v-text-field
                      id="username"
                      name="login"
                      type="text"
                      outlined
                      v-model="input.username"
                      style="margin-bottom: -15px"
                    />
                    <label for="psw" style="font-size: 16px">รหัสผ่าน</label> 
                    <v-text-field
                      id="psw"
                      type="password"
                      outlined
                      @keyup.enter="login"
                      v-model="input.password"
                      style="margin-bottom: -15px"
                    />
                    <v-btn
                      :loading="loading"
                      :disabled="loading"
                      block
                      color="#126496"
                      style="color: white"
                      class="mt-3 rounded-lg"
                      x-large
                      @click="login"
                      >เข้าสู่ระบบ</v-btn
                    >
                    <br />
                    <a class="mt-5" style="color: #126496;font-size: 18px;" @click="model = 'tab-5'"
                      >หากลืมรหัสผ่าน</a
                    >                 
                  </v-tab-item>

                  <!-- forgotPassword -->
                  <v-tab-item :value="`tab-5`">
                    <v-alert type="error" v-show="errorMessage != ''">{{errorMessage}}</v-alert>
                    <div class="d-flex">
                      <h2>ลืมรหัสผ่าน</h2>
                      <v-btn
                        v-show="!flagForgot"
                        text
                        class="ml-auto"
                        color="primary"
                        @click="model = 'tab-1'"
                      >
                        <v-icon>mdi-chevron-left</v-icon>
                        <span>กลับไปหน้าเข้าสู่ระบบ</span>
                      </v-btn>
                    </div>
                    <div v-if="!flagForgot">
                      <!-- <p class="mt-5">
                        กรุณาป้อนอีเมลเพื่อกู้คืนรหัสผ่านของคุณ คุณจะได้รับอีเมลพร้อมคำแนะนำ หากคุณ ประสบปัญหาในการกู้คืนรหัสผ่านของคุณกรุณาติดต่อ <a color="primary">Support</a>
                      </p> -->
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12">
                          <v-text-field
                            v-model="Email"
                            hide-details="auto"
                            label="อีเมล"
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-btn
                            block
                            :loading="loading"
                            :disabled="loading"
                            color="primary"
                            x-large
                            class="rounded-lg"
                            @click="forgot()"
                            >ดำเนินการ</v-btn
                          >
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <br />
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12">
                          <v-btn
                            block
                            disabled
                            large
                            class="rounded-lg"
                            style="background-color:#12649640 !important"
                          >
                            <span style="color:#126496"><img width="20" src="../assets/images/correct.png"> ดำเนินการเรียบร้อย</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12">
                          <v-btn
                            block
                            color="primary"
                            x-large
                            class="rounded-lg"
                            @click="model = 'tab-1', flagForgot = false"
                            >กลับไปที่หน้าเข้าสู่ระบบ</v-btn
                          >
                        </v-col>
                      </v-row>
                    </div>
                    <br />
                    <!-- <p class="mt-5">ยังไม่มีบัญชีผู้ใช้ ? <a color="primary" @click="model = 'tab-2'">สร้างบัญชีใหม่</a></p> -->
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-row>
        </v-container>
      </div>
    </v-img>
    <v-footer
      color="#126496"
      :absolute="$vuetify.breakpoint.height < 786 ? false : true"
      class="white--text text-caption hidden-md-and-down"
    >
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="10" md="10">
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="4" md="4" lg="2">
              <v-img
                src="@/assets/Group 7922.png"
                width="180"
                class="mx-auto"
              ></v-img>
            </v-col>
            <v-col>
              <div style="font-size: 14px"
                >VERACITY </div
              >
              <span style="font-size: 14px">77/148 SINSATHORN TOWER ถ. กรุงธนบุรี แขวง คลองต้นไทร
                เขตคลองสาน กรุงเทพมหานคร 10600</span>
              <div class="d-flex">
                <span style="text-decoration: underline;font-size: 14px">Terms Of Use</span>
                <v-divider vertical inset class="mx-3" dark></v-divider>
                <span style="text-decoration: underline;font-size: 14px">Privacy Policy</span>
                <v-divider vertical inset class="mx-3" dark></v-divider>
                <span style="text-decoration: underline;font-size: 14px">Cookies Policy</span>
                <v-divider vertical inset class="mx-3" dark></v-divider>
                <span style="text-decoration: underline;font-size: 14px">Privacy Notice</span>
                <v-divider vertical inset class="mx-3" dark></v-divider>
              </div>
            </v-col>
            <v-col>
              <div style="font-size: 14px">For more Information</div>
              <div style="font-size: 14px">TEL : 02 440 0408</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xs="12" sm="2" md="2">
          <!-- <v-btn
            icon
            color="white"
            class="float-right"
            style="margin-top: 0.5rem"
          >
            <v-icon>mdi-arrow-up-circle-outline</v-icon>
          </v-btn> -->
        </v-col>
      </v-row>
    </v-footer>
    
    <Loading
      :flagLoading="flagLoadingSend"
    />
    
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/api/function.js";
import Loading from "@/components/Loading";

export default {
  mixins: [LogTrace],
  name: "Login",
  components: {
    Loading,
  },
  data() {
    return {
      // expiryDate: "",
      // rememberMe: false,
      // url: enurl.apiUrl,
      // flagLoadingSend: false,
      // loading: false,
      // forgotpassword: false,
      // erroralert: false,
      // successalert: false,
      // successMessage: "",
      // errorMessage: "",
      // show1: false,
      // remember: "",
      // secretKey: "SecretPassphrase@2020",
      // permissionID: [],
      // input: {
      //   username: "",
      //   password: ""
      // },
      // Email: "",
      // images: {
      //   logo: require("@/assets/VLogo.png"),
      //   BG: require("@/assets/orange2.jpg")
      // }
      dialogAlert: false,
      textAlert: "",
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      username: "",
      password: "",
      confirmPassword: "",
      pdpa: false,
      model: "tab-1",
      expiryDate: "",
      rememberMe: false,
      emailforgot: "",
      url: enurl.apiUrl,
      flagLoadingSend: false,
      loading: false,
      forgotpassword: false,
      erroralert: false,
      successalert: false,
      successMessage: "",
      errorMessage: "",
      show1: false,
      remember: "",
      secretKey: "SecretPassphrase@2020",
      permissionID: [],
      input: {
        company_code: "",
        username: "",
        password: "",
      },
      Email: "",
      images: {
        logo: require("@/assets/VLogo.png"),
        BG: require("@/assets/orange2.jpg"),
        correct: require("@/assets/images/correct.png"),
      },
      flagHaveUser: false,
      flagForgot: false,
    };
  },
  mounted: function() {
    var self = this;

    this.DeleteCookie();
    // Clear cookie 
    // document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; 
    // document.cookie = "rememberme=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; 
    // document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; 

    self.remember = self.readCookie("rememberme");
    if (self.remember == "true") {
      let token = localStorage.getItem("token");
      self.$store
        .dispatch("checktoken", { token })
        .then(function(response) {
          console.log(response);
          if (response.data.message != "Invalid Token!") {
            var data = response.data.data;
            localStorage.setItem("token", response.data.data.token);

            if (data.userType == "COM") {
              setTimeout(() => {
                self.$router.push("/CompanyManagement");
              }, 1000);
              // document.cookie =
              //   "username=" +
              //   self.cryptoMethods("encrypt", data.username) +
              //   ";expires=" +
              //   self.expiryDate;
              // document.cookie =
              //   "password=" +
              //   self.cryptoMethods("encrypt", password) +
              //   ";expires=" +
              //   self.expiryDate;
              // let tempRem =
              //   self.remember == "true" ? self.remember : self.rememberMe;
              // document.cookie =
              //   "rememberme=" + tempRem + ";expires=" + self.expiryDate;
            }
          }
        })
        .catch(function(error) {
          console.log(error);
          // self.erroralert = true;
          // self.errorMessage = error;
          // self.loading = false;
        });
    }

    // let login_username = this.readCookie("username");
    // let login_password = this.readCookie("password");
    // this.remember = this.readCookie("rememberme");
    // if (this.remember == "true") {
    //   this.expiryDate = this.readCookie("expiredate");
    //   let today = new Date();
    //   if (today >= this.expiryDate) {
    //     document.cookie = "username=" + ";expires=" + self.expiryDate;
    //     document.cookie = "password=" + ";expires=" + self.expiryDate;
    //     document.cookie = "rememberme=" + ";expires=" + self.expiryDate;
    //   } else {
    //     if (login_username != null && login_password != null) {
    //       this.input.username = this.cryptoMethods("decrypt", login_username);
    //       this.input.password = this.cryptoMethods("decrypt", login_password);
    //       // this.login();
    //     }
    //   }
    // } else {
    //   this.expiryDate = new Date();
    //   this.expiryDate.setMonth(this.expiryDate.getMonth() + 1);
    //   document.cookie = "expiredate=" + this.expiryDate;
    // }
  },
  methods: {
    async DeleteCookie()
    {
      let self = this;
      await axios
        .post(`${self.url}Login/Logout`)
        .then(function (response) {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("usertype");
            sessionStorage.removeItem("username");
            sessionStorage.removeItem("permissionid");
            sessionStorage.removeItem("firstName");
            sessionStorage.removeItem("lastName");
            sessionStorage.removeItem("email");
            sessionStorage.removeItem("mobile");
            sessionStorage.removeItem("pathImage");
            sessionStorage.removeItem("employeeID");
            delete axios.defaults.headers.common["Authorization"];
            console.log(response.data); 
        })
        .catch(function (error) {
          console.log(error);
        }); 
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i];
          const eqPos = cookie.indexOf("=");
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
    forgot() {
      try {
        let self = this;
        self.flagLoadingSend = true;
        self.loading = true;
        let tempEmail = {
          email: self.Email
        };
        axios
          .post(`${self.url}Login/ForgotPassword`, tempEmail)
          .then(function(response) {
            alert(response.data.message);
            self.loading = false;
            self.Email = "";
            self.flagLoadingSend = false;
          })
          .catch(function(error) {
            alert(error);
            self.loading = false;
            self.flagLoadingSend = false;
          });
      } catch (error) {
        this.LogTrace(error, "forgotPassword");
      }
    },
    login: function() {
      let self = this;

      self.clearMessage();
      self.loading = true;
      let userName = self.input.username.toLowerCase();
      let password = self.input.password;

      self.$store
        .dispatch("login", { userName, password })
        .then(function(response) {
          self.successalert = true;
          self.successMessage = response.data.message;
          self.loading = false;
          self.model = { username: "", password: "" };
          const usertype = response.data.data.userType.trim();
          self.permissionID = response.data.data.permissionId;
          localStorage.setItem("storeTemp", self.permissionID);
          localStorage.setItem("token", response.data.data.token);

          if (usertype == "COM") {
            setTimeout(() => {
              // let MenuIds = sessionStorage.getItem("MenuId").split(",");
              // const first_MenuID = MenuIds[0];
              // let route = "usermanagement";
              // if (first_MenuID == 11) {
              //   route = "usermanagement";
              // } else if (first_MenuID == 22) {
              //   route = "usergroup";
              // } else if (first_MenuID == 33) {
              //   route = "permission";
              // } else if (first_MenuID == 1) {
              //   route = "menumanagement";
              // } else if (first_MenuID == 2) {
              //   route = "calendar";
              // } else if (first_MenuID == 3) {
              //   route = "log";
              // }
              self.$router.push("/CompanyManagement");
            }, 1000);
            // document.cookie =
            //   "username=" +
            //   self.cryptoMethods("encrypt", userName) +
            //   ";expires=" +
            //   self.expiryDate;
            // document.cookie =
            //   "password=" +
            //   self.cryptoMethods("encrypt", password) +
            //   ";expires=" +
            //   self.expiryDate;
            // let tempRem =
            //   self.remember == "true" ? self.remember : self.rememberMe;
            // document.cookie =
            //   "rememberme=" + tempRem + ";expires=" + self.expiryDate;
          }
          self.flagLoadingSend = false;
          self.loading = false;
        })
        .catch(function(error) {
          self.erroralert = true;
          self.errorMessage = error;
          self.loading = false;
        });
    },
    clearMessage: function() {
      this.erroralert = false;
      this.successalert = false;
      this.errorMessage = "";
      this.successMessage = "";
    },
    readCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    cryptoMethods(type, value) {
      let self = this;
      if (value != null || value != "" || value != undefined) {
        if (type == "encrypt") {
          const encryptedText = this.CryptoJS.AES.encrypt(
            value,
            self.secretKey
          ).toString();
          return encryptedText;
        } else {
          const decryptedText = this.CryptoJS.AES.decrypt(
            value,
            self.secretKey
          ).toString(this.CryptoJS.enc.Utf8);
          return decryptedText;
        }
      }
    }
  }
};
</script>
<style scoped>
::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot 
{
  min-height : 40px !important;
}
.forgotPassword {
  margin-top: 1.3rem;
}

.forgot {
  width: 10rem;
}

.login {
  width: 14rem;
}

.form {
  min-width: 50%;
}

.img-intersection {
  width: 100%;
  height: 100vh;
  max-width: 100%;
}

@media only screen and (max-width: 959px) {
  .logocss {
    display: none;
  }
  .rescss {
    margin: 1rem;
    width: 100% !important;
  }
}
</style>
