import Vue from 'vue'
import Router from 'vue-router';
import Login from './views/Login'
import UserManagement from './views/UserManagement'
import UserProfile from './views/UserProfile'
import MenuManagement from './views/MenuManagement'
import Calendar from './views/Calendar'
import Permission from './views/Permission'
import Log from './views/Log'
import Setting from './views/Setting'
import UserGroup from './views/UserGroup'
// import UploadPDF from './views/UploadPDF'
// import PdfPreview from './views/PdfPreview'
// import CertList from './views/CertList'
import CompanyManagement from './views/CompanyManagement'
import HistoryLog from './views/HistoryLog'
import ManualSync from './views/ManualSync'
// import SyncManagement from './views/SyncManagement'
import CompareAD from './views/CompareAD'
import ConfigScheduler from './views/ConfigScheduler'
import UserAttribute from './views/UserAttribute'
import TaskStatus from './views/TaskStatus'
import ReportManagement from './views/ReportManagement'
// import NotificationConfig from './views/NotificationConfig'
import Report1 from './views/Report1'
import Report2 from './views/Report2'
import Report3 from './views/Report3'
import Report4 from './views/Report4'
import Report5 from './views/Report5'
import Report6 from './views/Report6'
import Report7 from './views/Report7'
import ApproveManagement from './views/ApproveManagement'
import AddCompany from './views/AddCompany'
import EditCompany from './views/EditCompany'
import MasterProductPlan from './views/MasterProductPlan'
import RoleManagement from './views/RoleManagement'
import SubscriptionManagement from './views/SubscriptionManagement'
import ManageCustomerSubscription from './views/ManageCustomerSubscription'
import TemplateContract from './views/TemplateContract'
import AddTemplateContract from './views/AddTemplateContract'
import SalesManagement from './views/SalesManagement'
import UserManual from './views/UserManual'
import MailBoardcast from "./views/MailBoardcast";

import store from "./store"
Vue.use(Router)
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: {
        name: "Login",
      },
    },

    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/companymanagement",
      name: "CompanyManagement",
      component: CompanyManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/MasterProductPlan",
      name: "MasterProductPlan",
      component: MasterProductPlan,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/SalesManagement",
      name: "SalesManagement",
      component: SalesManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/RoleManagement",
      name: "RoleManagement",
      component: RoleManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ApproveManagement",
      name: "ApproveManagement",
      component: ApproveManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/manualsync",
      name: "ManualSync",
      component: ManualSync,
      meta: {
        requiresAuth: true,
      },
    },
    // {
    //     path: "/syncmanagement",
    //     name: "SyncManagement",
    //     component: SyncManagement,
    //     meta: {
    //         requiresAuth: true
    //     }

    // },
    {
      path: "/AddCompany",
      name: "AddCompany",
      component: AddCompany,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/EditCompany",
      name: "EditCompany",
      component: EditCompany,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/configscheduler",
      name: "ConfigScheduler",
      component: ConfigScheduler,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/compareAD",
      name: "CompareAD",
      component: CompareAD,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/userattribute",
      name: "UserAttribute",
      component: UserAttribute,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/taskstatus",
      name: "TaskStatus",
      component: TaskStatus,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/usermanagement",
      name: "UserManagement",
      component: UserManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/reportmanagement",
      name: "ReportManagement",
      component: ReportManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/report1",
      name: "Report1",
      component: Report1,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/report2",
      name: "Report2",
      component: Report2,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/report3",
      name: "Report3",
      component: Report3,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/report4",
      name: "Report4",
      component: Report4,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/report5",
      name: "Report5",
      component: Report5,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/report6",
      name: "Report6",
      component: Report6,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/report7",
      name: "Report7",
      component: Report7,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/historylog",
      name: "HistoryLog",
      component: HistoryLog,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/userprofile",
      name: "UserProfile",
      component: UserProfile,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/menumanagement",
      name: "MenuManagement",
      component: MenuManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/calendar",
      name: "Calendar",
      component: Calendar,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/permission",
      name: "Permission",
      component: Permission,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/log",
      name: "Log",
      component: Log,
      meta: {
        requiresAuth: true,
      },
    },
    // {
    //     path: "/UploadPDF",
    //     name: "UploadPDF",
    //     component: UploadPDF,
    //     meta: {
    //         requiresAuth: true
    //     }
    // },
    // {
    //     path: "/PdfPreview",
    //     name: "PdfPreview",
    //     component: PdfPreview,
    //     meta: {
    //         requiresAuth: true
    //     }
    // },
    // {
    //     path: "/CertList",
    //     name: "CertList",
    //     component: CertList,
    //     meta: {
    //         requiresAuth: true
    //     }
    // },
    {
      path: "/setting",
      name: "Setting",
      component: Setting,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/usergroup",
      name: "UserGroup",
      component: UserGroup,
      meta: {
        requiresAuth: true,
      },
    },
    // {

    //     path: "/NotiConfig",
    //     name: "NotiConfig",
    //     component: NotificationConfig,
    //     meta: {
    //         requiresAuth: true
    //     }

    // },
    {
      path: "/SubscriptionManagement",
      name: "SubscriptionManagement",
      component: SubscriptionManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ManageCustomerSubscription",
      name: "ManageCustomerSubscription",
      component: ManageCustomerSubscription,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/TemplateContract",
      name: "TemplateContract",
      component: TemplateContract,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/AddTemplateContract",
      name: "AddTemplateContract",
      component: AddTemplateContract,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/UserManual",
      name: "UserManual",
      component: UserManual,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/MailBoardcast",
      name: "MailBoardcast",
      component: MailBoardcast,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {

        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }

})

export default router;