<template>
  <div>
    <v-card class="card-preview">
      <div
        class="pt-8"
        style="
          text-color: #444444;
          font-size: 18px;
          margin-left: 1rem;
          margin-right: 1rem;
          color: #444444;
        "
      >
        Manual Sync
      </div>

      <div class="pl-5">
        <v-row>
          <v-col cols="12" md="12">
            <v-row style="margin-right: 11px">
              <v-col md="8"></v-col>
              <v-col cols="12" md="2">
                <v-btn
                  color="#126496"
                  style="color: white; float: right"
                  class="mr-2 text-capitalize"
                  width="120"
                  @click="ConfirmSyncAllDialog = true"
                  >Sync All</v-btn
                >
              </v-col>
              <v-col cols="12" md="2">
                <v-btn
                  color="#126496"
                  style="color: white; float: right"
                  class="mr-2 text-capitalize"
                  @click="ConfirmRefreshAllDialog = true"
                  >Refresh User AD</v-btn
                >
              </v-col>
            </v-row>
            <v-row class="pt-5" style="margin-left: 11px; margin-right: 11px">
              <v-col cols="6" md="3"> 
                <v-text-field
                  outlined
                  dense
                  v-model="OUName"
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="2"> 
                <v-btn
                  color="#03C7F919"
                  class="mr-2 text-capitalize"
                  style="color: #03c7f9; float: left"
                  width="100"
                  @click="getCountUserAD_OU(OUName)"
                  >Check OU</v-btn>
              </v-col>
              <v-col cols="2" md="1" v-show="flagCountOU">
                Count UserAD in OU : {{CountUserAD_OU}}
               </v-col>
              <v-col cols="6" md="4">
                <v-autocomplete
                  :search-input.sync="InputSearch"
                  :items="Listitems"
                  outlined
                  label="Select"
                  item-text="samAccountName"
                  item-value="userAD_ID"
                  v-model="DataInfo"
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" md="2">
                <v-btn
                  color="#03C7F919"
                  class="mr-2 text-capitalize"
                  style="color: #03c7f9; float: left"
                  @click="AddDatatoTempList(DataInfo)"
                  >ADD</v-btn
                >
              </v-col>
              <!-- <v-col cols="12" md="3" class="mt-1"> -->
              <!-- <v-select
                  dense
                  style="width: 120px; float: right"
                  solo
                  label="10/page"
                  v-model="itemsPerPage1"
                  :items="items"
                  @input="itemsPerPage1 = parseInt($event, 10)"
                ></v-select> -->
              <!-- </v-col> -->
            </v-row>

            <v-data-table
              style="margin-left: 10px; margin-right: 40px"
              class="mytable"
              :headers="headers"
              :items="ListData"
              :search="searchdata"
              :page.sync="page1"
              hide-default-header
              :items-per-page="itemsPerPage1"
              @page-count="pageCount1 = $event"
              hide-default-footer
            >
              <template v-slot:header="{ props: {} }">
                <thead>
                  <tr>
                    <th style="background-color: #126496">
                      {{ headers[0].text }}
                      <!-- <v-icon small v-if="!companyCode">mdi-arrow-down</v-icon>
                      <v-icon small v-if="companyCode">mdi-arrow-up</v-icon>-->
                    </th>
                    <th style="background-color: #126496">
                      {{ headers[1].text }}
                      <!-- <v-icon small v-if="!companyName">mdi-arrow-down</v-icon>
                      <v-icon small v-if="companyName">mdi-arrow-up</v-icon>-->
                    </th>
                    <th style="background-color: #126496">
                      {{ headers[2].text }}
                      <!-- <v-icon small v-if="!company_Email">mdi-arrow-down</v-icon>
                      <v-icon small v-if="company_Email">mdi-arrow-up</v-icon>-->
                    </th>
                    <th style="background-color: #126496">
                      {{ headers[3].text }}
                      <!-- <v-icon small v-if="!tele_Number">mdi-arrow-down</v-icon>
                      <v-icon small v-if="tele_Number">mdi-arrow-up</v-icon>-->
                    </th>
                    <!-- <th>Status</th> -->
                    <th style="text-align: center; background-color: #126496">
                      Action
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item, index }">
                <tr>
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.ADAccount }}</td>
                  <td>{{ item.FirstName }}</td>
                  <td>{{ item.LastName }}</td>
                  <td style="text-align: center">
                    <!-- <v-btn color="white" @click="RemoveDatafromTempData(item)"
                      ><v-img src="@/assets/RemoveIcon.png"></v-img>Remove from table</v-btn> -->
                    <v-btn
                      color="white"
                      class="ma-2"
                      @click="RemovefromtableDialog(item.ID)"
                    >
                      <!-- <v-icon left dark> mdi-cloud-upload </v-icon> -->
                      <v-img src="@/assets/RemoveIcon.png"></v-img>
                      <span style="color: red">Remove from table</span>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <!-- <div class="text-right">
              <v-row justify="end" class="ml-5 mt-5 mr-5">
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page1"
                    :length="pageCount1"
                    color="#FFCD2C"
                    :total-visible="6"
                  ></v-pagination>
                </v-col>
              </v-row>
            </div> -->
            <div style="text-align: center">
              <v-row class="ml-5 mt-5 mr-5">
                <v-col cols="12" md="2"> </v-col>
                <v-col cols="12" md="2">
                  <v-btn
                    color="#E5E7E9"
                    class="mr-5 text-capitalize"
                    width="120"
                    @click="CancelDialog = true"
                    >Cancel</v-btn
                  >
                </v-col>
                <v-col cols="12" md="2">
                  <!-- <v-btn
                    color="#126496"
                    class="mr-5 text-capitalize"
                    width="120"
                    style="color: white"
                    @click="SyncDataInListData()"
                    >Sync Now</v-btn
                  > -->
                  <v-btn
                    color="#126496"
                    class="mr-5 text-capitalize"
                    width="120"
                    style="color: white"
                    @click="ConfirmSyncnowDialog = true"
                    >Sync Now</v-btn
                  >
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    dense
                    style="width: 120px; float: right"
                    solo
                    label="10/page"
                    v-model="itemsPerPage1"
                    :items="items"
                    @input="itemsPerPage1 = parseInt($event, 10)"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-pagination
                    v-model="page1"
                    :length="pageCount1"
                    color="#FFCD2C"
                    :total-visible="7"
                  ></v-pagination>
                </v-col>
              </v-row>
            </div>
            <br />
          </v-col>
        </v-row>
      </div>
    </v-card>

    <v-dialog v-model="DeleteDialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span
            ><v-img
              style="width: 32px; height: 32px; margin-bottom: -30px"
              src="@/assets/Mask Group 48@2x.png"
            ></v-img
          ></span>
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 20px">
            Confirm to Delete
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  Do you want to delete this data?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            @click="DeleteDialog = false"
            text
            >Cancel</v-btn
          >
          <v-btn
            color="#FF2727"
            class="mr-2 white--text text-capitalize"
            width="120"
            @click="RemoveDatafromTempData()"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ConfirmSyncnowDialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span
            ><v-img
              style="width: 32px; height: 32px; margin-bottom: -30px"
              src="@/assets/Icon awesome-sync-alt@2x.png"
            ></v-img
          ></span>
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 20px">
            Confirm to Sync
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  Do you want to confirm to sync?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            @click="ConfirmSyncnowDialog = false"
            text
            >Cancel</v-btn
          >
          <v-btn
            color="#126496"
            class="mr-2 white--text text-capitalize"
            width="120"
            @click="SyncDataInListData()"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ConfirmSyncAllDialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span
            ><v-img
              style="width: 32px; height: 32px; margin-bottom: -30px"
              src="@/assets/Icon awesome-sync-alt@2x.png"
            ></v-img
          ></span>
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 20px">
            Confirm to Sync
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  Do you want to confirm to sync?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            @click="ConfirmSyncAllDialog = false"
            text
            >Cancel</v-btn
          >
          <v-btn
            color="#126496"
            class="mr-2 white--text text-capitalize"
            width="120"
            @click="SyncAllData()"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

     <v-dialog v-model="ConfirmRefreshAllDialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span
            ><v-img
              style="width: 32px; height: 32px; margin-bottom: -30px"
              src="@/assets/Icon awesome-sync-alt@2x.png"
            ></v-img
          ></span>
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 20px">
            Confirm to Refresh User AD
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  Do you want to confirm to Refresh User AD?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            @click="ConfirmRefreshAllDialog = false"
            text
            >Cancel</v-btn
          >
          <v-btn
            color="#126496"
            class="mr-2 white--text text-capitalize"
            width="120"
            @click="RefreshAllData()"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="CancelDialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span
            ><v-img
              style="width: 32px; height: 32px; margin-bottom: -30px"
              src="@/assets/Group 7730@2x.png"
            ></v-img
          ></span>
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 20px">
            Confirm to Clear
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  Do you want to clear data in all table?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            @click="CancelDialog = false"
            text
            >Cancel</v-btn
          >
          <v-btn
            color="#126496"
            class="mr-2 white--text text-capitalize"
            width="120"
            @click="ClearTempListdata()"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />
    
    <Loading
      :flagLoading="flagLoadingSend"
    />
    
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components: {
    Alert,
    Loading,
  },
  data() {
    return {
      flagAlertSend: false,
      mesAlertSend: "",
      typeAlertSend: "success",
      // DailyWeek: "1",
      // Timepicker: "",
      // SundayCheckboxel: "",
      // MondayCheckbox: "",
      // TuesdayCheckbox: "",
      // WednesdayCheckbo: "",
      // ThursdayCheckbox: "",
      // FridayCheckbox: "",
      // SaturdayCheckbox: "",
      OUName: "",
      CountUserAD_OU:0,
      flagCountOU: false,
      ListData: [],
      Listitems: [],
      OldListitems: [],
      DataInfo: "",
      search: "",
      searchdata: "",
      DeleteID: null,
      DeleteDialog: false,
      ConfirmSyncnowDialog: false,
      ConfirmSyncAllDialog: false,
      ConfirmRefreshAllDialog: false,
      CancelDialog: false,

      //userid: store.state.username,
      flagLoadingSend: false,
      detaildialog: false,
      adddialog: false,
      editdialog: false,
      status: false,
      companyName: null,
      companyCode: null,
      tele_Number: null,
      activeFlag: null,
      company_ID: 0,
      items: [10, 20, 30],
      url: enurl.apiUrl,
      dialog: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,

      //Inactive Company
      page1: 1,
      pageCount1: 0,
      itemsPerPage1: 10,

      InputSearch: null
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "#",
          align: "left",
          sortable: true,
          value: "companyCode",
        },
        {
          text: "AD Account",
          value: "ADAccount",
          align: "left",
          sortable: true,
        },

        {
          text: "FirstName",
          align: "left",
          value: "FirstName",
          sortable: true,
        },
        {
          text: "LastName",
          align: "left",
          value: "LastName",
          sortable: true,
        },
        {
          text: "Action",
          value: "removeactions",
          sortable: true,
          align: "left",
        },
      ];
    },
  },
  watch: {
    InputSearch(value){
      let self = this;
      var temp = {
        "search" : value,
        "OU": self.OUName
      }
      if(value != null){
        if( value.length >= 3){
          self.flagLoadingSend = true;
          axios
          // .post(`${self.url}SyncManagement/SearchForAccountUsers?search=${value}`)
          // .get(`${self.url}SyncManagement/GetDataAD?search=${value}`)
          .post(`${self.url}SyncManagement/SearchDataAD`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.Listitems = response.data.data;
              //self.OldListitems = response.data.data;
              // console.log(response.data.data);
            } else {
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
            }
            self.flagLoadingSend = false;

          })
          .catch(function (error) {
            alert(error);
            self.flagLoadingSend = false;
          });
        }
      }
    },
  },
  mounted() {
    //this.GetDataAD();
  },

  methods: {
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    getCountUserAD_OU(value){
      let self = this;
      self.flagLoadingSend = true;
      self.flagCountOU = false;
      axios
        .post(`${self.url}SyncManagement/CountUserADOu?search=${value}`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.CountUserAD_OU = response.data.data;
            // alert(response.data.message);
          } else {
            self.CountUserAD_OU = 0;
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
          }
          self.flagCountOU = true;
          self.flagLoadingSend = false;
        })
        .catch(function (error) {
          alert(error);
          self.CountUserAD_OU = 0;
          self.flagCountOU = false;
          self.flagLoadingSend = false;
        });
    },
    SyncAllData() {
      let self = this;
      axios
        .post(`${self.url}SyncManagement/SyncAllData`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
          } else {
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
          }
          self.ConfirmSyncAllDialog = false;
        })
        .catch(function (error) {
          alert(error);
          self.ConfirmSyncAllDialog = false;
        });
    },
    RefreshAllData() {
      let self = this;
      axios
        .post(`${self.url}SyncManagement/RefreshAllData`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
          } else {
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
          }
          self.ConfirmRefreshAllDialog = false;
        })
        .catch(function (error) {
          alert(error);
          self.ConfirmRefreshAllDialog = false;
        });
    },
    SyncDataInListData() {
      let self = this;
      if (self.ListData.length > 0) {
        self.flagLoadingSend = true;
        let tempdata = [];
        for (let i = 0; i < self.ListData.length; i++) {
          let data = {
            ID: self.ListData[i].ID,
            ADAccount: self.ListData[i].ADAccount,
            FirstName: self.ListData[i].FirstName,
            LastName: self.ListData[i].LastName,
          };
          tempdata.push(data);
        }
        let temp = {
          tempSyncData: tempdata,
        };
        axios
          .post(`${self.url}SyncManagement/SyncData`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = response.data.message;
              self.flagAlertSend = true;
              self.ListData = [];
              self.OUName = "";
              // self.GetDataAD();
            } else {
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = response.data.message;
              self.flagAlertSend = true;
            }
            self.flagLoadingSend = false;
            self.ConfirmSyncnowDialog = false;
          })
          .catch(function (error) {
            alert(error);
            self.flagLoadingSend = false;
            self.ConfirmSyncnowDialog = false;
          });
      }
      else{
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "กรุณาเพิ่มข้อมูลในตาราง!";
        self.flagAlertSend = true;
        self.ConfirmSyncnowDialog = false;
      }
    },

    AddDatatoTempList(value) {
      let self = this;
      // let finddata = self.Listitems.filter((x) => x.sAMAccountName == value);
      if(self.OUName == "" && self.CountUserAD_OU == 0){
        let finddata = self.Listitems.filter((x) => x.userAD_ID == value);
        let findDup = self.ListData.filter((x) => x.ADAccount == value);
        if(findDup.length == 0){
          if (finddata.length > 0) {
            let temp = {
              ID: finddata[0].userAD_ID,
              ADAccount: finddata[0].samAccountName,
              FirstName: finddata[0].givenName,
              LastName: finddata[0].sn,
            };
            self.ListData.push(temp);
            self.DataInfo = "";
          }
          self.Listitems = [];
          self.OldListitems.forEach((element) => {
            self.Listitems.push(element);
          });
          for (let i = 0; i < self.ListData.length; i++) {
            var FindIndex = self.Listitems.findIndex(
              (x) => x.userAD_ID == self.ListData[i].ID
            );
            if (FindIndex != null) {
              self.Listitems.splice(FindIndex, 1);
            }
          }
        }
        else{
          self.typeAlertSend = "warning"; //success warning error
          self.mesAlertSend = "ข้อมูลซ้ำ !!";
          self.flagAlertSend = true;
        }
      }
      else{
        self.flagLoadingSend = true;
        axios
          .post(`${self.url}SyncManagement/SearchUserADOrgUnit?search=${self.OUName}`)
          .then(function (response) {
            if (response.data.status == 0) {
              response.data.data.forEach(e => {
                  var singleObj = {
                    ID: e.userAD_ID,
                    ADAccount: e.samAccountName,
                    FirstName: e.givenName,
                    LastName: e.sn
                  };
                  self.ListData.push(singleObj);
              });
              
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = response.data.message;
              self.flagAlertSend = true;
            } else {
              self.CountUserAD_OU = 0;
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = response.data.message;
              self.flagAlertSend = true;
            }
            self.flagCountOU = false;
            self.flagLoadingSend = false;
          })
          .catch(function (error) {
            alert(error);
            self.CountUserAD_OU = 0;
            self.flagCountOU = false;
            self.flagLoadingSend = false;
          });
      }
    },

    RemovefromtableDialog(value) {
      this.DeleteID = value;
      this.DeleteDialog = true;
    },

    RemoveDatafromTempData() {
      let self = this;
      let findIndex = self.ListData.findIndex((x) => x.ID == self.DeleteID);
      if (findIndex != null) {
        self.ListData.splice(findIndex, 1);
      }
      self.Listitems = [];
      self.OldListitems.forEach((element) => {
        self.Listitems.push(element);
      });
      for (let i = 0; i < self.ListData.length; i++) {
        var FindIndex = self.Listitems.findIndex(
          (x) => x.userAD_ID == self.ListData[i].ID
        );
        if (FindIndex != null) {
          self.Listitems.splice(FindIndex, 1);
        }
      }
      self.DeleteDialog = false;
    },

    ClearTempListdata() {
      this.ListData = [];
      this.GetDataAD();
    },

    GetDataAD: function () {
      let self = this;
      axios
        .get(`${self.url}SyncManagement/GetDataAD`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.Listitems = response.data.data;
            self.OldListitems = response.data.data;
            // console.log(response.data.data);
          } else {
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
          }
        })
        .catch(function (error) {
          alert(error);
        });
    },

    SearchDataAD: function () {
      let self = this;
      let data = "";
      axios
        .get(`${self.url}SyncManagement/SearchDataAD?search=${data}`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.Listitems = response.data.data;
            self.OldListitems = response.data.data;
            console.log(response.data.data);
          } else {
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
          }
        })
        .catch(function (error) {
          alert(error);
        });
    },
  },
};
</script>
<style scoped>
.card-preview {
  border-radius: 5px !important;
  height: 100%;
  /* overflow: auto; */
  padding: 0;
}
input[type="checkbox"] {
  width: 17px;
  height: 17px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.text-capitalize {
  font-size: 14px;
}
.mytitle {
  font-size: 14px;
  font-weight: 300;
}
.delbtn {
  background-color: #1976d2;
  border-radius: 20px;
}
* >>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #1976d2 !important;
  /* color: white !important; */
}
* >>> .theme--light.v-data-table thead tr th {
  /* color: #1976d2 !important; */
  color: white !important;
}
/* .v-card:not(.v-sheet--tile):not(.v-card--shaped){
  border-radius: 20px;
} */
* >>> .v-dialog {
  border-radius: 20px !important;
}
.typeofleave .v-input__slot {
  min-height: 30px !important;
  width: 90rem !important;
}
*
  >>> .theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fff9f0 !important;
}

*
  >>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
* >>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
* >>> .theme--light.v-pagination .v-pagination__item--active {
  background: #126496 !important;
  /* background: #1976d2 !important; */
  color: white !important;
}
* >>> .v-data-table tr td {
  height: 70px;
}
* >>> .theme--light.v-data-table tbody td:not(.v-data-table__mobile-row) {
  font-weight: 400;
}
* >>> .theme--light.v-data-table thead tr th {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.firsttab {
  background-color: white;
  width: 140px;
}
.tabone {
  width: 150px;
  font-size: 14px;
  border: solid 1px #1976d2;
  border-radius: 3px;
  height: 42px;
}
* >>> .mdi-paperclip::before {
  content: none;
}
* >>> .foricon .mdi:before {
  color: #1976d2;
}
.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #1976d2 !important;
}
.title {
  font-size: 20px;
}
</style>
